/* istanbul ignore file */
import { Matrix3Tuple, Matrix4Tuple } from 'three'

import { LayerStatus, PointArray } from './attribute'
import { MinimumAreaBoundary } from './diagram'

export interface Shape extends LayerStatus {
  partition_key?: string
  shape_id: string
  transformation: Matrix4Tuple
  center?: PointArray
  is_shown_on_final_sheet?: boolean
}

export interface Cylinder extends Shape {
  diameter: number
  length: number
}

export enum PlaneSide {
  UPPER = 'upper',
  LOWER = 'lower',
}

export interface Cuboid extends LayerStatus {
  region_id: string
  center: PointArray
  rotation: number[]
  extent: PointArray
}

export interface Polygon extends Shape {
  positions: PointArray[]
  vertices: [number, number][]
  plane_side?: PlaneSide
  min_rect_boundaries: MinimumAreaBoundary
}

export type ShapeTypes = Cylinder | Polygon

export enum ShapeKeyType {
  CYLINDER = 'cylinders',
  POLYGON = 'polygons',
}
export type ShapeKey = `${ShapeKeyType}`

export interface Shapes {
  cylinders: Cylinder[]
  polygons: Polygon[]
}

export interface ShapesId {
  cylinders: string[]
  polygons: string[]
}

export interface ShapeDistance {
  distance: number
  positions_for_distance: [PointArray, PointArray]
}

export interface ShapeDetectionResult {
  shape_id: string
  registration_result?: {
    transformation: {
      t_00: number
      t_01: number
      t_02: number
      t_03: number
      t_10: number
      t_11: number
      t_12: number
      t_13: number
      t_20: number
      t_21: number
      t_22: number
      t_23: number
      t_30: number
      t_31: number
      t_32: number
      t_33: number
    }
  }
  error_id?: number
  error_message?: string
  positions?: {
    x: number
    y: number
    z: number
  }[]
  is_shown_on_final_sheet: boolean
}
export interface CylinderDetectionResult extends ShapeDetectionResult {
  parameters_cylinder?: {
    diameter: number
    length: number
  }
  internal_shape_parameters?: {
    diameter: number
  }
}

// TODO: not actual result, replaced once confirmed
export interface PolygonDetectionResult extends ShapeDetectionResult {
  parameters_polygon: {
    vertices: [number, number][]
  }
  plane_side?: PlaneSide
}

export interface AxiosCuboid {
  center: PointArray
  rotation: Matrix3Tuple
  extent: PointArray
}
