import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { ToastId, useToast } from '@chakra-ui/react'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Plane, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, MAXIMUM_NUMBER_OF_SHAPE_DETECTION } from 'config/constants'
import { STANDALONE_PLANE_OPACITY } from 'config/styles'

import { PointArray } from 'interfaces/attribute'
import { PolygonPlaneMeshProps } from 'interfaces/canvas'
import { CanvasConfig, CanvasEventsExtra } from 'interfaces/editor'
import { Polygon } from 'interfaces/shape'

import { findCenter } from 'services/Points'
import { getPlaneStyles, zeroPad } from 'services/Util'
import { getVolumeEstimationItem } from 'services/VolumeEstimation'

import { addWorkingPoint, completePlane, setIsClosing, updateLastWorkingPoint } from '../store'
import { useAnchors } from './useAnchors'

export default function useMainCanvas(): CanvasConfig {
  // Toast
  const toast = useToast()
  const toastIdRef = useRef<ToastId>()

  // Context
  const { isJobRunning, selectedTool, shapes, inspectionItems } = useContext(EditorContext) // States

  // Store
  const dispatch = useAppDispatch()
  const workingPoints = useSelector((root: RootState) => root.toolPlaneDetection.workingPoints)
  const planes = useSelector((root: RootState) => root.toolPlaneDetection.planes)
  const isClosing = useSelector((root: RootState) => root.toolPlaneDetection.isClosing)
  const isLoading = useSelector((root: RootState) => root.toolPlaneDetection.isLoading)
  const hoveredShapeId = useSelector((state: RootState) => state.editor.hoveredShapeId)
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)
  const dimmedShapeIds = useSelector((state: RootState) => state.editor.dimmedShapeIds)

  // States
  const [onDownData, setOnDownData] = useState<{ position: number[]; timestamp: number } | undefined>(undefined) // position here is screen position, not canvas coordinates.
  const [lastTouchPoint, setLastTouchPoint] = useState<PointArray | undefined>(undefined)
  const [lastTouchClient, setLastTouchClient] = useState<{ x: number; y: number } | undefined>(undefined)
  const [threePlanes, setThreePlanes] = useState<Plane[]>([]) // The 'three' here refers to ThreeJS. Must follow index order of 'planes' from Redux.
  const [workingPlane, setWorkingPlane] = useState<Plane | null>(null)

  // Hooks
  const { anchors, movingAnchor, onMoveAnchor, onEndMoveAnchor } = useAnchors(threePlanes)

  // Vars
  const isSelectedTool = useMemo(() => selectedTool === EDITOR_TOOLS.PLANE, [selectedTool])

  /**
   * When workingsPoints is changed (new points added), or completedPlanes is changed (plane completed),
   * construct ThreeJS's Plane representation of them.
   */
  useEffect(() => {
    if (workingPoints.length < 3) return

    setWorkingPlane(
      new Plane().setFromCoplanarPoints(
        new Vector3(...workingPoints[0]),
        new Vector3(...workingPoints[1]),
        new Vector3(...workingPoints[2]),
      ),
    )
  }, [workingPoints])
  useEffect(() => {
    setThreePlanes(
      planes.map((plane) =>
        new Plane().setFromCoplanarPoints(
          new Vector3(...plane.points[0]),
          new Vector3(...plane.points[1]),
          new Vector3(...plane.points[2]),
        ),
      ),
    )
  }, [planes])

  /**
   * Set attention text based on the current drawing stage.
   */
  useEffect(() => {
    if (!isSelectedTool) return

    dispatch(
      setAttentionText({
        message:
          '平面の頂点を選択してください。同時に11個まで作成することができます。\n選択が完了したら右下の測定ボタンをクリックしてください。',
      }),
    )
  }, [isSelectedTool, dispatch])

  /**
   * Event Handlers (onMove).
   * Will be called by both mouse and touch events.
   */
  const onMove = useCallback(
    (point: PointArray | undefined, { isTouch = false }: { isTouch?: boolean }) => {
      // Vertice adjustments during complete stage
      if (movingAnchor) {
        onMoveAnchor(point)
        return
      }

      // only mouse move will update the last point
      if (!point || isTouch || planes.length >= MAXIMUM_NUMBER_OF_SHAPE_DETECTION) return

      let newPoint = point

      if (workingPoints.length > 3 && workingPlane) {
        newPoint = workingPlane.projectPoint(new Vector3(...point), new Vector3()).toArray()
      }

      // update last point to according to mouse position
      dispatch(updateLastWorkingPoint(newPoint))
    },
    [workingPoints, workingPlane, movingAnchor, planes, onMoveAnchor, dispatch],
  )

  /**
   * Event Handlers (onUp).
   * Will be called by both mouse and touch events.
   */
  const onUp = useCallback(
    (
      point: PointArray | undefined,
      { clientX, clientY, isTouch = false }: { clientX: number; clientY: number; isTouch?: boolean },
      { isDoubleClicked }: CanvasEventsExtra,
    ) => {
      if (planes.length >= MAXIMUM_NUMBER_OF_SHAPE_DETECTION) {
        if (!toastIdRef.current) {
          toastIdRef.current = toast({
            title: '同時に検出できる要素は11個までです。',
            description: '右側の「検出中の要素」パネルから削除して11個以下にしてください。',
            status: 'warning',
            duration: null,
          })
        }
        return
      }

      const mouseStart = onDownData ? { ...onDownData } : undefined
      setOnDownData(undefined)

      const distanceFromDown = mouseStart
        ? Math.hypot(clientX - mouseStart.position[0], clientY - mouseStart.position[1])
        : 0
      if (distanceFromDown > 10) return // user has been dragging the canvas

      if (point) {
        // Double clicked with more than 2 or 3 points or last point is near the first point.
        // Touch does not have the last anchor that follows the mouse which is what the extra anchor
        // check is needed for non-touch interactions
        const completesPolygon =
          (workingPoints.length > (isTouch ? 2 : 3) && isDoubleClicked) ||
          (workingPoints.length > (isTouch ? 2 : 3) &&
            new Vector3(...workingPoints[0]).distanceTo(new Vector3(...point)) < 0.02)

        if (completesPolygon) {
          dispatch(completePlane({ removeLast: !isTouch }))
          dispatch(setIsClosing(false))
          // if the user double clicks with less than 3 points, ignore second click
        } else if (!isDoubleClicked) {
          // Get the other plane as reference point
          if (!workingPlane && workingPoints.length > 3) return

          dispatch(
            addWorkingPoint(
              workingPoints.length > 3 && workingPlane
                ? workingPlane.projectPoint(new Vector3(...point), new Vector3()).toArray()
                : point,
            ),
          )
        }
      }
    },
    [planes, workingPoints, onDownData, workingPlane, toast, dispatch],
  )

  /**
   * When user removes a plane, remove the toast if available.
   */
  useEffect(() => {
    if ((planes.length < MAXIMUM_NUMBER_OF_SHAPE_DETECTION || isLoading) && toastIdRef.current) {
      toast.close(toastIdRef.current)
      toastIdRef.current = undefined
    }
  }, [planes.length, isLoading, toast])

  return {
    objects: {
      circleAnchors: anchors,
      polygonPlaneMesh: [
        // Get polygon from shapes and prep data for drawing.
        ...(shapes?.polygons
          .filter((polygon) => !polygon.invisible && !getVolumeEstimationItem(inspectionItems, polygon.shape_id))
          .map<PolygonPlaneMeshProps>((polygon, index) => {
            const inspectionItem = inspectionItems.find(
              (item) => item.shape_ids.polygons?.includes(polygon.shape_id) && item.item_type === 'polygon_area',
            )

            return {
              ...getPlaneStyles(
                polygon,
                selectedShapeIds,
                dimmedShapeIds,
                hoveredShapeId,
                '#70B77E',
                STANDALONE_PLANE_OPACITY,
              ),
              polygon,
              label:
                inspectionItem && (hoveredShapeId === polygon.shape_id || selectedShapeIds.includes(polygon.shape_id))
                  ? `${
                      inspectionItem.part_name || `面積${zeroPad(index + 1, 3)}`
                    } : ${inspectionItem.polygon_area?.estimated_value?.toFixed(4)}m²`
                  : undefined,
              transparent: false,
              color: selectedShapeIds.includes(polygon.shape_id) ? 'yellow' : 'lightgreen',
              selectable: true,
              showGuides: true,
              showGuidesLabel:
                (hoveredShapeId === polygon.shape_id || selectedShapeIds.includes(polygon.shape_id)) &&
                selectedTool === EDITOR_TOOLS.MOVE &&
                !isJobRunning,
              guideLabelBgColor: 'lightgreen',
              guideLabelTextColor: 'black',
              isOpenLoop: true,
            }
          }) || []),
        // Completed planes
        ...planes.map((plane, planeIndex) => ({
          polygon: {
            shape_id: `working-plane-${planeIndex}`,
            positions: plane.points,
            vertices: plane.points.map((v) => [v[0], v[1]] as [number, number]),
            center: findCenter(plane.points.map((v) => new Vector3(...v)))?.toArray(),
            transformation: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
          } as Polygon,
          transparent: false,
          color: 'pink',
          showGuides: true,
          guideColor: undefined,
          labelTextColor: '#fff',
          guideLabelBgColor: 'white',
          guideLabelTextColor: 'black',
          opacity: STANDALONE_PLANE_OPACITY.STANDARD,
          selectable: false,
        })),
        // Working plane
        workingPoints.length
          ? {
              polygon: {
                shape_id: 'working-plane-drawing',
                positions: workingPoints,
                vertices: workingPoints.map((v) => [v[0], v[1]] as [number, number]),
                center: findCenter(workingPoints.map((v) => new Vector3(...v)))?.toArray(),
                transformation: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
              } as Polygon,
              transparent: false,
              color: 'yellow',
              showGuides: true,
              guideColor: isClosing ? 'red' : undefined,
              labelBgColor: 'white',
              labelTextColor: '#fff',
              guideLabelBgColor: 'yellow',
              guideLabelTextColor: 'black',
              opacity: STANDALONE_PLANE_OPACITY.STANDARD,
              selectable: false,
            }
          : null,
      ].filter(Boolean) as PolygonPlaneMeshProps[],
    },
    events: {
      onTouchStart: (e: React.TouchEvent<HTMLDivElement>, point: PointArray | undefined) => {
        if (!isSelectedTool) return
        // if (drawingStage === DrawingStage.Height && point) changeIsDragging(true) // toggling off is done in MainCanvas::onTouchEnd
        setLastTouchPoint(point)
        setLastTouchClient({ x: e.touches[0].clientX, y: e.touches[0].clientY })
        setOnDownData({ position: [e.touches[0].clientX, e.touches[0].clientY], timestamp: Date.now() })
      },
      onTouchMoveCapture: (e: React.TouchEvent<HTMLDivElement>, point: PointArray | undefined) => {
        if (!isSelectedTool) return

        if (movingAnchor) {
          onMoveAnchor(point)
          return
        }

        setLastTouchPoint(point)
        setLastTouchClient({ x: e.touches[0].clientX, y: e.touches[0].clientY })
        onMove(point, { isTouch: true })
      },
      onTouchEnd: (e, extra: CanvasEventsExtra) => {
        if (!isSelectedTool) return

        if (movingAnchor) {
          onEndMoveAnchor()
          return
        }

        // Last touch point and last touch client are obtained from touchstart and touchstart because touchmove does not have `touches` data.
        if (lastTouchPoint && lastTouchClient) {
          onUp(lastTouchPoint, { clientX: lastTouchClient.x, clientY: lastTouchClient.y, isTouch: true }, extra)
        }

        setLastTouchPoint(undefined)
      },
      onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isSelectedTool || lastTouchClient) return
        setOnDownData({ position: [e.clientX, e.clientY], timestamp: Date.now() })
      },
      onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, points: PointArray | undefined, extra) => {
        if (!isSelectedTool || lastTouchClient) return
        if (movingAnchor) onEndMoveAnchor()
        else onUp(points, { clientX: e.clientX, clientY: e.clientY }, extra)
      },
      onMove: (point: PointArray | undefined) => {
        if (!isSelectedTool || lastTouchClient) return
        onMove(point, {})
      },
    },
  }
}
