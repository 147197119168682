import { FC } from 'react'

import { StackDivider, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ToolbarCommentIcon } from 'assets/icons'

import { BLUEPRINT_TOOLS, EDITOR_TOOL_BUTTON_SIZE } from 'config/constants'

import ToolbarButton from '../../editor/toolbar/components/ToolbarButton'
import { setSelectedTool } from '../store/blueprint'

const BlueprintTools: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const selectedTool = useSelector((state: RootState) => state.blueprint.selectedTool)
  const commentPermissionSet = useSelector((state: RootState) => state.comments.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)

  // Permission check
  const isAllowedToComment = commentPermissionSet.CREATE.includes(userType)

  if (!isAllowedToComment) {
    return null
  }

  return (
    <VStack overflow="hidden" w={EDITOR_TOOL_BUTTON_SIZE} spacing={3}>
      <VStack
        backgroundColor="gray.800"
        overflow="hidden"
        borderRadius="md"
        divider={<StackDivider borderColor="whiteAlpha.200" />}
        spacing={0}
        w={EDITOR_TOOL_BUTTON_SIZE}
      >
        <ToolbarButton
          icon={<ToolbarCommentIcon width="50%" height="50%" />}
          label="コメント"
          warningLabel={!inspectionArea ? '検査箇所を選択してください' : ''}
          toolType={BLUEPRINT_TOOLS.COMMENT}
          toolTipPlacement="right"
          buttonSize={EDITOR_TOOL_BUTTON_SIZE}
          selectedTool={selectedTool}
          changeTool={(tool: string) => dispatch(setSelectedTool(selectedTool === BLUEPRINT_TOOLS.COMMENT ? '' : tool))} // Toggle on/off
          disabled={!inspectionArea}
        />
      </VStack>
    </VStack>
  )
}

export default BlueprintTools
