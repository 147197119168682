import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { LayerStatus, PointArray } from 'interfaces/attribute'
import { PlaneSide } from 'interfaces/shape'

export const PLANE_CREATION_ORDER_DEFAULT: PlaneSide[] = [PlaneSide.UPPER, PlaneSide.LOWER]

export enum DrawingStage {
  Draw,
  Height,
  Complete,
}

export interface VolumeEstimationPolygonState {
  /**
   * Drawing stage.
   */
  drawingStage: DrawingStage

  /**
   * Working points when drawing polygon.
   */
  workingPoints: PointArray[]

  /**
   * Completed planes.
   */
  planes: Record<
    PlaneSide,
    | ({
        points: PointArray[]
        anchorAdjustedPoints?: PointArray[]
        originalPoints?: PointArray[]
      } & LayerStatus)
    | null
  >

  /**
   * Point user selects/hovers to set height.
   */
  heightSelectionPoint: PointArray | null

  /**
   * Tool is loading.
   */
  isLoading: boolean

  /**
   * Flag to indicate if the polygon is closing.
   */
  isClosing: boolean

  /**
   * Flag to indicate if the tool is dirty (has working items).
   */
  isDirty: boolean

  /**
   * Plane creation order.
   */
  planeCreationOrder: PlaneSide[]

  /**
   * Transform quaternion from TransformControl applied from planes.x.anchorAdjustedPoints onto planes.x.points.
   */
  currentTransformQuaternion: number[] | null
}

const updatePointsAtIndex = (points: PointArray[], index: number, position: PointArray) => {
  points[index] = position

  // If first point is updated, update the last point as well, and vice versa.
  if (index === 0) {
    points[points.length - 1] = position
  }

  if (index === points.length - 1) {
    points[0] = position
  }

  return points
}

const initialState: VolumeEstimationPolygonState = {
  drawingStage: DrawingStage.Draw,
  isLoading: false,
  isClosing: false,
  isDirty: false,
  workingPoints: [],
  heightSelectionPoint: null,
  planeCreationOrder: PLANE_CREATION_ORDER_DEFAULT,
  planes: {
    upper: null,
    lower: null,
  },
  currentTransformQuaternion: null,
}

export const slice = createSlice({
  name: 'editor/tools/volumeEstimation/polygon',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setClosing: (state, action: PayloadAction<boolean>) => {
      state.isClosing = action.payload
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload
    },
    setPlaneCreationOrder: (state, action: PayloadAction<PlaneSide[]>) => {
      state.planeCreationOrder = action.payload
    },
    setHeightSelectionPoints: (state, action: PayloadAction<PointArray | null>) => {
      state.heightSelectionPoint = action.payload
    },
    addWorkingPoint: (state, action: PayloadAction<PointArray>) => {
      state.workingPoints.push(action.payload)
      state.isDirty = true
    },
    setWorkingPoints: (state, action: PayloadAction<PointArray[]>) => {
      state.workingPoints = action.payload
    },
    resetWorkingPoints: (state) => {
      state.workingPoints = []
    },
    updateLastWorkingPoint: (state, action: PayloadAction<PointArray>) => {
      if (state.workingPoints.length) {
        state.workingPoints[state.workingPoints.length - 1] = action.payload
      } else {
        state.workingPoints.push(action.payload)
      }
    },
    updateBottomPlaneAnchor: (state, action: PayloadAction<{ index: number; position: PointArray }>) => {
      if (state.planes.lower) {
        state.planes.lower.points[action.payload.index] = action.payload.position

        // If first point is updated, update the last point as well, and vice versa.
        if (action.payload.index === 0) {
          state.planes.lower.points[state.planes.lower.points.length - 1] = action.payload.position
        }

        if (action.payload.index === state.planes.lower.points.length - 1) {
          state.planes.lower.points[0] = action.payload.position
        }
      }
    },
    completeBottomPlane: (state, action: PayloadAction<{ removeLast: boolean }>) => {
      if (state.planes.upper) {
        state.planes.lower = {
          points: [...state.workingPoints],
        }
        state.workingPoints = []
      } else {
        state.planes.lower = {
          points: [
            ...(action.payload.removeLast ? state.workingPoints.slice(0, -1) : state.workingPoints),
            state.workingPoints[0],
          ],
        }
      }
    },
    updateTopPlaneAnchor: (state, action: PayloadAction<{ index: number; position: PointArray }>) => {
      if (state.planes.upper) {
        updatePointsAtIndex(state.planes.upper.points, action.payload.index, action.payload.position)
      }
    },
    updateTopPlaneAnchorAdjustedPoints: (state, action: PayloadAction<{ index: number; position: PointArray }>) => {
      if (state.planes.upper?.anchorAdjustedPoints) {
        updatePointsAtIndex(state.planes.upper.anchorAdjustedPoints, action.payload.index, action.payload.position)
      }
    },
    setCurrentTransformQuaternion: (state, action: PayloadAction<number[] | null>) => {
      state.currentTransformQuaternion = action.payload
    },
    completeTopPlane: (state, action: PayloadAction<{ removeLast: boolean }>) => {
      if (state.planes.lower) {
        state.planes.upper = {
          points: [...state.workingPoints],
          anchorAdjustedPoints: [...state.workingPoints],
          originalPoints: [...state.workingPoints],
        }
        state.workingPoints = []
      } else {
        state.planes.upper = {
          points: [
            ...(action.payload.removeLast ? state.workingPoints.slice(0, -1) : state.workingPoints),
            state.workingPoints[0],
          ],
          anchorAdjustedPoints: [
            ...(action.payload.removeLast ? state.workingPoints.slice(0, -1) : state.workingPoints),
            state.workingPoints[0],
          ],
          originalPoints: [
            ...(action.payload.removeLast ? state.workingPoints.slice(0, -1) : state.workingPoints),
            state.workingPoints[0],
          ],
        }
      }
    },
    /**
     * Update top plane without updating original points.
     */
    updateTopPlane: (state, action: PayloadAction<{ points: PointArray[] }>) => {
      state.planes.upper = {
        ...state.planes.upper,
        points: action.payload.points,
      }
    },
    swapPlanes: (state) => {
      const temp = state.planes.upper
      state.planes.upper = state.planes.lower
      state.planes.lower = temp
    },
    setDrawingStage: (state, action: PayloadAction<DrawingStage>) => {
      state.drawingStage = action.payload
    },
    resetTopPlane: (state) => {
      state.planes.upper = null
      if (state.planeCreationOrder[0] === PlaneSide.UPPER) {
        state.drawingStage = DrawingStage.Draw
        state.workingPoints = []
      } else {
        state.drawingStage = DrawingStage.Height
      }

      state.currentTransformQuaternion = null

      if (!state.planes.lower?.points.length) state.isDirty = false
    },
    /**
     * Restore top plane to its original state after drawing completion.
     */
    restoreTopPlane: (state) => {
      state.planes.upper = {
        points: state.planes.upper?.originalPoints || [],
        anchorAdjustedPoints: state.planes.upper?.originalPoints || [],
        originalPoints: state.planes.upper?.originalPoints || [],
      }
      state.currentTransformQuaternion = null
    },
    resetBottomPlane: (state) => {
      state.planes.lower = null
      if (state.planeCreationOrder[0] === PlaneSide.LOWER) {
        state.drawingStage = DrawingStage.Draw
        state.workingPoints = []
      } else {
        state.drawingStage = DrawingStage.Height
      }

      if (!state.planes.upper?.points.length) state.isDirty = false
    },
    toggleUpperPlaneVisibility: (state, action: PayloadAction<boolean>) => {
      if (state.planes.upper) state.planes.upper.invisible = action.payload
    },
    toggleLowerPlaneVisibility: (state, action: PayloadAction<boolean>) => {
      if (state.planes.lower) state.planes.lower.invisible = action.payload
    },
    reset: () => initialState,
  },
})

export const {
  // Utility
  setIsLoading,
  setClosing,
  setIsDirty,
  setDrawingStage,
  setHeightSelectionPoints,
  setPlaneCreationOrder,
  setCurrentTransformQuaternion,

  // Working points
  addWorkingPoint,
  updateLastWorkingPoint,
  setWorkingPoints,
  resetWorkingPoints,

  // Completed planes
  updateBottomPlaneAnchor,
  completeBottomPlane,

  updateTopPlaneAnchorAdjustedPoints,
  updateTopPlaneAnchor,
  completeTopPlane,
  updateTopPlane,
  restoreTopPlane,

  swapPlanes,

  // Resets
  reset,
  resetTopPlane,
  resetBottomPlane,

  // Visibility
  toggleLowerPlaneVisibility,
  toggleUpperPlaneVisibility,
} = slice.actions

export default slice.reducer
