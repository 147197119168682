import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { last, uniqueId } from 'lodash'
import { CursorState, setCursor } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_TOOLS, MAX_EDITOR_LAYERS } from 'config/constants'

import { DistanceLabelProps } from 'interfaces/canvas'
import { CanvasConfig } from 'interfaces/editor'
import { InspectionItem } from 'interfaces/inspection'

import { addInspectionItem, updateInspectionItem } from 'services/InspectionSheet'
import { pointArrayToVector3 } from 'services/Points'
import { getDistanceLabel, zeroPad } from 'services/Util'

import {
  CustomMeasurement,
  setCustomMeasurements,
  setTempMeasurements,
  updateCustomMeasurement,
  updateTempMeasurement,
} from '../store'

const DISTANCE_LABEL_STYLES = {
  color: '#f00',
  labelBgColor: '#f00',
  labelTextColor: '#fff',
  anchorColor: '#888',
}

const DISTANCE_LABEL_STYLES_WORKING = {
  color: '#f00',
  labelBgColor: '#f00',
  labelTextColor: '#fff',
  anchorColor: '#f00',
}

const TEMP_ID_PREFIX = 'temp_custom_measurement_'
const WORKING_ID_PREFIX = 'working_custom_measurement_'

const zeroPlaces = MAX_EDITOR_LAYERS.toString().length

const useMainCanvas = (): CanvasConfig => {
  // Context
  const { selectedTool, prevSelectedTool, inspectionSheet, inspectionItems, setInspectionItems, changeIsDragging } =
    useContext(EditorContext)
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const customMeasurements = useSelector((state: RootState) => state.toolCustomMeasurement.measurements)
  const tempMeasurements = useSelector((state: RootState) => state.toolCustomMeasurement.tempMeasurements)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)

  // State
  const [firstPointPlaced, setFirstPointPlaced] = useState(false)
  const [draggingLabelAnchor, setDraggingLabelAnchor] = useState<{ id: string; anchorIndex: number } | null>(null)
  const [axiosUpdateController, setAxiosUpdateController] = useState<Record<string, AbortController>>({})

  // Flags
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.CUSTOM_MEASUREMENT, [selectedTool])
  const isAllowedToDetect = permissionSet.DETECT.includes(userType)

  // Functions/Data to be used depending on user permssion
  const measurements = isAllowedToDetect ? customMeasurements : tempMeasurements
  const setMeasurementFn = isAllowedToDetect ? setCustomMeasurements : setTempMeasurements
  const updateMeasurementFn = isAllowedToDetect ? updateCustomMeasurement : updateTempMeasurement

  /**
   * Save temporary measurement
   * - This is for users without permission to save.
   * - This will be saved in the store only.
   *
   * @param measurement Measurement to save
   */
  const saveTempMeasurement = (measurement: CustomMeasurement) => {
    dispatch(
      updateTempMeasurement({
        ...measurement,
        inspectionItem: {
          part_name: `距離`,
          item_type: 'length_with_distance_tool',
          length_with_distance_tool: {
            estimated_value: pointArrayToVector3(measurement.distanceLabel.points[0]).distanceTo(
              pointArrayToVector3(measurement.distanceLabel.points[1]),
            ),
            positions_for_distance: measurement.distanceLabel.points,
          },
        },
      }),
    )
  }

  /**
   * Save measurement to DB
   *
   * @param measurement Measurement to save
   */
  const saveCustomMeasurement = useCallback(
    async (measurement: CustomMeasurement) => {
      if (!project || !inspectionArea || !inspectionSheet) return

      const token = await getAccessToken()
      if (!token) return

      const item = await addInspectionItem(
        token,
        project.project_id,
        inspectionArea.inspection_area_id,
        inspectionSheet.inspection_sheet_id,
        {
          part_name: '', // leave empty for generic name on display until user changes it
          item_type: 'length_with_distance_tool',
          length_with_distance_tool: {
            estimated_value: pointArrayToVector3(measurement.distanceLabel.points[0]).distanceTo(
              pointArrayToVector3(measurement.distanceLabel.points[1]),
            ),
            positions_for_distance: measurement.distanceLabel.points,
          },
        },
        showErrorModal,
      )

      // update the label with the inspection item
      if (item) {
        dispatch(
          updateMeasurementFn({
            ...measurement,
            inspectionItem: {
              ...item,
              part_name: item.part_name || `距離${zeroPad(measurements.length, zeroPlaces)}`,
            },
          }),
        )

        // gotta add the inspection item to the inspection items list as well
        setInspectionItems((items) => items.concat(item))
      }
    },
    [
      dispatch,
      getAccessToken,
      inspectionArea,
      inspectionSheet,
      measurements.length,
      project,
      setInspectionItems,
      showErrorModal,
      updateMeasurementFn,
    ],
  )

  /**
   * Update measurement to DB
   *
   * @param measurementId Measurement ID to update
   */
  const updateMeasurement = useCallback(
    async (measurementId: string) => {
      if (!project || !inspectionArea || !inspectionSheet) return

      const measurement = measurements.find((measure) => measure.id === measurementId)
      if (!measurement?.inspectionItem) return

      // get original, unmodified inspection item
      const inspectionItem = inspectionItems.find(
        (item) => item.inspection_item_id === measurement.inspectionItem?.inspection_item_id,
      )
      if (!inspectionItem) return

      const token = await getAccessToken()
      if (!token) return

      // abort previous request if it's still running and create a new controller
      if (axiosUpdateController[measurementId]) axiosUpdateController[measurementId].abort()
      const abortController = new AbortController()
      setAxiosUpdateController((controllers) => ({ ...controllers, [measurementId]: abortController }))

      const result = await updateInspectionItem(
        token,
        project.project_id,
        inspectionArea.inspection_area_id,
        inspectionSheet.inspection_sheet_id,
        {
          ...inspectionItem, // use the original inspection item, otherwise we may save some FE only data (eg: part_name that has not been defined yet)
          length_with_distance_tool: {
            estimated_value: pointArrayToVector3(measurement.distanceLabel.points[0]).distanceTo(
              pointArrayToVector3(measurement.distanceLabel.points[1]),
            ),
            positions_for_distance: measurement.distanceLabel.points,
          },
        } as InspectionItem,
        showErrorModal,
        abortController.signal,
      )

      // gotta udpate the inspection item in the inspection items list as well
      if (result) {
        setInspectionItems((items) =>
          items.map((item) => (item.inspection_item_id === result.inspection_item_id ? result : item)),
        )
      }
    },
    [
      inspectionArea,
      inspectionSheet,
      inspectionItems,
      measurements,
      project,
      axiosUpdateController,
      setInspectionItems,
      showErrorModal,
      getAccessToken,
    ],
  )

  const onDown = useCallback(
    (id: string, anchorIndex: number) => {
      changeIsDragging(true) // toggling this back to false is managed by MainCanvas::onMouseUp
      setDraggingLabelAnchor({ id, anchorIndex })
    },
    [changeIsDragging],
  )

  /**
   * Fetch measurement items from inspection items
   */
  useEffect(
    () => {
      const data = inspectionItems
        .filter((item) => item.item_type === 'length_with_distance_tool')
        .map<CustomMeasurement>((item, index) => {
          // If the item is already in customMeasurements, use that instead
          const existingMeasurement = customMeasurements.find(
            (measurement) => measurement.inspectionItem?.inspection_item_id === item.inspection_item_id,
          )

          return {
            id: uniqueId(TEMP_ID_PREFIX),
            distanceLabel: existingMeasurement
              ? existingMeasurement.distanceLabel
              : {
                  ...DISTANCE_LABEL_STYLES,
                  id: uniqueId(TEMP_ID_PREFIX),
                  points: item.length_with_distance_tool!.positions_for_distance!,
                  label: getDistanceLabel(item.length_with_distance_tool!.estimated_value!),
                },
            inspectionItem: {
              ...item,
              part_name: item.part_name || `距離${zeroPad(index + 1, zeroPlaces)}`,
            },
          }
        })
        .concat(customMeasurements.filter((measurement) => !measurement.inspectionItem))

      dispatch(setCustomMeasurements(data))
    },
    // Ignore adjustments to customMeasurements
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inspectionItems, dispatch],
  )

  /**
   * When user changes to a different tool, remove working anchor
   */
  useEffect(
    () => {
      if (isToolSelected) {
        dispatch(setCursor(CursorState.CROSSHAIR))
      } else if (prevSelectedTool === EDITOR_TOOLS.CUSTOM_MEASUREMENT) {
        dispatch(setCursor(CursorState.DEFAULT))
        const prevLabel = measurements.length > 0 ? measurements[measurements.length - 1].distanceLabel.points : null

        // If no point has been placed or only one point has been placed, remove the label
        if (prevLabel?.length === 1 || (firstPointPlaced && prevLabel?.length === 2)) {
          dispatch(setMeasurementFn(measurements.slice(0, -1)))
          setFirstPointPlaced(false)
        }
      }
    },
    // We only care for change of tool, ignore the rest.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isToolSelected, prevSelectedTool],
  )

  return {
    objects: {
      distanceLabels: customMeasurements
        .concat(tempMeasurements)
        // When dragging, hide working label if there's only 1 anchor
        .filter(
          (label) =>
            !label.invisible &&
            (!draggingLabelAnchor || (draggingLabelAnchor && label.distanceLabel.points.length > 1)),
        )
        .map<DistanceLabelProps>((label, index) => ({
          ...label.distanceLabel,
          ...(label.selected || !label.inspectionItem ? DISTANCE_LABEL_STYLES_WORKING : DISTANCE_LABEL_STYLES),
          label: label.selected ? label.distanceLabel.label : undefined,
          id: `custom-measurement-${label.inspectionItem?.inspection_item_id || label.id || index}`,
          labelOutlineColor: label.selected ? '#fff' : undefined,
          // lineOutline: true,
          anchorScale: label.selected ? 1.5 : undefined,
          onDown: label.inspectionItem
            ? (anchorIndex: number) => {
                onDown(label.id, anchorIndex)
              }
            : undefined,
          onEnter: () => {
            if (
              (isToolSelected || selectedTool === EDITOR_TOOLS.MOVE) &&
              !draggingLabelAnchor &&
              !label.id.startsWith(WORKING_ID_PREFIX) &&
              ((label.inspectionItem?.inspection_item_id && isAllowedToDetect) ||
                (!label.inspectionItem?.inspection_item_id && !isAllowedToDetect))
            )
              dispatch(setCursor(CursorState.GRAB))
          },
          onLeave: () => {
            if (!draggingLabelAnchor && (isToolSelected || selectedTool === EDITOR_TOOLS.MOVE))
              dispatch(setCursor(selectedTool === EDITOR_TOOLS.MOVE ? CursorState.DEFAULT : CursorState.CROSSHAIR))
          },
        })),
    },
    events: {
      /**
       * User clicks on the canvas to confirm the anchor.
       * - If the latest distance label has only 1 anchor, just flag it. No need to do anything else
       *   as onMove will handle the rest.
       * - If the latest distance label already has 2 anchor, create a new working distance label with 1 anchor.
       *
       * @param e Mouse event
       * @param points Clicked point in 3D Space
       */
      onMouseUp(e, points) {
        if (draggingLabelAnchor) {
          void updateMeasurement(draggingLabelAnchor.id)
          setDraggingLabelAnchor(null)
        } else if (points && isToolSelected) {
          if (firstPointPlaced) {
            const lastLabel = last(measurements)

            if (lastLabel) {
              const newLabel = {
                ...lastLabel,
                id: uniqueId(TEMP_ID_PREFIX),
                distanceLabel: {
                  ...lastLabel.distanceLabel,
                  ...DISTANCE_LABEL_STYLES,
                  id: uniqueId(TEMP_ID_PREFIX),
                },
              }

              const updatedLabels = [
                ...measurements.slice(0, -1),
                newLabel,
                {
                  id: uniqueId(WORKING_ID_PREFIX),
                  distanceLabel: {
                    ...DISTANCE_LABEL_STYLES_WORKING,
                    id: uniqueId(WORKING_ID_PREFIX),
                    points: [points],
                  },
                },
              ]

              dispatch(setMeasurementFn(updatedLabels))
              if (isAllowedToDetect) {
                void saveCustomMeasurement(newLabel)
              } else {
                saveTempMeasurement(newLabel)
              }
            }
          }

          setFirstPointPlaced(!firstPointPlaced)
        }
      },
      /**
       * On movement of the mouse, add/update the working distance label.
       * - If there's no working distance label, an anchor will be added and always follow mouse movement.
       * - If there's a working distance label, the second anchor will be added to it and follows mouse movement,
       *   plus a complete distance label will be displayed.
       *
       * @param points Point in 3D Space
       */
      onMove(points) {
        if (points) {
          if ((isToolSelected || selectedTool === EDITOR_TOOLS.MOVE) && draggingLabelAnchor) {
            const label = measurements.find((measure) => measure.id === draggingLabelAnchor.id)
            if (label) {
              const updatedPoints = [...label.distanceLabel.points]
              updatedPoints[draggingLabelAnchor.anchorIndex] = points

              dispatch(
                updateMeasurementFn({
                  ...label,
                  distanceLabel: {
                    ...label.distanceLabel,
                    label: getDistanceLabel(
                      pointArrayToVector3(updatedPoints[0]).distanceTo(pointArrayToVector3(updatedPoints[1])),
                    ),
                    points: updatedPoints,
                  },
                }),
              )
            }
          } else if (isToolSelected) {
            const lastLabel = last(measurements)
            const workLabel = lastLabel && !lastLabel.inspectionItem ? lastLabel : null
            const existingLabels = [...measurements]
            let label: CustomMeasurement

            // if first point has been placed, add/edit second point
            if (workLabel && firstPointPlaced) {
              label = {
                id: workLabel?.id || uniqueId(WORKING_ID_PREFIX),
                distanceLabel: {
                  ...DISTANCE_LABEL_STYLES_WORKING,
                  id: workLabel?.id || uniqueId(WORKING_ID_PREFIX),
                  points: [workLabel.distanceLabel.points[0], points],
                  label: getDistanceLabel(
                    pointArrayToVector3(workLabel.distanceLabel.points[0]).distanceTo(pointArrayToVector3(points)),
                  ),
                },
              }
              existingLabels.pop()
            } else {
              // add/edit first point
              label = {
                id: workLabel?.id || uniqueId(WORKING_ID_PREFIX),
                distanceLabel: {
                  id: workLabel?.id || uniqueId(WORKING_ID_PREFIX),
                  ...DISTANCE_LABEL_STYLES_WORKING,
                  points: [points],
                },
              }

              // Only remove the last label if it's a working label, otherwise we'll be removing a completed label
              if (workLabel?.distanceLabel.points?.length === 1) existingLabels.pop()
            }

            dispatch(setMeasurementFn([...existingLabels, label]))
          }
        }
      },
    },
  }
}

export default useMainCanvas
