import * as chakraUiIcons from '@chakra-ui/icons'
import { AiOutlineFolderOpen } from 'react-icons/ai'
import {
  BiArrowBack,
  BiCaretLeft,
  BiCaretRight,
  BiCheck,
  BiCheckCircle,
  BiChevronDown,
  BiChevronLeft,
  BiChevronUp,
  BiCrosshair,
  BiCube,
  BiDotsHorizontalRounded,
  BiDownload,
  BiErrorCircle,
  BiExport,
  BiGrid,
  BiHelpCircle,
  BiHide,
  BiImageAdd,
  BiListUl,
  BiMenu,
  BiMinus,
  BiPencil,
  BiPlus,
  BiReset,
  BiShow,
  BiTrash,
  BiX,
} from 'react-icons/bi'
import { BsFillCircleFill, BsPencil, BsScissors } from 'react-icons/bs'
import { FaChevronDown, FaChevronUp, FaExternalLinkAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { LuArrowDownUp } from 'react-icons/lu'
import { MdLockReset, MdOutlineCancel, MdOutlineVerticalAlignBottom, MdOutlineVerticalAlignTop } from 'react-icons/md'
import { PiCrosshair, PiFilePdfDuotone, PiGearSixBold } from 'react-icons/pi'
import { RiAlertFill } from 'react-icons/ri'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { SiMicrosoftexcel } from 'react-icons/si'
import { TbChartGridDots } from 'react-icons/tb'

import { ReactComponent as Comment } from './icons/comment.svg'
import { ReactComponent as CubeBack } from './icons/cube-back.svg'
import { ReactComponent as CubeDown } from './icons/cube-down.svg'
import { ReactComponent as CubeFront } from './icons/cube-front.svg'
import { ReactComponent as CubeLeft } from './icons/cube-left.svg'
import { ReactComponent as CubeRight } from './icons/cube-right.svg'
import { ReactComponent as CubeUp } from './icons/cube-up.svg'
import { ReactComponent as Cuboid } from './icons/cuboid.svg'
import { ReactComponent as LogoIconOnly } from './icons/logo-icon.svg'
import { ReactComponent as LogoInverted } from './icons/logo-inverted.svg'
import { ReactComponent as Logo } from './icons/logo.svg'
import { ReactComponent as Move } from './icons/move.svg'
import { ReactComponent as ToolbarAxis1 } from './icons/toolbar-axis-1.svg'
import { ReactComponent as ToolbarAxis2 } from './icons/toolbar-axis-2.svg'
import { ReactComponent as ToolbarAxis3 } from './icons/toolbar-axis-3.svg'
import { ReactComponent as ToolbarComment } from './icons/toolbar-comment.svg'
import { ReactComponent as ToolbarCylinder } from './icons/toolbar-cylinder.svg'
import { ReactComponent as ToolbarDepth } from './icons/toolbar-depth.svg'
import { ReactComponent as ToolbarDistance } from './icons/toolbar-distance.svg'
import { ReactComponent as ToolbarMove } from './icons/toolbar-move.svg'
import { ReactComponent as ToolbarPlane } from './icons/toolbar-plane.svg'
import { ReactComponent as ToolbarRotate } from './icons/toolbar-rotate.svg'
import { ReactComponent as ToolbarScale } from './icons/toolbar-scale.svg'
import { ReactComponent as ToolbarTranslate } from './icons/toolbar-translate.svg'
import { ReactComponent as ToolbarVolumePolygon } from './icons/toolbar-volume-estimation-polygon.svg'

export const {
  ChevronRightIcon, //* >矢印
  ChevronDownIcon,
  CloseIcon, //* 閉じる
  WarningTwoIcon,
} = chakraUiIcons

export const ExternalLink = FaExternalLinkAlt

export const AddIcon = BiPlus
export const MinusIcon = BiMinus
export const AddImageIcon = BiImageAdd
export const AlertFillIcon = RiAlertFill
export const BackIcon = BiArrowBack
export const BigChevronDownIcon = FaChevronDown
export const BigChevronUpIcon = FaChevronUp
export const CancelIcon = MdOutlineCancel
export const CaretLeftIcon = BiCaretLeft
export const CaretRightIcon = BiCaretRight
export const CircleFillIcon = BsFillCircleFill
export const CheckCircleIcon = BiCheckCircle
export const ChevronLeftIcon = BiChevronLeft
export const ExpandIcon = BiChevronDown
export const ErrorIcon = BiErrorCircle
export const CollapseIcon = BiChevronUp
export const DeleteIcon = BiTrash
export const DiameterIcon = BiCrosshair
export const DownloadIcon = BiDownload
export const ExportIcon = BiExport
export const FolderIcon = AiOutlineFolderOpen
export const HDotsIcon = BiDotsHorizontalRounded
export const HideIcon = BiHide
export const HelpCircleIcon = BiHelpCircle
export const InputEditorCancelIcon = BiX
export const InputEditorConfirmIcon = BiCheck
export const InputEditorEditIcon = BiPencil
export const ListIcon = BiListUl
export const LogoDark = Logo
export const LogoLight = LogoInverted
export const LogoIcon = LogoIconOnly
export const MenuIcon = BiMenu
export const PencilIcon = BsPencil
export const BottomPlaneIcon = MdOutlineVerticalAlignBottom
export const TopPlaneIcon = MdOutlineVerticalAlignTop
export const ShowIcon = BiShow
export const ResetIcon = BiReset
export const ToolbarCommentIcon = ToolbarComment
export const ToolbarCylinderIcon = ToolbarCylinder
export const ToolbarPlaneIcon = ToolbarPlane
export const ToolbarDistanceIcon = ToolbarDistance
export const ToolbarMoveIcon = ToolbarMove
export const ToolbarFocusButton = PiCrosshair
export const ToolbarVolumeIcon = ToolbarVolumePolygon
export const ToolbarVolumeIconPolygon = ToolbarVolumePolygon
export const ToolbarDepthIcon = ToolbarDepth
export const ToolbarScaleIcon = ToolbarScale
export const ToolbarTranslateIcon = ToolbarTranslate
export const ToolbarRotateIcon = ToolbarRotate
export const ToolbarAxis1Icon = ToolbarAxis1
export const ToolbarAxis2Icon = ToolbarAxis2
export const ToolbarAxis3Icon = ToolbarAxis3
export const ToolbarMaskRegion = BsScissors
export const ToolbarGridIcon = TbChartGridDots
export const CubeUpIcon = CubeUp
export const CubeDownIcon = CubeDown
export const CubeLeftIcon = CubeLeft
export const CubeRightIcon = CubeRight
export const CubeFrontIcon = CubeFront
export const CubeBackIcon = CubeBack
export const PDFIcon = PiFilePdfDuotone
export const ExcelIcon = SiMicrosoftexcel
export const CubeIcon = BiCube
export const GridIcon = BiGrid
export const CuboidIcon = Cuboid

export const ResetGridLockIcon = MdLockReset

export const CommentDisplayIcon = Comment
export const CommentMoveIcon = Move

export const SettingIcon = PiGearSixBold
export const EditBoxIcon = FiEdit
export const OrderIcon = LuArrowDownUp
export const OrderGrabIcon = RxDragHandleDots2
