import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { isMobile } from 'react-device-detect'

import { LayerStatus, PointArray } from 'interfaces/attribute'

export interface PlaneDetectionState {
  /**
   * Working points when drawing plane.
   */
  workingPoints: PointArray[]

  /**
   * Completed planes.
   */
  planes: ({
    id: string
    points: PointArray[]
  } & LayerStatus)[]
  /**
   * Tool is loading.
   */
  isLoading: boolean

  /**
   * Flag to indicate if the polygon is closing.
   */
  isClosing: boolean

  /**
   * Flag to indicate if the tool is dirty (has working items).
   */
  isDirty: boolean
}

const initialState: PlaneDetectionState = {
  workingPoints: [],
  planes: [],
  isLoading: false,
  isClosing: false,
  isDirty: false,
}

export const slice = createSlice({
  name: 'editor/tools/planeDetection',
  initialState,
  reducers: {
    /**
     * Add a new working point.
     */
    addWorkingPoint: (state, action: PayloadAction<PointArray>) => {
      state.workingPoints.push(action.payload)
      state.isDirty = true
    },

    /**
     * Update last working point.
     */
    updateLastWorkingPoint: (state, action: PayloadAction<PointArray>) => {
      if (state.workingPoints.length) {
        state.workingPoints[state.workingPoints.length - 1] = action.payload
      } else {
        state.workingPoints.push(action.payload)
      }
    },

    /**
     * Add a new plane.
     */
    addPlane: (state, action: PayloadAction<PointArray[]>) => {
      state.planes.push({
        id: crypto.randomUUID(),
        points: action.payload,
      })
    },

    /**
     * Complete drawing a plane.
     */
    completePlane: (state, action: PayloadAction<{ removeLast: boolean }>) => {
      state.planes.push({
        id: crypto.randomUUID(),
        points: [
          ...(action.payload.removeLast ? state.workingPoints.slice(0, -1) : state.workingPoints),
          state.workingPoints[0],
        ],
      })
      state.workingPoints = []
    },

    /**
     * Remove a completed plane.
     */
    removePlane: (state, action: PayloadAction<number>) => {
      state.planes.splice(action.payload, 1)
      state.isDirty = state.planes.length > 0 || state.workingPoints.length > (isMobile ? 0 : 1)
    },

    /**
     * Toggle visibility of all planes.
     */
    togglePlaneVisibility: (state, action: PayloadAction<number>) => {
      state.planes[action.payload].invisible = !state.planes[action.payload].invisible
    },

    /**
     * Toggle visibility of all planes.
     */
    toggleAllPlaneVisibility: (state) => {
      state.planes.forEach((plane) => {
        plane.invisible = !plane.invisible
      })
    },

    /**
     * Update a plane anchor.
     */
    updatePlaneAnchor: (
      state,
      action: PayloadAction<{ planeIndex: number; anchorIndex: number; position: PointArray }>,
    ) => {
      if (state.planes[action.payload.planeIndex]) {
        state.planes[action.payload.planeIndex].points[action.payload.anchorIndex] = action.payload.position

        // If first point is updated, update the last point as well, and vice versa.
        if (action.payload.anchorIndex === 0) {
          state.planes[action.payload.planeIndex].points[state.planes[action.payload.anchorIndex].points.length - 1] =
            action.payload.position
        }

        if (action.payload.anchorIndex === state.planes[action.payload.planeIndex].points.length - 1) {
          state.planes[action.payload.planeIndex].points[0] = action.payload.position
        }
      }
    },

    /**
     * Set loading state.
     */
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },

    /**
     * Set closing state.
     */
    setIsClosing: (state, action: PayloadAction<boolean>) => {
      state.isClosing = action.payload
    },

    /**
     * Reset working points.
     */
    resetWorkingPoints: (state) => {
      state.workingPoints = []
      state.isDirty = state.planes.length > 0
    },

    /**
     * Reset all
     */
    reset: () => initialState,
  },
})

export const {
  addWorkingPoint,
  updateLastWorkingPoint,
  addPlane,
  completePlane,
  removePlane,
  togglePlaneVisibility,
  toggleAllPlaneVisibility,
  updatePlaneAnchor,
  setIsLoading,
  setIsClosing,
  resetWorkingPoints,
  reset,
} = slice.actions

export default slice.reducer
