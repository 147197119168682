import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { ThreeEvent, useFrame } from '@react-three/fiber'
import { Material, MeshStandardMaterial, SphereGeometry, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import { CircleAnchorProps } from 'interfaces/canvas'

const outerMaterial = new MeshStandardMaterial({
  color: 'white',
  transparent: true,
  depthTest: false,
  depthWrite: false,
})

const outerGeometry = new SphereGeometry(0.002, 32, 16)
const innerGeometry = new SphereGeometry(0.0015, 32, 16)

const CircleAnchor: FC<CircleAnchorProps> = ({
  groupRef,
  point,
  scale = 1,
  hoverScale = 1.1,
  color,
  outlineColor = 'white',
  opacity = 1,
  tooltipText,
  renderOrder = 1,
  onDown,
  onEnter,
  onLeave,
}) => {
  // Context
  const { selectedTool, cameraRef } = useContext(EditorContext)

  // States
  const [scaleFactor, setScaleFactor] = useState(1)
  const [outlineMaterial, setOutlineMaterial] = useState<Material>(outerMaterial.clone())

  /**
   * Set material for outline
   */
  useEffect(() => {
    const newMat = outerMaterial.clone()
    newMat.color.set(outlineColor)
    setOutlineMaterial(newMat)
  }, [outlineColor])

  /**
   *  To keep the anchor size constant when zooming in/out.
   *  Use the camera's directional distance to prevent the anchor from appearing larger as it goes to the edge of the screen.
   *  NOTE: If this is heavy, consider using a distance from the camera to the ABControls' gizmo point for all anchors.
   *        The results will vary, but they look good.
   */
  useFrame(() => {
    if (cameraRef.current) {
      // distance from the camera to the anchor point
      const cameraToAnchor = new Vector3(...point).sub(cameraRef.current.position.clone())
      // angle between the camera's forward vector and the vector from the camera to the anchor point
      const angle = cameraToAnchor.angleTo(new Vector3(0, 0, -1).applyQuaternion(cameraRef.current.quaternion.clone()))
      // center position of viewing plane calculated from the camera's position and the distance to the anchor point
      const centerOfTheAnchorViewingPlane = cameraRef.current.position
        .clone()
        .sub(
          new Vector3(0, 0, cameraToAnchor.length() * Math.cos(angle)).applyQuaternion(
            cameraRef.current.quaternion.clone(),
          ),
        )
      setScaleFactor(
        Math.min(Math.max(cameraRef.current.position.distanceTo(centerOfTheAnchorViewingPlane) * 6, 1), 10),
      )
    }
  })

  // State
  const [isHovered, setIsHovered] = useState(false)

  /**
   * Event Handlers (pointer enter/hover)
   */
  const onPointerEnter = useCallback(() => {
    if (onEnter) onEnter()

    setIsHovered(true)
  }, [onEnter])

  /**
   * Event Handlers (pointer leave)
   */
  const onPointerLeave = useCallback(() => {
    if (onLeave) onLeave()

    setIsHovered(false)
  }, [onLeave])

  return (
    <group renderOrder={renderOrder} position={new Vector3(...point)} ref={groupRef}>
      <mesh
        scale={isHovered ? hoverScale * scaleFactor : scale * scaleFactor}
        onPointerDown={(e: ThreeEvent<PointerEvent>) => {
          if (selectedTool === EDITOR_TOOLS.FOCUS || e.nativeEvent.button !== 0) return
          if (onDown) onDown()
        }}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        geometry={outerGeometry}
        material={outlineMaterial}
      />
      <mesh scale={isHovered && onDown ? hoverScale * scaleFactor : scale * scaleFactor} geometry={innerGeometry}>
        <meshStandardMaterial color={color} depthTest={false} depthWrite={false} opacity={opacity} transparent />
      </mesh>

      {/* Tooltip */}
      {!!tooltipText && isHovered && (
        <Html style={{ transform: 'translateX(-50%) translateY(-200%)' }} zIndexRange={[1, 9]}>
          <Box
            backgroundColor="yellow"
            py={0}
            px={4}
            opacity={opacity}
            fontSize="80%"
            fontWeight="bold"
            color="black"
            whiteSpace="nowrap"
          >
            {tooltipText}
          </Box>
        </Html>
      )}
    </group>
  )
}
export default CircleAnchor
