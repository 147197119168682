import { FC, useMemo, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { clamp } from 'lodash'
import { Vector3 } from 'three'
import { Line2, LineGeometry, LineMaterial } from 'three-stdlib'

import { DistanceLabelProps, LineStyle } from 'interfaces/canvas'

import { getCenterOfTwoPoints } from 'services/Editor'

import CircleAnchor from './CircleAnchor'

const matLineSolid = new LineMaterial({
  color: 0xffffff,
  transparent: true,
  alphaToCoverage: true,
  worldUnits: true,
  depthTest: false,
  depthWrite: false,
})

const matLineDashed = new LineMaterial({
  // default dash styles
  dashed: true,
  dashScale: 400,
  dashSize: 2,
  gapSize: 1.5,

  transparent: true,
  alphaToCoverage: true,
  worldUnits: true,
  depthTest: false,
  depthWrite: false,
})

/**
 * Label for distance between two points.
 */
const DistanceLabel: FC<DistanceLabelProps> = ({
  id,
  points,
  label,
  hideAnchors = false,
  anchorScale = 1,
  anchorHoverScale = 1.1,
  anchorColor = '#88DF41',
  anchorOutlineColor = '#FFFFFF',
  topAnchorColor,
  bottomAnchorColor,
  lineOutline,
  lineStyle = LineStyle.Dashed,
  lineColor = '#333333',
  lineThickness = 0.001,
  labelBgColor = '#88DF41',
  labelTextColor = '#000',
  labelOutlineColor,
  labelBorderWidth = 2,
  opacity = 1,
  onDown,
  onEnter,
  onLeave,
}) => {
  const { camera } = useThree()

  const center = points[1] ? getCenterOfTwoPoints(new Vector3(...points[0]), new Vector3(...points[1])) : null
  const actualTopAnchorColor = topAnchorColor || anchorColor
  const actualBottomAnchorColor = bottomAnchorColor || anchorColor

  // States
  const [scaleFactor, setScaleFactor] = useState(0)

  /**
   * Main line
   */
  const line = useMemo(() => {
    const geometry = new LineGeometry()
    geometry.setPositions(points.flat())

    const mat = lineStyle === LineStyle.Solid ? matLineSolid.clone() : matLineDashed.clone()
    mat.color.set(lineColor)
    // gradually increase line width by scaleFactor, thinner line at low scaleFactor, thicker line at high scaleFactor
    mat.linewidth = lineThickness + scaleFactor ** 2 * 0.005
    mat.dashSize = matLineDashed.dashSize + scaleFactor ** 2 * 4
    mat.gapSize = matLineDashed.gapSize + scaleFactor ** 2 * 4

    const ln = new Line2(geometry, mat)
    ln.renderOrder = 2
    ln.computeLineDistances()
    ln.scale.set(1, 1, 1)

    return ln
  }, [points, lineStyle, lineColor, lineThickness, scaleFactor])

  /**
   * Outline line
   */
  const outline = useMemo(() => {
    if (!lineOutline) return null

    const geometry = new LineGeometry()
    geometry.setPositions(points.flat())

    const mat = matLineSolid.clone() // lineStyle === LineStyle.Solid ? matLineSolid.clone() : matLineDashed.clone()

    const outlineMat = mat.clone()
    outlineMat.color.set('white')
    outlineMat.linewidth = lineThickness + Math.max(0.0025, lineThickness * 0.5) + scaleFactor ** 2 * 0.01
    outlineMat.dashSize = matLineDashed.dashSize + scaleFactor ** 2 * 4
    outlineMat.gapSize = matLineDashed.gapSize + scaleFactor ** 2 * 4

    const outlineLn = new Line2(geometry.clone(), outlineMat)
    outlineLn.renderOrder = 1
    outlineLn.computeLineDistances()
    outlineLn.scale.set(1, 1, 1)

    return outlineLn
  }, [points, lineOutline, lineThickness, scaleFactor])

  /**
   *  To keep the anchor size constant when zooming in/out.
   *  Use the camera's directional distance to prevent the anchor from appearing larger as it goes to the edge of the screen.
   *  NOTE: If this is heavy, consider using a distance from the camera to the ABControls' gizmo point for all anchors.
   *        The results will vary, but they look good.
   */
  useFrame(() => {
    if (camera && center) {
      // distance from the camera to the anchor point
      const cameraToAnchor = center.clone().sub(camera.position.clone())
      // angle between the camera's forward vector and the vector from the camera to the anchor point
      const angle = cameraToAnchor.angleTo(new Vector3(0, 0, -1).applyQuaternion(camera.quaternion.clone()))
      // center position of viewing plane calculated from the camera's position and the distance to the anchor point
      const centerOfTheAnchorViewingPlane = camera.position
        .clone()
        .sub(new Vector3(0, 0, cameraToAnchor.length() * Math.cos(angle)).applyQuaternion(camera.quaternion.clone()))
      setScaleFactor(clamp(camera.position.distanceTo(centerOfTheAnchorViewingPlane) * 6, 1, 10) / 10)
    }
  })

  return (
    // super hight rendering order to make sure they're always on top
    <group>
      {/* Anchor 1 */}
      {!hideAnchors && (
        <CircleAnchor
          id={`distance-label-${id}-anchor-1`}
          renderOrder={3}
          point={points[0]}
          scale={anchorScale}
          hoverScale={anchorHoverScale}
          color={actualTopAnchorColor}
          outlineColor={anchorOutlineColor}
          opacity={opacity}
          onDown={onDown ? () => onDown(0) : undefined}
          onEnter={onEnter ? () => onEnter(0) : undefined}
          onLeave={onLeave ? () => onLeave(0) : undefined}
        />
      )}

      {/* Anchor 2 */}
      {points[1] && (
        <>
          {/* Line */}
          {lineOutline && outline && <primitive object={outline} />}
          {line && <primitive object={line} />}

          {!hideAnchors && (
            <CircleAnchor
              id={`distance-label-${id}-anchor-2`}
              renderOrder={3}
              point={points[1]}
              scale={anchorScale}
              hoverScale={anchorHoverScale}
              color={actualBottomAnchorColor}
              outlineColor={anchorOutlineColor}
              opacity={opacity}
              onDown={onDown ? () => onDown(1) : undefined}
              onEnter={onEnter ? () => onEnter(1) : undefined}
              onLeave={onLeave ? () => onLeave(1) : undefined}
            />
          )}

          {/* Text label */}
          {label && (
            <Html
              position={center || 0}
              style={{ transform: 'translateX(-50%) translateY(-50%)', pointerEvents: 'none' }}
              zIndexRange={[1, 9]}
            >
              <Box
                backgroundColor={labelBgColor}
                px={5}
                fontSize="80%"
                fontWeight="bold"
                color={labelTextColor}
                whiteSpace="nowrap"
                borderWidth={labelBorderWidth}
                borderColor={labelOutlineColor || 'transparent'}
                opacity={opacity}
              >
                {label}
              </Box>
            </Html>
          )}
        </>
      )}
    </group>
  )
}

export default DistanceLabel
