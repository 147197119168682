import { useCallback, useContext, useRef, useState } from 'react'

import { Text, useToast } from '@chakra-ui/react'
import { Project as PDLProject } from 'project-dashboard-library/dist/interfaces/project'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES, UPLOAD_LIMIT_BYTE, UPLOAD_LIMIT_GIGABYTE } from 'config/constants'
import { TOAST_CONFIG } from 'config/styles'

import { InspectionArea, InspectionSheetPlaneDiagram } from 'interfaces/inspection'
import { Project } from 'interfaces/project'

import { deleteInspectionArea } from 'services/InspectionArea'
import { deleteProject } from 'services/Projects'
import { checkSize } from 'services/Validation'

import { CreateInspectionAreaModalForwardRef } from '../components/InspectionAreaFormModal'
import { fetchProjects } from '../store/dashboard'

export const useProjectsTable = () => {
  const toast = useToast()
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  // Store
  const dispatch = useAppDispatch()
  const userProfile = useSelector((root: RootState) => root.user.userProfile)

  // States
  const [planeDiagrams, setPlaneDiagrams] = useState<Record<string, InspectionSheetPlaneDiagram>>({})

  // React router
  const navigate = useNavigate()

  /**
   * Show success toas after creating project
   */
  const onProjectUpdated = useCallback(() => {
    toast({
      ...TOAST_CONFIG,
      title: '工事を変更しました',
      containerStyle: { top: '120px', position: 'relative' }, // push it down to a more visible position
    })
  }, [toast])

  /**
   * Handle project deletion
   *
   * @param project - The project to be deleted
   */
  const handleProjectDelete = useCallback(
    (project: PDLProject) => {
      showModal({
        body: (
          <>
            <Text>一度削除してしまうと、元に戻せません。</Text>
            <Text mt="1" fontWeight="semibold">
              {project.project_name}
            </Text>
          </>
        ),
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirm: () => {
          const deleteProjectApi = async () => {
            const access_token = await getAccessToken()
            if (!access_token) {
              return false
            }

            const deleteResult = await deleteProject(access_token, project.project_id, showErrorModal)
            if (!deleteResult) {
              return false
            }

            await dispatch(fetchProjects({ access_token, showErrorModal }))
            return true
          }

          void deleteProjectApi()
          return true
        },
        title: '工事を削除しますか？',
      })
    },
    [getAccessToken, dispatch, showModal, showErrorModal],
  )

  /**
   * Inspection area creation modal
   */
  const InspectionAreaFormModalRef = useRef<CreateInspectionAreaModalForwardRef>()
  const openInspectionAreaFormModal = useCallback(
    (
      project: PDLProject,
      inspectionArea?: InspectionArea,
      inspectionAreas?: InspectionArea[] | null,
      onSaved?: () => Promise<void>,
    ) => {
      // アップロードされた合計ファイルサイズが10GB以上の場合、エラー
      if (!inspectionArea && !checkSize(userProfile?.stats?.total_size || 0, UPLOAD_LIMIT_BYTE)) {
        showErrorModal(`アップロードファイルの合計が${UPLOAD_LIMIT_GIGABYTE}GBを超えています。`)
        return
      }

      InspectionAreaFormModalRef.current?.openModal(project as Project, inspectionArea, inspectionAreas, onSaved)
    },
    [userProfile, showErrorModal],
  )

  /**
   * Open confirm Inspection Area delete modal
   */
  const confirmInspectionAreaDelete = useCallback(
    (project: PDLProject, inspectionArea: InspectionArea, onDeleted?: () => Promise<void>) => {
      showModal({
        body: (
          <>
            <Text>一度削除してしまうと、元に戻せません。</Text>
            <Text mt="1" fontWeight="semibold">
              {inspectionArea.inspection_area_name}
            </Text>
          </>
        ),
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirmPromised: async () => {
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const deleteResult = await deleteInspectionArea(
            token,
            project.project_id,
            inspectionArea.inspection_area_id,
            showErrorModal,
          )
          if (!deleteResult) {
            return false
          }

          if (onDeleted) await onDeleted()
          return true
        },
        title: '検査箇所を削除しますか？',
      })
    },
    [getAccessToken, showModal, showErrorModal],
  )

  /**
   * Go to project-level Blueprints page.
   */
  const goToBlueprints = useCallback(
    (project: PDLProject) => {
      navigate(`/projects/${project.project_id}/blueprints`)
    },
    [navigate],
  )

  /**
   * Go to project-level Inspection Sheet page.
   */
  const goToInspectionSheet = useCallback(
    (project: PDLProject) => {
      navigate(`/projects/${project.project_id}/inspection-sheet`)
    },
    [navigate],
  )

  /**
   * Project table header
   */
  const renderGroupHeader = useCallback(
    (header: string) => (
      <Text fontWeight="bold" mb={4} color="secondary.400">
        {header}
      </Text>
    ),
    [],
  )

  return {
    goToBlueprints,
    goToInspectionSheet,
    handleProjectDelete,
    InspectionAreaFormModalRef,
    onProjectUpdated,
    openInspectionAreaFormModal,
    renderGroupHeader,
    confirmInspectionAreaDelete,

    planeDiagrams,
    setPlaneDiagrams,
  }
}
