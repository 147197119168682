import { FC, useContext, useState } from 'react'

import { Box, Flex, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_COLLAPSE_TYPES, EDITOR_SHAPE_KEYS } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { PlaneSide, Polygon } from 'interfaces/shape'

import { shapesWithKeyShouldShow } from 'services/Util'
import { getVolumeEstimationItem } from 'services/VolumeEstimation'

import CollapsePanel from './components/CollapsePanel'
import LayerItem from './components/LayerItem'
import LayerItemDivider from './components/LayerItemDivider'
import ShapeLayer from './components/ShapeLayer'

const ShapePanel: FC = () => {
  // Store
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const userType = useSelector((state: RootState) => state.user.userType)
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // Context
  const {
    shapes,
    inspectionItems,
    isLayerModifying,
    deleteShapes,
    updateAllSelectedShapesStatus,
    changeSelectedShapeIds,
  } = useContext(EditorContext)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  // States
  const [cylinderShapesLayerCollapsed, setCylinderShapesLayerCollapsed] = useState(false)
  const [polygonShapesLayerCollapsed, setPolygonShapesLayerCollapsed] = useState(false)
  const [planeShapesLayerCollapsed, setPlaneShapesLayerCollapsed] = useState(false)
  const [isCollapseOpen, setIsCollapseOpen] = useState(true)

  // Split volume polygons and standalone plane shapes
  const volumePolygonShapes = shapes[EDITOR_SHAPE_KEYS.POLYGONS].filter(
    (shp) => getVolumeEstimationItem(inspectionItems, shp.shape_id) && (shp as Polygon).plane_side === PlaneSide.UPPER,
  )
  const standalonePlaneShapes = shapes[EDITOR_SHAPE_KEYS.POLYGONS].filter(
    (shp) => !getVolumeEstimationItem(inspectionItems, shp.shape_id),
  )

  // Vars
  const isSomeCylindersVisible = shapes[EDITOR_SHAPE_KEYS.CYLINDERS].some((status) => !status.invisible)
  const isSomePolygonsVisible = volumePolygonShapes.some((status) => !status.invisible)
  const isSomePlaneShapesVisible = standalonePlaneShapes.some((status) => !status.invisible)
  const minHeight =
    40 +
    33 *
      ((shapesWithKeyShouldShow(shapes, EDITOR_SHAPE_KEYS.CYLINDERS) ? 1 : 0) +
        (shapesWithKeyShouldShow(shapes, EDITOR_SHAPE_KEYS.POLYGONS) ? 1 : 0))

  if (!shapes[EDITOR_SHAPE_KEYS.CYLINDERS]?.length && !shapes[EDITOR_SHAPE_KEYS.POLYGONS]?.length) {
    return null
  }

  const isSomeSelectedShapesVisible = () => {
    if (
      shapes[EDITOR_SHAPE_KEYS.CYLINDERS].length &&
      selectedShapeIds.some((id) => {
        const shapeIndex = shapes[EDITOR_SHAPE_KEYS.CYLINDERS].findIndex((shape) => shape.shape_id === id)
        if (shapeIndex < 0) {
          return false
        }
        return !shapes[EDITOR_SHAPE_KEYS.CYLINDERS][shapeIndex].invisible
      })
    ) {
      return true
    }

    if (
      shapes[EDITOR_SHAPE_KEYS.POLYGONS].length &&
      selectedShapeIds.some((id) => {
        const shapeIndex = shapes[EDITOR_SHAPE_KEYS.POLYGONS].findIndex((shape) => shape.shape_id === id)
        if (shapeIndex < 0) {
          return false
        }
        return !shapes[EDITOR_SHAPE_KEYS.POLYGONS][shapeIndex].invisible
      })
    ) {
      return true
    }

    return false
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isCollapseOpen ? minHeight : 0}
      data-testid="shape-panel"
    >
      <CollapsePanel title="検出した要素" type={EDITOR_COLLAPSE_TYPES.detected} onChange={setIsCollapseOpen}>
        <VStack
          w="100%"
          spacing={0}
          pb={INFO_PANEL_PADDING - 1}
          divider={<LayerItemDivider />}
          overflowX="hidden"
          overflowY="auto"
        >
          {shapesWithKeyShouldShow(shapes, EDITOR_SHAPE_KEYS.POLYGONS) && (
            <ShapeLayer
              shapes={volumePolygonShapes}
              shapeKey={EDITOR_SHAPE_KEYS.POLYGONS}
              label={`体積 (${volumePolygonShapes.length})`}
              isSomeShapesVisible={isSomePolygonsVisible}
              collapsed={polygonShapesLayerCollapsed}
              setExpansion={setPolygonShapesLayerCollapsed}
            />
          )}
          {shapesWithKeyShouldShow(shapes, EDITOR_SHAPE_KEYS.CYLINDERS) && (
            <ShapeLayer
              shapes={shapes[EDITOR_SHAPE_KEYS.CYLINDERS]}
              shapeKey={EDITOR_SHAPE_KEYS.CYLINDERS}
              label={`鉄筋 (${shapes[EDITOR_SHAPE_KEYS.CYLINDERS].length})`}
              isSomeShapesVisible={isSomeCylindersVisible}
              collapsed={cylinderShapesLayerCollapsed}
              setExpansion={setCylinderShapesLayerCollapsed}
            />
          )}
          {shapesWithKeyShouldShow(shapes, EDITOR_SHAPE_KEYS.POLYGONS) && (
            <ShapeLayer
              shapes={standalonePlaneShapes}
              shapeKey={EDITOR_SHAPE_KEYS.POLYGONS}
              label={`面積 (${standalonePlaneShapes.length})`}
              isSomeShapesVisible={isSomePlaneShapesVisible}
              collapsed={planeShapesLayerCollapsed}
              setExpansion={setPlaneShapesLayerCollapsed}
            />
          )}
          {!!selectedShapeIds.length && (
            <Box fontWeight="bold" w="100%">
              <LayerItem
                onClick={() => changeSelectedShapeIds([])}
                disabled={isLayerModifying || !isAllowedToModify}
                invisible={!isSomeSelectedShapesVisible()}
                label={`選択した要素(${selectedShapeIds.length})`}
                updateVisibility={(invisible) => updateAllSelectedShapesStatus({ invisible })}
                deleteLayer={() => deleteShapes(true)}
              />
            </Box>
          )}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default ShapePanel
