import { Mesh, Object3D, Object3DEventMap, Raycaster, Vector3 } from 'three'

import { InspectionItem } from 'interfaces/inspection'
import { PlaneSide, Polygon, Shape, Shapes } from 'interfaces/shape'

import { millimeterToMeter } from './Util'

const raycaster = new Raycaster()

/**
 * Function to find the normal of plane A pointing towards plane B
 */
export function findNormalTowardsB(pointA: Vector3, pointB: Vector3, normalA: Vector3): Vector3 {
  // Calculate the vector from A to B
  const vectorAB = new Vector3().subVectors(pointA, pointB).normalize()

  // Check if the normal of A is pointing towards B
  return normalA.dot(vectorAB) < 0 ? normalA : normalA.negate()
}

/**
 * Get normal vector of the top plane
 */
export const getNormals = (topShapePolygon?: Polygon, topMesh?: Mesh): Vector3 => {
  let normal: Vector3 = new Vector3()
  /* istanbul ignore else */
  if (topShapePolygon) {
    const vertex1 = new Vector3(...topShapePolygon.positions[0])
    const vertex2 = new Vector3(...topShapePolygon.positions[1])
    const vertex3 = new Vector3(...topShapePolygon.positions[2])

    // Calculate the normal vector of the triangle
    normal = new Vector3()
      .crossVectors(new Vector3().subVectors(vertex1, vertex2), new Vector3().subVectors(vertex3, vertex2))
      .normalize()
  } else if (topMesh) {
    normal = topMesh.getWorldDirection(new Vector3())
  }

  return normal
}

/**
 * Project points from top plane to bottom plane
 *
 * @param points Grid points to project
 * @param topMesh Top plane mesh
 * @param bottomMesh Bottom plane mesh
 */
/* istanbul ignore next */
export const projectFromTopToBottom = (
  points: Vector3[],
  topMesh: Object3D<Object3DEventMap>,
  bottomMesh: Object3D<Object3DEventMap>,
  direction: Vector3,
) =>
  points.map((point) => {
    const startPosition = new Vector3(
      millimeterToMeter(point.x),
      millimeterToMeter(point.y),
      millimeterToMeter(point.z),
    )
    topMesh.localToWorld(startPosition)

    raycaster.set(startPosition, direction)
    const results = raycaster.intersectObject(bottomMesh, true)

    if (results.length) {
      return [startPosition.toArray(), results[0].point.toArray()]
    }

    return [startPosition.toArray()]
  })

/**
 * Update a single shape while keeping the old shape's invisible status.
 *
 * @param newShape New shape to update
 * @param oldShape Old shape to pull invisible status from
 */
export function updateShape<T extends Shape>(newShape: T, oldShape?: T): T {
  if (!oldShape || newShape.invisible !== undefined) {
    return newShape
  }

  const updated = { ...newShape }
  updated.invisible = oldShape.invisible
  return updated
}

/**
 * Find the upper plane of the inspection item
 *
 * @param shapes
 * @param inspectionItem
 */
export const findUpperPlane = (shapes: Shapes, inspectionItem: InspectionItem) =>
  shapes.polygons?.find(
    (plane) => plane.plane_side === PlaneSide.UPPER && inspectionItem?.shape_ids.polygons?.includes(plane.shape_id),
  )

/**
 *  Find the lower plane of the inspection item
 * @param shapes
 * @param inspectionItem
 * @returns
 */
export const findLowerPlane = (shapes: Shapes, inspectionItem: InspectionItem) =>
  shapes.polygons?.find(
    (plane) => plane.plane_side === PlaneSide.LOWER && inspectionItem?.shape_ids.polygons?.includes(plane.shape_id),
  )
