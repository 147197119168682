import { FC, Fragment, TouchEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { User } from '@auth0/auth0-react'
import { Box } from '@chakra-ui/react'
import { ArcballControls, PerspectiveCamera, useContextBridge } from '@react-three/drei'
import { Canvas, RootState } from '@react-three/fiber'
import LoadingPage from 'components/LoadingPage'
import { uniqueId } from 'lodash'
import {
  modifyComment,
  setEditingComment,
  setFormImages,
  setSelectedComment,
} from 'pages/projects/common/Comments/store/comments'
import { InspectionAreaDownSampleStatus } from 'project-dashboard-library/dist/interfaces/inspectionArea'
import { Provider, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { RootState as AppRootState, store, useAppDispatch } from 'store/app'
import {
  AxesHelper,
  Cache,
  Camera,
  LinearSRGBColorSpace,
  Matrix4,
  NoToneMapping,
  Object3D,
  Object3DEventMap,
  Points,
  PointsMaterial,
  Vector3,
} from 'three'
import { PCDLoader } from 'three-stdlib'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import {
  EDITOR_DOUBLE_CLICK_INTERVAL,
  EDITOR_DOUBLE_CLICK_POS_THRESHOLD,
  EDITOR_MEASURE_KEYS,
  EDITOR_MOUSE_DRAG_THRESHOLD,
  EDITOR_POINT_SIZE_INTERVAL,
  EDITOR_REQUIRED_ANCHORS,
  EDITOR_TOOLS,
  EDITOR_TOOL_CURSOR_CLASSES,
  EDITOR_TOOL_KEYS,
  MAX_EDITOR_LAYERS,
  TEMP_COMMENT_ID,
} from 'config/constants'

import { CanvasConfig, CanvasConfigObjects } from 'interfaces/editor'
import { FocusedPoint, PointArray } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from 'services/ErrorHandler'
import { getSignedUrlForGetDownSampledFile } from 'services/InspectionArea'
import { createRayCaster, createSelectedPoint, generateCuboidUniforms, getClickCoords } from 'services/Points'
import { zeroPad } from 'services/Util'

import { CursorState, setCursor } from '../store/editor'
import { Tools, getCurrentEditorTool } from '../tools/setup'
import AnchorFrames, { CuboidAnchorFrames } from './AnchorFrames'
import { BoxBoundaryMesh } from './BoxBoundary'
import CameraAnimator from './CameraAnimator'
import CircleAnchor from './CircleAnchor'
import Comments from './Comments/Comments'
import CuboidFrame from './CuboidFrame'
import { CylinderMesh } from './CylinderMesh'
import DistanceLabel from './DistanceLabel'
import PolygonPlaneMeshTransformable from './PolygonMeshTransformable'
import { PolygonPlaneMesh } from './PolygonPlaneMesh'
import WarningLabel from './WarningLabel'

const zeroPlaces = MAX_EDITOR_LAYERS.toString().length

Cache.enabled = true

const MainCanvas: FC<{
  user: User | undefined
  windowSize: {
    width: number
    height: number
  }
  addAnchor: (point: PointArray) => void
  updateAnchorPoint: (pointInfo: FocusedPoint, point: PointArray) => void
  updateDistanceAnchorPoint: (pointInfo: FocusedPoint, point: PointArray) => void
  setMovingPrefixedPosition: (position: string) => void
  movingPrefixedPosition: string
}> = ({ user, windowSize, movingPrefixedPosition, setMovingPrefixedPosition }) => {
  // URL Params
  const { project_id } = useParams<{ project_id: string }>()
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)
  const {
    backgroundColor,
    baseDiameter,
    changeIsDragging,
    changeIsMouseDown,
    changePointSize,
    changeSelectedPoint,
    changeTool,
    meshRefs,
    hoveredPoint,
    isDragging,
    isPointCloudInvisible,
    pointSize,
    inspectionArea,
    selectedTool,
    prevSelectedTool,
    shapes,
    depthEstimationTypes,
    selectionBoundaryBoxVectors,
    initCompleted: editorInitCompleted,
    cameraRef,
    arcballControlsRef,
    focusCamera,
  } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const isPageLoading = useSelector((state: AppRootState) => state.page.isLoading)
  const editingCommentId = useSelector((state: AppRootState) => state.comments.editingCommentId)
  const selectedComment = useSelector((state: AppRootState) => state.comments.selectedComment)
  const cuboidAnchor = useSelector((state: AppRootState) => state.cuboid.anchor)
  const editingCuboid = useSelector((state: AppRootState) => state.cuboid.editingCuboid)
  const maskRegions = useSelector((state: AppRootState) => state.maskPCD.regions)
  const userLoaded = useSelector((state: AppRootState) => state.user.userLoaded)
  const anchorPlacementObjectIds = useSelector((state: AppRootState) => state.editor.anchorPlacementObjects)
  const cursor = useSelector((state: AppRootState) => state.editor.cursor)
  const disablePanning = useSelector((state: AppRootState) => state.editor.disablePanning)
  const pcdTransparency = useSelector((state: AppRootState) => state.editor.pcdTransparency)

  //* canvas制御用
  const [isMouseMoving, setIsMouseMoving] = useState(false)
  const [startDraggingPoint, setStartDraggingPoint] = useState<{ x: number; y: number } | null>(null)

  //* 点群データ制御用
  const [pointCloud, setPointCloud] = useState<Points>()
  const [pcdMaterial, setPcdMaterial] = useState<PointsMaterial>()
  const [defaultPointSize, setDefaultPointSize] = useState<number>()
  const [pcdLoadingPercent, setPcdLoadingPercent] = useState<number>(0)

  //* 初回ローディング用
  const [initCompleted, setInitCompleted] = useState(false)

  //* Triple click
  const [tripleClickInitiatedTimes, setTripleClickInitiatedTimes] = useState<Date[]>([])
  const [lastMouseEvent, setLastMouseEvent] = useState<React.MouseEvent<HTMLDivElement, MouseEvent> | undefined>()

  const [tripleClickInitiatedTimesTouch, setTripleClickInitiatedTimesTouch] = useState<Date[]>([])
  const [lastTouchEvent, setLastTouchEvent] = useState<TouchEvent<HTMLDivElement> | undefined>()

  const savedCameraTarget = useMemo(
    () =>
      inspectionArea && inspectionArea.camera_profile ? new Vector3(...inspectionArea.camera_profile.target) : null,
    [inspectionArea],
  )

  // Tools activation by running hook
  const toolsHook = Tools.map((tool) => {
    const tools: {
      key: string
      config: CanvasConfig
    }[] = []
    if (tool.hooks?.useMainCanvas) {
      tools.push({
        key: tool.key,
        config: tool.hooks?.useMainCanvas({ pointCloud }),
      })
    }

    if (tool.toolbar?.variants) {
      tool.toolbar.variants.forEach((variant) => {
        if (variant.hooks?.useMainCanvas) {
          tools.push({
            key: `${tool.key}-${variant.key}`,
            config: variant.hooks.useMainCanvas({ pointCloud }),
          })
        }
      })
    }

    return tools
  })
    .flat()
    .filter(Boolean)

  const toolsObjects: {
    key: string
    objects: CanvasConfigObjects | undefined
  }[] = toolsHook
    .map((tool) => ({
      key: tool.key,
      objects: tool?.config.objects,
    }))
    .filter((obj) => obj.objects)

  const [maskModelMatrices, setMaskModelMatrices] = useState<
    {
      modelMatrix: Matrix4
      min: Vector3
      max: Vector3
    }[]
  >([])
  const isViewAllPoints =
    selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID ||
    maskRegions.filter((region) => region.invisible).length === maskRegions.length

  const getSelectedPoint = useCallback(
    (
      mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
      touchEvent?: React.TouchEvent<HTMLDivElement>,
    ): PointArray | undefined => {
      if (!cameraRef?.current) return undefined

      //* カメラ取得
      const camera = cameraRef.current as Camera

      //* 光線生成とクリック位置特定
      const rayCaster = createRayCaster()
      const targetElem = document.getElementById('canvasBox')?.getBoundingClientRect()
      if (!targetElem) {
        return undefined
      }

      const { x, y, width, height } = targetElem
      const { coords } = getClickCoords(
        {
          x: mouseEvent?.clientX || touchEvent?.touches[0].clientX || 0,
          y: mouseEvent?.clientY || touchEvent?.touches[0].clientY || 0,
        },
        {
          x,
          y,
          width,
          height,
        },
      )

      //* カメラからpointまで光線を伸ばす
      rayCaster.setFromCamera(coords, camera)

      if (!pointCloud) return undefined

      const material = pointCloud.material as PointsMaterial
      if (rayCaster.params.Points) rayCaster.params.Points.threshold = material.size

      const currentEditorTool = getCurrentEditorTool(selectedTool)

      // decide which objects to intersect with
      const anchorPlacementObjects = anchorPlacementObjectIds
        .map((id) => meshRefs && meshRefs[id].current)
        .filter(Boolean) as Object3D<Object3DEventMap>[]

      // only alow picking point when point cloud object is visible
      const usePCD = !isPointCloudInvisible && !anchorPlacementObjects.length

      // 'anchorPlacementObjects' is an exclusive setting. When it is set, only those objects will be intersected with.
      // Otherwise, the ray will intersect with the PCD if it is visible.
      // Optionally, tools can also specify that the ray should not intersect with any objects.
      const rayObjects = []
      if (anchorPlacementObjects.length) {
        rayObjects.push(...anchorPlacementObjects)
      } else {
        if (usePCD) {
          rayObjects.push(pointCloud)
        }

        if (!currentEditorTool?.config?.anchor?.notPlaceableOnObjects) {
          rayObjects.push(
            ...(Object.keys(meshRefs || {})
              .map((id) => (meshRefs && meshRefs[id].current ? meshRefs[id].current : null))
              .filter(Boolean) as Object3D<Object3DEventMap>[]),
          )
        }
      }

      //* 光線に衝突した点群を検出
      const intersects = rayCaster.intersectObjects(rayObjects)
      if (!intersects.length) {
        return undefined
      }

      let closest = intersects[0].distance
      const intersectedPoint = intersects.find(({ index, faceIndex, distance, point, object }) => {
        if ((index === undefined && faceIndex === undefined) || distance > closest) return false

        // If mask regions has been defined, filter out points that are not within them but only for PCD intersections.
        // Intersections with any other object is always allowed.
        // Note that 'maskModelMatrices' has already been filtered to only include visible masks.
        let result = true
        if (object instanceof Points && !isViewAllPoints && maskModelMatrices.length) {
          result = maskModelMatrices.some((mask) => {
            const localPoint = point.clone().applyMatrix4(mask.modelMatrix)
            return (
              localPoint.x >= mask.min.x &&
              localPoint.x <= mask.max.x &&
              localPoint.y >= mask.min.y &&
              localPoint.y <= mask.max.y &&
              localPoint.z >= mask.min.z &&
              localPoint.z <= mask.max.z
            )
          })
        }

        if (result) closest = distance
        return result
      })
      if (!intersectedPoint) {
        return undefined
      }

      return createSelectedPoint(pointCloud, intersectedPoint)
    },
    [
      cameraRef,
      isViewAllPoints,
      maskModelMatrices,
      pointCloud,
      selectedTool,
      anchorPlacementObjectIds,
      meshRefs,
      isPointCloudInvisible,
    ],
  )

  useEffect(() => {
    setMaskModelMatrices(maskRegions.filter((region) => !region.invisible).map(generateCuboidUniforms))
  }, [maskRegions])

  useEffect(() => {
    if (pcdMaterial && defaultPointSize === undefined) {
      setDefaultPointSize(pcdMaterial.size)
      changePointSize(pcdMaterial.size)
    }
  }, [changePointSize, defaultPointSize, pcdMaterial])

  useEffect(() => {
    if (!pcdMaterial || !pointCloud) {
      return
    }

    const material = pointCloud.material as PointsMaterial
    if (material?.size !== pointSize) {
      const newSize =
        pointSize === undefined
          ? defaultPointSize || EDITOR_POINT_SIZE_INTERVAL
          : Math.max(pointSize, EDITOR_POINT_SIZE_INTERVAL)
      material.polygonOffset = true
      material.polygonOffsetFactor = 1

      // Update both the saved and current material
      material.size = newSize
      pcdMaterial.size = newSize
    }

    if (pointSize === undefined && pointSize !== defaultPointSize) {
      changePointSize(Math.max(defaultPointSize, EDITOR_POINT_SIZE_INTERVAL))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointSize])

  useEffect(() => {
    if (movingPrefixedPosition) {
      arcballControlsRef.current?.reset()
      let target = pointCloud?.geometry.boundingSphere?.center || new Vector3()
      if (movingPrefixedPosition.startsWith('CUBE')) {
        target = editingCuboid?.center ? new Vector3(...editingCuboid.center) : new Vector3()
      }
      arcballControlsRef.current?.setTarget(target.x, target.y, target.z)
    }
  }, [editingCuboid, movingPrefixedPosition, pointCloud, arcballControlsRef])

  useEffect(() => {
    if (initCompleted && cameraRef.current) cameraRef.current.up = new Vector3(0, 0, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initCompleted, cameraRef.current])

  /**
   * Canvas touch start event handlers
   * @param e
   */
  const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    if (!inspection_area_id) return

    const anchorPoint = getSelectedPoint(undefined, e)

    // Execute events for tools
    toolsHook.forEach((tool) => tool.config?.events?.onTouchStart && tool.config.events.onTouchStart(e, anchorPoint))
  }

  /**
   * Canvas touch move event handlers
   * @param e
   */
  const onTouchMoveCapture = (e: TouchEvent<HTMLDivElement>) => {
    if (!inspection_area_id) return

    const anchorPoint = getSelectedPoint(undefined, e)

    // Execute events for tools
    toolsHook.forEach(
      (tool) => tool.config?.events?.onTouchMoveCapture && tool.config.events.onTouchMoveCapture(e, anchorPoint),
    )
  }

  /**
   * Canvas touch end event handlers
   * @param e
   */
  const onTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
    if (!inspection_area_id) return

    const isClickedNearPoint =
      lastTouchEvent !== undefined &&
      Math.abs(lastTouchEvent.changedTouches[0].clientX - e.changedTouches[0].clientX) <
        EDITOR_DOUBLE_CLICK_POS_THRESHOLD &&
      Math.abs(lastTouchEvent.changedTouches[0].clientY - e.changedTouches[0].clientY) <
        EDITOR_DOUBLE_CLICK_POS_THRESHOLD

    // re-using existing triple click logic to determine double/triple click
    const isDoubleClicked =
      tripleClickInitiatedTimesTouch.length === 1 &&
      Date.now() - tripleClickInitiatedTimesTouch[0].valueOf() < EDITOR_DOUBLE_CLICK_INTERVAL &&
      isClickedNearPoint
    const isTripleClicked =
      tripleClickInitiatedTimesTouch.length === 2 &&
      Math.abs(tripleClickInitiatedTimesTouch[1].valueOf() - Date.now()) < EDITOR_DOUBLE_CLICK_INTERVAL &&
      isClickedNearPoint

    if (isTripleClicked) {
      setTripleClickInitiatedTimesTouch([])
    } else if (
      tripleClickInitiatedTimesTouch.length === 1 &&
      Math.abs(tripleClickInitiatedTimesTouch[0].valueOf() - Date.now()) < EDITOR_DOUBLE_CLICK_INTERVAL
    ) {
      setTripleClickInitiatedTimesTouch([tripleClickInitiatedTimesTouch[0], new Date()])
    } else {
      setTripleClickInitiatedTimesTouch([new Date()])
    }

    // Execute events for tools
    toolsHook.forEach(
      (tool) =>
        tool.config?.events?.onTouchEnd && tool.config.events.onTouchEnd(e, { isDoubleClicked, isTripleClicked }),
    )

    changeIsDragging(false)
    setLastTouchEvent(e)
  }

  /**
   * Canvas mouse up event handlers
   * @param e
   */
  const onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const anchorPoint = getSelectedPoint(e)

    // If middle mouse button is clicked, change the target of the camera
    if (e.button === 1) {
      focusCamera(anchorPoint)
      return
    }

    if (e.button !== 0 || !inspection_area_id) return

    changeIsMouseDown(false)
    const isClickedNearPoint =
      lastMouseEvent !== undefined &&
      Math.abs(lastMouseEvent.clientX - e.clientX) < EDITOR_DOUBLE_CLICK_POS_THRESHOLD &&
      Math.abs(lastMouseEvent.clientY - e.clientY) < EDITOR_DOUBLE_CLICK_POS_THRESHOLD
    const isDoubleClicked =
      tripleClickInitiatedTimes.length === 1 &&
      Date.now() - tripleClickInitiatedTimes[0].valueOf() < EDITOR_DOUBLE_CLICK_INTERVAL &&
      isClickedNearPoint
    const isTripleClicked =
      tripleClickInitiatedTimes.length === 2 &&
      Math.abs(tripleClickInitiatedTimes[1].valueOf() - Date.now()) < EDITOR_DOUBLE_CLICK_INTERVAL &&
      isClickedNearPoint
    setLastMouseEvent(e)
    if (isTripleClicked) {
      setTripleClickInitiatedTimes([])
    } else if (
      tripleClickInitiatedTimes.length === 1 &&
      Math.abs(tripleClickInitiatedTimes[0].valueOf() - Date.now()) < EDITOR_DOUBLE_CLICK_INTERVAL
    ) {
      setTripleClickInitiatedTimes([tripleClickInitiatedTimes[0], new Date()])
    } else {
      setTripleClickInitiatedTimes([new Date()])
    }

    // Execute events for tools
    toolsHook.forEach(
      (tool) =>
        tool.config?.events?.onMouseUp &&
        tool.config.events.onMouseUp(e, anchorPoint, { isTripleClicked, isDoubleClicked }),
    )
    changeIsDragging(false)
    // If the user was grabbing something, onUp they will still be on whatever they grabbed.
    // So instead of resetting to default state, set it back to grab.
    if (cursor === CursorState.GRABBING) dispatch(setCursor(CursorState.GRAB))
    const currentPoint = {
      x: e?.clientX,
      y: e?.clientY,
    }
    const dragDistance = isMouseMoving
      ? (startDraggingPoint!.x - currentPoint.x) ** 2 + (startDraggingPoint!.y - currentPoint.y) ** 2
      : 0
    if (!isMouseMoving || dragDistance < EDITOR_MOUSE_DRAG_THRESHOLD) {
      setIsMouseMoving(false)
      if (selectedTool === EDITOR_TOOLS.MOVE) {
        changeSelectedPoint(hoveredPoint)
      } else if (selectedTool === EDITOR_TOOLS.FOCUS && cameraRef.current && arcballControlsRef.current) {
        focusCamera(anchorPoint)
        changeTool(prevSelectedTool)
      } else if (selectedTool === EDITOR_TOOLS.COMMENT && anchorPoint) {
        const newPosition = { x: anchorPoint[0], y: anchorPoint[1], z: anchorPoint[2] }
        // Update the editing comment's position
        if (editingCommentId && editingCommentId !== TEMP_COMMENT_ID) {
          dispatch(
            modifyComment({
              thread_id: editingCommentId,
              cartesian_position: newPosition,
            }),
          )
        }
        // Add new comment
        else if (!selectedComment) {
          const dummyComment = {
            thread_id: TEMP_COMMENT_ID,
            author_name: user?.nickname || '',
            thread_body: '',
            cartesian_position: newPosition,
            inspection_area_id,
          }
          dispatch(setEditingComment(dummyComment))
          dispatch(setSelectedComment(dummyComment))
          dispatch(setFormImages([]))
        }
      }
    }
  }

  /**
   * Canvas mouse move event handlers
   * @param mouseEvent
   * @param touchEvent
   */
  const onMoveMouse = (
    mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    touchEvent?: React.TouchEvent<HTMLDivElement>,
  ) => {
    const anchorPoint = getSelectedPoint(mouseEvent, touchEvent)

    // Execute events for tools
    toolsHook.forEach(
      (tool) => tool.config?.events?.onMove && tool.config.events.onMove(anchorPoint, mouseEvent, touchEvent),
    )
  }

  /**
   * Canvas mouse down event handlers
   * @param mouseEvent
   */
  const onMouseDown = (mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (mouseEvent?.button === 0) changeIsMouseDown(true)
    setIsMouseMoving(false)
    setStartDraggingPoint({
      x: mouseEvent?.clientX || 0,
      y: mouseEvent?.clientY || 0,
    })
    if (hoveredPoint) {
      changeIsDragging(true)
    }
    const anchorPoint = getSelectedPoint(mouseEvent)
    toolsHook.forEach(
      (tool) => tool.config?.events?.onMouseDown && tool.config.events.onMouseDown(mouseEvent, anchorPoint),
    )
  }

  /**
   * If area has already been set and inspection_area_id is different, reset store/states
   */
  useEffect(() => {
    if (initCompleted && inspection_area_id && inspectionArea?.inspection_area_id !== inspection_area_id) {
      setPcdMaterial(undefined)
      setPointCloud(undefined)
      setInitCompleted(false)
    }
  }, [initCompleted, inspection_area_id, inspectionArea])

  useEffect(() => {
    if (!userLoaded && !initCompleted) return

    void (async () => {
      if (
        inspection_area_id &&
        project_id &&
        inspectionArea?.inspection_area_id === inspection_area_id &&
        inspectionArea?.down_sampled_file?.name &&
        (!inspectionArea?.downsample_status?.status ||
          inspectionArea.downsample_status.status === InspectionAreaDownSampleStatus.SUCCEEDED) &&
        !pointCloud
      ) {
        if (Cache.get(inspection_area_id)) {
          //* すでにダウンロード済みの場合、キャッシュから点群表示
          const loadPoints = Cache.get(inspection_area_id) as Points
          setPointCloud(loadPoints)

          // Save material for later use
          const mat = loadPoints.material as PointsMaterial
          mat.transparent = true
          setPcdMaterial(mat.clone())

          setInitCompleted(true)
          return true
        }

        //* 点群データの読み込み用
        const token = await getAccessToken()
        if (!token) {
          setInitCompleted(true)
          return false
        }

        const url = await getSignedUrlForGetDownSampledFile(token, project_id, inspection_area_id, showErrorModal)
        if (!url) {
          setInitCompleted(true)
          return false
        }

        //* 点群データのダウンロード
        try {
          const loader = new PCDLoader()
          const loadPoints = await loader.loadAsync(url, (e) => {
            const loadingPercent = (e.loaded / e.total) * 100

            setPcdLoadingPercent((prev) => Math.max(loadingPercent, prev))
          })

          //* 点群データをキャッシュに保存（どこかのタイミングでキャッシュを消したい、ログアウトとか？）
          Cache.add(inspection_area_id, loadPoints)
          setPointCloud(loadPoints)

          // Save material for later use
          const mat = loadPoints.material as PointsMaterial
          mat.transparent = true
          setPcdMaterial(mat.clone())

          return setInitCompleted(true)
        } catch (err) {
          setInitCompleted(true)
          return processErrorHandler(err, ERROR_PROCESS.GET_DOWN_SAMPLED_FILE, showErrorModal)
        }
      }
      return {}
    })()
  }, [
    getAccessToken,
    initCompleted,
    inspectionArea,
    pointCloud,
    project_id,
    inspection_area_id,
    userLoaded,
    showErrorModal,
  ])

  useEffect(() => {
    if (!pointCloud || !pcdMaterial) {
      return
    }

    // Always start with material that has not have its shaders already modified.
    const newMaterial = pcdMaterial.clone()
    let uniformsData: {
      modelMatrix: Matrix4
      min: Vector3
      max: Vector3
    }[] = []

    // If there are no mask, we need to reset the PCD material.
    // On fresh load this would not be a problem, but if the last
    // region was deleted, the previous mask region will still be
    // applied so we need to reset it.
    if (maskRegions.length) {
      uniformsData = maskRegions.filter((region) => !region.invisible).map(generateCuboidUniforms)
    }

    // Add the cuboid we are creating/editing to the list of mask regions
    if (editingCuboid) {
      uniformsData.push(generateCuboidUniforms(editingCuboid))
    }

    if (uniformsData.length) {
      newMaterial.onBeforeCompile = (shader) => {
        // Pass the model matrix to the shader as uniforms
        const uniforms = {
          maskRegion: { value: uniformsData },
        }

        shader.uniforms = { ...(shader.uniforms || {}), ...uniforms }

        // Get the position of the fragment in world space by appending it to the beginning of the shader.
        // We still need the original shader for other parts of the rendering.
        shader.vertexShader = shader.vertexShader.replace(
          `void main() {`,
          `
            varying vec4 fragPosition;
        
            void main() {
                fragPosition = modelMatrix * vec4(position, 1.0);
            `,
        )

        // Add a check if the fragment is outside the masks and if so, set a lower opacity
        // or discard it altogether.
        // Replace the original diffuseColor to use the new opacity.
        // The original shader is retained as we still need it to render it as-is,
        // just with lower opacity or none as necessary.
        shader.fragmentShader = shader.fragmentShader
          .replace(
            `void main() {`,
            `
                struct Region {
                  mat4 modelMatrix;
                  vec3 min;
                  vec3 max;
                };
  
                uniform Region maskRegion[${uniformsData.length}];
                varying vec4 fragPosition;
  
                bool checkWithin(Region region)
                {
                  // we need fragment position localized to the mask region
                  vec4 localPos = region.modelMatrix * fragPosition;
              
                  // Check if the fragment is inside the cube in its local space
                  return all(greaterThanEqual(localPos.xyz, region.min)) && all(lessThanEqual(localPos.xyz, region.max));
                }
  
                void main() {
                  bool toDiscard = ${selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID ? 'false' : 'true'};
                  float defaultOpacity = opacity;
                  float updatedOpacity = ${selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID ? '0.2' : '0.0'};
  
                  for(int i=0; i<${uniformsData.length}; i++)
                  {
                    if (checkWithin(maskRegion[i]))
                    {
                      updatedOpacity = defaultOpacity;
                      toDiscard = false;
                    }
                  }

                  if (toDiscard) {
                    discard;
                    return;
                  }
              `,
          )
          .replace(
            'vec4 diffuseColor = vec4( diffuse, opacity );',
            'vec4 diffuseColor = vec4( diffuse, updatedOpacity );',
          )
      }
    }

    // Shaders are compiled but when mask regions change, we need to recompile them.
    // Use IDs of the mask regions as a cache key.
    // If we are editing a cuboid, add that as well. Only need to do it once. If the cuboid changes,
    // uniforms will continue to be updated without needing to recompile the shader.
    if (pcdTransparency !== undefined) newMaterial.opacity = pcdTransparency
    newMaterial.needsUpdate = true
    newMaterial.customProgramCacheKey = () =>
      `${maskRegions
        // limit to only regions where uniforms are available.
        // sometime the mesh is not yet rendered and the uniforms can't be calculated.
        .slice(0, uniformsData.length - 1)
        .map((region) => region.region_id)
        .concat([
          `${uniformsData.length}`,
          editingCuboid ? 'editing-cuboid' : '',
          selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID ? 'pcd-trim' : '',
        ])
        .join(',')}`

    pointCloud.material = newMaterial
  }, [selectedTool, maskRegions, pointCloud, pcdMaterial, editingCuboid, pcdTransparency])

  //* canvas内でcontextを使用するための設定
  const ContextBridge = useContextBridge(UserContext, EditorContext, GlobalModalContext)

  const canvasClassName =
    (selectedTool === EDITOR_TOOLS.CYLINDER_CUBOID ||
      selectedTool === EDITOR_TOOLS.TORUS_CUBOID ||
      Tools.find((tool) => selectedTool === tool.key)?.config?.additionalToolbars?.cuboidControls) &&
    cuboidAnchor?.points.length === EDITOR_REQUIRED_ANCHORS.cuboid
      ? `editor-main-canvas ${EDITOR_TOOL_CURSOR_CLASSES[EDITOR_TOOLS.MOVE] || ''}`
      : `editor-main-canvas ${EDITOR_TOOL_CURSOR_CLASSES[selectedTool] || ''}`

  if (!initCompleted || !editorInitCompleted || isPageLoading) {
    return <LoadingPage text={pcdLoadingPercent ? `Download...${pcdLoadingPercent.toFixed(0)}%` : `読み込み中...`} />
  }

  return (
    <Box
      h="100%"
      onMouseDown={onMouseDown}
      onMouseMove={onMoveMouse}
      onTouchMove={(e) => onMoveMouse(undefined, e)}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMoveCapture={onTouchMoveCapture}
      userSelect="none"
      tabIndex={-1}
    >
      <Canvas
        className={`${canvasClassName} tooling-${cursor}`}
        style={{ width: '100svw', height: '100%', zIndex: 0 }}
        onCreated={(state: RootState) => {
          // eslint-disable-next-line no-param-reassign
          state.gl.localClippingEnabled = false
          // eslint-disable-next-line no-param-reassign
          state.gl.toneMapping = NoToneMapping
          // eslint-disable-next-line no-param-reassign
          state.gl.outputColorSpace = LinearSRGBColorSpace
        }}
      >
        <ContextBridge>
          <Provider store={store}>
            {/* 点群データ */}
            {pointCloud && !isPointCloudInvisible && (
              <group>
                <primitive object={pointCloud} />
              </group>
            )}

            {/* 原点補助軸 */}
            <primitive
              object={new AxesHelper(0.2)}
              position={[
                (pointCloud?.geometry?.boundingSphere?.center.x || 0) -
                  (pointCloud?.geometry?.boundingSphere?.radius || 1),
                (pointCloud?.geometry?.boundingSphere?.center.y || 0) +
                  (pointCloud?.geometry?.boundingSphere?.radius || 1),
                (pointCloud?.geometry?.boundingSphere?.center.z || 0) -
                  (pointCloud?.geometry?.boundingSphere?.radius || 1),
              ]}
            />

            {/* Cylinders */}
            {shapes?.cylinders.map((shape, index) => (
              <CylinderMesh
                key={shape.shape_id}
                cameraRef={cameraRef}
                cylinder={shape}
                invisible={shape.invisible || shape.deleted}
                labelPrefix={`鉄筋${zeroPad(index + 1, zeroPlaces)}`}
                arcballControls={arcballControlsRef.current}
              />
            ))}

            {/* Depth estimation distances */}
            {!depthEstimationTypes[0].invisible && depthEstimationTypes[0].distanceAnchors && (
              <AnchorFrames
                anchors={depthEstimationTypes[0].distanceAnchors}
                arcballControls={arcballControlsRef.current}
                shapeKey={EDITOR_MEASURE_KEYS.DETECTED_PLANE_TO_CYLINDER_DISTANCE}
              />
            )}
            {!depthEstimationTypes[1].invisible && depthEstimationTypes[1].distanceAnchors && (
              <AnchorFrames
                anchors={depthEstimationTypes[1].distanceAnchors}
                arcballControls={arcballControlsRef.current}
                shapeKey={EDITOR_MEASURE_KEYS.DETECTED_CYLINDER_TO_PLANE_DISTANCE}
              />
            )}
            {!depthEstimationTypes[2].invisible && depthEstimationTypes[2].distanceAnchors && (
              <AnchorFrames
                anchors={depthEstimationTypes[2].distanceAnchors}
                arcballControls={arcballControlsRef.current}
                shapeKey={EDITOR_MEASURE_KEYS.DETECTED_PLANE_TO_CYLINDER_DISTANCE}
              />
            )}
            {!depthEstimationTypes[3].invisible && depthEstimationTypes[3].distanceAnchors && (
              <AnchorFrames
                anchors={depthEstimationTypes[3].distanceAnchors}
                arcballControls={arcballControlsRef.current}
                shapeKey={EDITOR_MEASURE_KEYS.DETECTED_CYLINDER_TO_PLANE_DISTANCE}
              />
            )}

            {/* Working cuboid */}
            {(!cuboidAnchor || cuboidAnchor.points.length < EDITOR_REQUIRED_ANCHORS.cuboid) &&
              Tools.find((tool) => selectedTool === tool.key)?.config?.additionalToolbars?.cuboidControls && (
                <CuboidAnchorFrames
                  anchor={cuboidAnchor}
                  shapeKey={EDITOR_TOOL_KEYS[selectedTool]}
                  baseDiameter={baseDiameter || 0}
                />
              )}

            {/* Editor tools objects */}
            {toolsObjects.map((tool) => (
              <Fragment key={`tool-objects-${tool.key}`}>
                {tool.objects?.distanceLabels?.map(({ id, ...props }) => <DistanceLabel key={id} id={id} {...props} />)}
                {tool.objects?.warningLabels?.map((warningLabel) => <WarningLabel {...warningLabel} />)}
                {tool.objects?.circleAnchors?.map(({ id, ...props }) => <CircleAnchor key={id} id={id} {...props} />)}
                {tool.objects?.polygonPlaneMesh?.map((props) => (
                  <PolygonPlaneMesh key={`polygon-object-${props.polygon.shape_id}`} {...props} />
                ))}
                {tool.objects?.polygonPlaneMeshTransformable?.map((props) => (
                  <PolygonPlaneMeshTransformable key={`polygon-object-${props.polygon.shape_id}`} {...props} />
                ))}
              </Fragment>
            ))}

            <CuboidFrame
              cuboidAnchor={cuboidAnchor}
              baseDiameter={baseDiameter || 0}
              maxSize={(pointCloud?.geometry.boundingSphere?.radius || 1) * 2}
            />

            {/* Prefixed positions */}
            <CameraAnimator
              distance={pointCloud?.geometry?.boundingSphere?.radius || 1}
              center={pointCloud?.geometry.boundingSphere?.center}
              position={movingPrefixedPosition}
              finish={() => setMovingPrefixedPosition('')}
            />

            {pointCloud && (
              <>
                {/* Comments */}
                <Comments />
                {/* Camera */}
                <PerspectiveCamera
                  makeDefault
                  ref={cameraRef}
                  fov={90}
                  aspect={windowSize.width / windowSize.height}
                  near={0.01}
                  far={10000}
                  position={[
                    pointCloud?.geometry?.boundingSphere?.center.x || 0,
                    (pointCloud?.geometry?.boundingSphere?.center.y || 0) -
                      (pointCloud?.geometry?.boundingSphere?.radius || 1),
                    pointCloud?.geometry?.boundingSphere?.center.z || 0,
                  ]}
                />
                {/* Controls */}
                <ArcballControls
                  ref={arcballControlsRef}
                  makeDefault
                  target={savedCameraTarget || pointCloud?.geometry.boundingSphere?.center || [0, 0, 0]}
                  enabled={!isDragging}
                  enableAnimations={false}
                  enablePan={!disablePanning}
                />
              </>
            )}

            {selectionBoundaryBoxVectors &&
              selectionBoundaryBoxVectors.map((vectors) => (
                <BoxBoundaryMesh key={uniqueId('boxboundary')} points={vectors} />
              ))}
            <color attach="background" args={[backgroundColor]} />
            <ambientLight intensity={Math.PI} />
          </Provider>
        </ContextBridge>
      </Canvas>
    </Box>
  )
}

export default MainCanvas
