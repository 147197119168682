import './styles.css'

import { FC } from 'react'

import {
  Button,
  Flex,
  Grid,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import LoadingPage from 'components/LoadingPage'
import PageErrorHandler from 'components/PageErrorHandler'
import { debounce } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { BiCaretDown } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { SettingIcon } from 'assets/icons'

import { Project } from 'interfaces/project'

import Navbar from '../common/Navbar'
import DataSaver from './components/DataSaver'
import InspectionAreaRow from './components/InspectionAreaRow'
import PopoverEditing from './components/PopoverEditing'
import SettingsDrawer from './components/SettingsDrawer'
import TableHeader from './components/TableHeader'
import XLSXExportButton from './components/XLSXExportButton'
import useInspectionSheet from './hooks/useInspectionSheet'
import { patchEditedProject } from './store'

const debouncedPatchEditedProject = debounce((dispatch: ReturnType<typeof useAppDispatch>, data: Partial<Project>) => {
  dispatch(patchEditedProject(data))
}, 200)

const InspectionSheet: FC = () => {
  const {
    handleGenerateXLSX,
    isLoading,
    shapes,
    isAllowedToModifyProject,
    isAllowedToModifySheet,
    allAdditionalMetricsToggle,
    sortedInspectionItems,
    rebarsSurfaceArea,
    totalVolume,
    totalArea,
    settingsDrawerRef,
  } = useInspectionSheet()

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const editedProject = useSelector((state: RootState) => state.inspectionSheet.editedProject)
  const isSaving = useSelector((state: RootState) => state.inspectionSheet.isSaving)
  const isSavingSettings = useSelector((state: RootState) => state.inspectionSheet.isSavingSettings)

  return (
    <PageErrorHandler>
      <DataSaver />

      <VStack id="inspection-sheet" className={[isAllowedToModifySheet ? 'editable' : ''].join(' ')}>
        <HStack zIndex={160} position="relative" w="100%">
          <Navbar />
        </HStack>

        {isLoading ? (
          <LoadingPage text="読み込み中…" />
        ) : (
          <Flex id="inspection-sheet-container">
            {/* Action buttons */}
            <HStack bg="#E5F3FF" w="100%" justifyContent="flex-end" p={4} id="inspection-sheet-action-bar">
              <Menu>
                <MenuButton as={Button} colorScheme="primary" fontWeight="bold" size="lg" rightIcon={<BiCaretDown />}>
                  ダウンロード
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      mixpanel.track('Export inspection sheet', { 'File format': 'PDF' })
                      window.print()
                    }}
                  >
                    印刷 (PDF)
                  </MenuItem>
                  <XLSXExportButton handleGenerateXLSX={handleGenerateXLSX} />
                </MenuList>
              </Menu>
              {isAllowedToModifyProject && (
                <Button
                  variant="darkGray"
                  rightIcon={<SettingIcon />}
                  fontWeight="bold"
                  size="lg"
                  onClick={() => settingsDrawerRef.current?.openDrawer()}
                >
                  設定
                </Button>
              )}
            </HStack>

            <Grid className="scrollable-container">
              {/* Profile group */}
              <HStack borderTop="1px solid var(--chakra-colors-gray-300)" w="100%" p={8} pl={24} gap={6}>
                {/* page title */}
                <Flex color="primary.700" alignItems="end" flexDirection="column">
                  <Text fontSize="4xl" fontWeight="bold" whiteSpace="nowrap">
                    検査帳票
                  </Text>
                  <Text fontSize="xl">出来形表</Text>
                </Flex>

                {/* construction properties */}
                <Flex bg="gray.100" py={4} px={8} borderRadius={6} flexDirection="column">
                  <HStack>
                    <Text width="70px" color="primary.700">
                      工事名
                    </Text>
                    {isAllowedToModifySheet && project ? (
                      <PopoverEditing
                        allowEmpty={false}
                        value={editedProject?.project_name || project.project_name}
                        onChange={(value) => {
                          debouncedPatchEditedProject(dispatch, { project_id: project.project_id, project_name: value })
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </HStack>
                </Flex>

                {/* Summary */}
                <Flex py={4} flexDirection="column">
                  <HStack>
                    <Text width="150px" color="primary.700">
                      体積の合計
                    </Text>
                    <Text>{totalVolume ? `${totalVolume}m³` : '-'}</Text>
                  </HStack>
                  <HStack>
                    <Text width="150px" color="primary.700">
                      面積の合計
                    </Text>
                    <Text>{totalArea ? `${totalArea}m²` : '-'}</Text>
                  </HStack>
                  <HStack>
                    <Text width="150px" color="primary.700">
                      鉄筋の表面積の合計
                    </Text>
                    <Text>{rebarsSurfaceArea ? `${rebarsSurfaceArea}m²` : '-'}</Text>
                  </HStack>
                </Flex>
              </HStack>

              <TableHeader
                isSaving={isSaving || isSavingSettings}
                allAdditionalMetricsToggle={allAdditionalMetricsToggle}
              />

              {/* Table */}
              {sortedInspectionItems.map(({ area: ar, items: its }) => (
                <InspectionAreaRow
                  key={ar.inspection_area_id}
                  inspectionArea={ar}
                  inspectionItems={its}
                  shapes={shapes}
                  allAdditionalMetricsToggle={allAdditionalMetricsToggle}
                />
              ))}

              <Spacer />
            </Grid>
          </Flex>
        )}
      </VStack>
      {isAllowedToModifyProject && <SettingsDrawer ref={settingsDrawerRef} />}
    </PageErrorHandler>
  )
}

export default InspectionSheet
