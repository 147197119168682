import { useCallback, useContext, useEffect, useMemo } from 'react'

import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { CursorState, setCursor, setDisablePanning } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { patchInspectionAreas } from 'store/page'
// import { setInspectionArea } from 'store/page'
import { Vector3 } from 'three'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_TOOLS } from 'config/constants'

import { Editor } from 'interfaces/canvas'
import { EditorConfig } from 'interfaces/editor'

import { getArcballControlsCameraState } from 'services/Editor'
import { patchInspectionArea } from 'services/InspectionArea'

import { SelectionStage, reset, resetWorkingState, setCameraProfile, setSelectionStage } from '../store'

const attentionMessages = {
  [SelectionStage.FOCUS]: '点群をクリックして焦点を選んでください',
  [SelectionStage.ROTATE]:
    '保存したい視点に回転してください。このステップでは視点の平行移動は無効になっています。\n点群をクリックすることで焦点を変更できます。終了したら右下の「保存」をクリックしてください。',
  [SelectionStage.SAVING]: '正面視野を保存中...',
}

export default function useEditor({
  initCompleted,
  selectedTool,
  prevSelectedTool,
  arcballControlsRef,
  cameraRef,
  changeTool,
  changeIsToolProcessing,
}: Editor): EditorConfig {
  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const selectionStage = useSelector((state: RootState) => state.toolCameraProfile.selectionStage)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const target = useSelector((state: RootState) => state.toolCameraProfile.target)

  // Vars
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.CAMERA_PROFILE, [selectedTool])
  const isPreviousToolSelected = useMemo(() => prevSelectedTool === EDITOR_TOOLS.CAMERA_PROFILE, [prevSelectedTool])

  /**
   * Generate camera profile.
   */
  const getCameraProfile = useCallback(() => {
    if (!cameraRef.current || !arcballControlsRef.current || !target) return null

    const targetV3 = new Vector3(...target)
    const direction = new Vector3().subVectors(cameraRef.current.position, targetV3).normalize()
    const right = new Vector3().crossVectors(cameraRef.current.up, direction).normalize()
    const up = new Vector3().crossVectors(direction, right).normalize()
    const distance = cameraRef.current.position.distanceTo(targetV3)

    return {
      state: getArcballControlsCameraState(arcballControlsRef.current),
      target: targetV3.toArray(),
      positions: {
        front: cameraRef.current.position.toArray(),
        back: new Vector3().copy(direction).negate().multiplyScalar(distance).add(targetV3).toArray(),
        top: new Vector3().copy(up).multiplyScalar(distance).add(targetV3).toArray(),
        bottom: new Vector3().copy(up).negate().multiplyScalar(distance).add(targetV3).toArray(),
        left: new Vector3().copy(right).negate().multiplyScalar(distance).add(targetV3).toArray(),
        right: new Vector3().copy(right).multiplyScalar(distance).add(targetV3).toArray(),
      },
    }
  }, [arcballControlsRef, cameraRef, target])

  /**
   * Load camera profile.
   */
  useEffect(
    () => {
      if (!arcballControlsRef.current || !initCompleted || !inspectionArea?.camera_profile) return

      // load from inspection area
      dispatch(setCameraProfile(inspectionArea.camera_profile))

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
      ;(arcballControlsRef.current as any).setStateFromJSON(JSON.stringify(inspectionArea.camera_profile.state))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, initCompleted, inspectionArea, arcballControlsRef.current],
  )

  /**
   * Save camera profile.
   */
  const onClick = useCallback(async () => {
    const profile = getCameraProfile()
    if (!project || !inspectionArea || !profile) return

    const accessToken = await getAccessToken()
    if (!accessToken) return

    dispatch(setSelectionStage(SelectionStage.SAVING))
    changeIsToolProcessing(true)

    const result = await patchInspectionArea(
      accessToken,
      project.project_id,
      {
        ...inspectionArea,
        camera_profile: profile,
      },
      showErrorModal,
    )

    // Change to move tool once saved
    if (result) {
      changeTool(EDITOR_TOOLS.MOVE)
      changeIsToolProcessing(false)
      dispatch(patchInspectionAreas([result]))
    } else {
      dispatch(setSelectionStage(SelectionStage.FOCUS))
    }
  }, [
    project,
    inspectionArea,
    changeIsToolProcessing,
    getAccessToken,
    showErrorModal,
    changeTool,
    getCameraProfile,
    dispatch,
  ])

  /**
   * Set attention text and cursor when tool is active, according to the selection stage.
   */
  useEffect(() => {
    if (isToolSelected) {
      if (attentionMessages[selectionStage]) {
        dispatch(setAttentionText({ message: attentionMessages[selectionStage] }))
      }

      dispatch(setCursor(CursorState.CROSSHAIR))
      dispatch(setDisablePanning(true))
    } else if (isPreviousToolSelected) {
      dispatch(setCursor(CursorState.DEFAULT))
      dispatch(setAttentionText({ message: '' }))
      dispatch(setDisablePanning(false))
    }
  }, [isPreviousToolSelected, isToolSelected, selectedTool, selectionStage, dispatch])

  /**
   * Reset working state when tool is deselected.
   */
  useEffect(() => {
    if (!isToolSelected && isPreviousToolSelected) {
      dispatch(resetWorkingState())
    }
  }, [isToolSelected, isPreviousToolSelected, dispatch])

  /**
   * Reset on unmount.
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  return {
    buttons: {
      submit: {
        key: 'save-camera-profile',
        label: '保存',
        loadingLabel: '保存中',
        onClick,
        isShown: useCallback(() => isToolSelected, [isToolSelected]),
        isLoading: useCallback(() => selectionStage === SelectionStage.SAVING, [selectionStage]),
        isDisabled: useCallback(
          () => [SelectionStage.SAVING, SelectionStage.FOCUS].includes(selectionStage),
          [selectionStage],
        ),
      },
    },
  }
}
