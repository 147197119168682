import { ReactElement } from 'react'

import Decimal from 'decimal.js'
import { IntervalsConfig } from 'pages/projects/editor/tools/Grid/store'

import { DIAMETERS, EDITOR_TOOLS, MILLIMETER_SCALE } from 'config/constants'
import { PLANE_BORDER_THICKNESS, PLANE_OPACITY, PLANE_SIDE_COLOR } from 'config/styles'

import { EditorTool } from 'interfaces/editor'
import {
  Anchors,
  CuboidAnchor,
  Cylinder,
  InspectionItem,
  InspectionItemNumberValues,
  LayerStatus,
  LineStyle,
  PointArray,
  Polygon,
  Shape,
  ShapeKey,
  ShapeKeyType,
  Shapes,
  ShapesId,
} from 'interfaces/interfaces'

/**
 * ファイル拡張子からContentTypeを返す
 * @param {string} fileName ファイル名
 * @return {string | null} 拡張子もしくはnull
 */
export const returnContentType = (fileName: string): string | null => {
  const extension: string | undefined = fileName.split('.').pop()
  const allowedExtensions = new Set<string | undefined>(['las', 'ply', 'pts', 'xyz', 'xyzrgb'])
  if (allowedExtensions.has(extension)) return 'text/plain'
  return null
}

/**
 * 任意の桁で四捨五入する関数
 * @param {number} num 四捨五入する数値
 * @param {string} decimalBase numをdecimalBaseで整数化したときに、どの桁まで残すか。decimalBaseの1つ下の位で四捨五入する
 * @return {number} 四捨五入した値
 */
export const roundNumber = (
  num: number,
  decimalBase: '0.0000001' | '0.000001' | '0.00001' | '0.0001' | '0.001' | '0.01' | '0.1' | '1',
) => {
  //* decimalBaseの0の数をカウント
  const zeroMatch = decimalBase.match(/0/g)
  const zeroCount = zeroMatch ? zeroMatch.length : 0
  //* 丸め誤差対策のため、decimalBaseを整数値に変換
  const intDecimalBase = 10 ** zeroCount
  return Math.round(num * intDecimalBase) / intDecimalBase
}

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

//* ミリメートルからメートルへ変換（丸め誤差対策）
export const millimeterToMeter = (value: number) => new Decimal(value).div(MILLIMETER_SCALE).toNumber()

//* メートルからミリメートルへ変換（丸め誤差対策）
export const meterToMillimeter = (value: number) => new Decimal(value).mul(MILLIMETER_SCALE).toNumber()

export const meterToMilimeterWhole = (v: number): number => Math.round(meterToMillimeter(v))

export const meterToMilimeterRounded = (v: number): number => roundNumber(meterToMillimeter(v), '0.01')

export const meterRounded = (v: number): number => roundNumber(v, '0.0001')

/**
 * Round distance to milimeters
 * @param distance in meters
 * @returns distance in milimeters
 */
export const roundDistance = (distance: number): number => roundNumber(meterToMillimeter(distance), '0.0001')

/*
 * Round volume. Precision of volume from tekkin-detector is 5 decimal places.
 * @param volume in meters
 * @returns roundedvolume
 */
export const roundVolume = (volume: number): number => roundNumber(volume, '0.00001')

/**
 * Check if a value is defined when a number field can be null or undefined and 0 is a valid value.
 *
 * @param value
 */
export const isValueDefined = (value: number | null | undefined) => value !== undefined && value !== null

/**
 * Get the distance label with unit
 * @param distance in meters
 * @returns label with unit
 */
export const getDistanceLabel = (distance: number): string => {
  const roundedDistance = roundNumber(distance, '0.001')
  const distanceInMillimeter = meterToMillimeter(roundedDistance)

  if (distanceInMillimeter < 10000) {
    return `${distanceInMillimeter}mm`
  }

  return `${roundedDistance}m`
}

//* Used for Diameter dropdown
export const findDiameterKeyByValue = (diameterValue: number | undefined) =>
  Object.keys(DIAMETERS).find((key) => DIAMETERS[key] === diameterValue) || (diameterValue || '').toString()

export const shapesExist = (shapes: Shapes): boolean => !!shapes.cylinders.length || !!shapes.polygons.length

export const validateEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
  return emailRegex.test(email)
}

//* Used for checking clearing guidelines before switching tool
export const needClearAnchorFrames = (
  newTool: string,
  cuboidAnchor: CuboidAnchor | undefined,
  intervals: Record<string, IntervalsConfig>,
  toolVolumePolygonIsDirty: boolean,
  toolRebarDetectionIsDirty: boolean,
  toolPlaneDetectionIsDirty: boolean,
) => {
  // no need to clear guidelines if user switches to the tools that not modify the points
  if (newTool === EDITOR_TOOLS.DISTANCE || newTool === EDITOR_TOOLS.COMMENT) {
    return false
  }

  if (newTool === EDITOR_TOOLS.MOVE || newTool === EDITOR_TOOLS.FOCUS) {
    // clear guidelines if user switches to move tool but not for editing the points
    // editing points on guidelines is not allowed on CUBOID tools
    if (cuboidAnchor) {
      return true
    }

    if (Object.keys(intervals).length > 0) {
      return true
    }

    // not clear guidelines if user switches to move tool for editing the points
    // editing points on guidelines is allowed on CYLINDER, TORUS and PLANE tools
    return false
  }

  // TODO: Need to figure out how to do this modularized way.

  // clear guidelines if user switches to another tool but not re-use the same guidelines
  if (newTool !== EDITOR_TOOLS.CYLINDER && toolRebarDetectionIsDirty) {
    return true
  }

  if (newTool !== EDITOR_TOOLS.PLANE && toolPlaneDetectionIsDirty) {
    return true
  }

  // clear working grid when moving away from it
  if (newTool !== EDITOR_TOOLS.GRID && Object.keys(intervals).length > 0) {
    return true
  }

  // Clear working polygon points when moving away from volume polygon tool
  if (newTool !== EDITOR_TOOLS.VOLUME_POLYGON && toolVolumePolygonIsDirty) {
    return true
  }

  return false
}

/**
 * Filters and returns non-deleted layers only.
 * @param {LayerStatus[]} layers
 */
export const getNonDeletedLayers = (layers: LayerStatus[]): LayerStatus[] =>
  layers.filter((layer: LayerStatus) => !layer.deleted)

export const shapesWithKeyShouldShow = (shapes: Shapes | Anchors, key: ShapeKey): boolean =>
  !!shapes[key].length && !!getNonDeletedLayers(shapes[key]).length

/**
 * Calculates the difference value and checks if it passes the pre-defined thresholds for an inspection item.
 * @param inspectionItemValue The inspection item value to compare.
 * @param inspectionItem The inspection item to compare.
 * @param unit The unit of measurement for the difference value.
 * @returns An object containing the difference value and a boolean indicating if it passes the pre-defined thresholds.
 */
export const getDifferenceValueAndIsPassingThresholds = (
  inspectionItemValue: InspectionItemNumberValues | undefined,
  inspectionItem: InspectionItem | undefined,
  unit: string | ReactElement,
  rounding: '0.0000001' | '0.000001' | '0.00001' | '0.0001' | '0.001' | '0.01' | '0.1' | '1' = '0.0001',
) => {
  let differenceValue: number | undefined
  let isPassingThresholds: boolean | undefined
  if (
    typeof inspectionItemValue?.estimated_value === 'number' &&
    typeof inspectionItem?.pre_defined_thresholds?.designed_value === 'number'
  ) {
    differenceValue = inspectionItemValue.estimated_value - inspectionItem.pre_defined_thresholds.designed_value
    differenceValue = unit === 'mm' ? roundNumber(differenceValue, rounding) : roundVolume(differenceValue)
    if (typeof inspectionItem.pre_defined_thresholds.tolerance === 'number')
      isPassingThresholds = Math.abs(differenceValue) <= inspectionItem.pre_defined_thresholds.tolerance
  }
  return { differenceValue, isPassingThresholds }
}

/**
 * Convert array of shape IDs to ShapesId instance.
 * @param shapes Shapes reference.
 * @param shapeIds Shape IDs to be converted.
 * @returns
 */
export const shapeIdArrayToShapeIds = (shapes: Shapes, shapeIds: string[]): ShapesId => ({
  cylinders: shapes.cylinders.filter((c) => shapeIds.includes(c.shape_id)).map((c) => c.shape_id),
  polygons: shapes.polygons.filter((t) => shapeIds.includes(t.shape_id)).map((t) => t.shape_id),
})

/**
 * Check if a shape can be selected
 * @param shape Shape to be selected
 * @throws Error if shape cannot be selected
 */
export const validateSelectedShape = (
  shape: Shape,
  shapeKey: ShapeKey,
  inspectionItems: InspectionItem[],
  currentTool?: EditorTool,
) => {
  if (currentTool?.config?.volume?.selectable && shapeKey === ShapeKeyType.POLYGON) {
    if (!inspectionItems.some((item) => item.shape_ids.polygons.includes(shape.shape_id))) {
      throw new Error('体積測定に失敗した平面は選択できません。')
    }
  }
}

/**
 * Remove hash from browser URL.
 * Only to be used where react-router context is not available.
 */
export const removeHashFromBrowserURL = () => {
  window.history.replaceState('', document.title, window.location.pathname + window.location.search)
}

/**
 * Calculates the surface area of a rebar.
 * @param cylinder Cylinder
 * @returns The surface area of the rebar.
 */
export const calculateRebarSurfaceArea = (cylinder: Cylinder) => {
  const { diameter, length } = cylinder
  // 2πr^2 + 2πrh
  const radius = diameter / 2
  const surfaceArea = 2 * Math.PI * radius * radius + 2 * Math.PI * radius * length
  return surfaceArea
}

/**
 * Returns num evenly spaced samples, calculated over the interval [start, stop].
 *
 * @param start Start value
 * @param end End value
 * @param n Number of samples
 */
export const linspace = (start: number, end: number, n: number) => {
  if (n === 1) return [start]

  const step = (end - start) / (n - 1)
  return Array.from({ length: n }, (_, i) => start + step * i)
}

/**
 * Generate distance labels for working grids.
 * The point coordinates will be localized to the plane.
 *
 * @param interval Intervals config containing the max and interval values.
 */
export const generateGridPoints = (interval: Omit<IntervalsConfig, 'topPlaneId' | 'bottomPlaneId'>) => {
  const aLength = interval.longAxis.max * 2
  const aAxisCount = Math.ceil((aLength - interval.longAxis.value) / interval.longAxis.value)
  const aStart =
    (aLength - interval.longAxis.value) / 2.0 -
    (aAxisCount / 2 - 1) * interval.longAxis.value -
    interval.longAxis.offset
  const aEnd =
    (aLength + interval.longAxis.value) / 2.0 +
    (aAxisCount / 2 - 1) * interval.longAxis.value -
    interval.longAxis.offset
  const aPoints = linspace(aStart, aEnd, aAxisCount)

  const bLength = interval.shortAxis.max * 2
  const bAxisCount = Math.ceil((bLength - interval.shortAxis.value) / interval.shortAxis.value)
  const bStart =
    (bLength - interval.shortAxis.value) / 2.0 -
    (bAxisCount / 2 - 1) * interval.shortAxis.value -
    interval.shortAxis.offset
  const bEnd =
    (bLength + interval.shortAxis.value) / 2.0 +
    (bAxisCount / 2 - 1) * interval.shortAxis.value -
    interval.shortAxis.offset
  const bPoints = linspace(bStart, bEnd, bAxisCount)

  return bPoints.reduce<PointArray[]>(
    (acc, b) => acc.concat(aPoints.map((a) => (interval.whichLongAxis === 1 ? [a, b, 0] : [b, a, 0]))),
    [],
  )
}

/**
 * Check if a shape is hovered or selected.
 *
 * @param shape Shape to be checked
 * @param hoveredShapeId Currently hovered shape ID
 * @param selectedShapeIds Currently selected shape IDs
 */
export const isHoverSelected = (shapeId: string, hoveredShapeId: string, selectedShapeIds: string[]) =>
  hoveredShapeId === shapeId || selectedShapeIds.includes(shapeId)

/**
 * Get the opacity of a plane based on its state.
 *
 * @param shape Plane or Polygon.
 * @param dimmedShapeIds Dimmed shape IDs.
 * @param hoveredShapeId Hovered shape ID.
 * @returns Opacity value
 */
export const getPlaneOpacity = (
  shape: Polygon,
  dimmedShapeIds: string[],
  hoveredShapeId: string,
  opacities: Record<keyof typeof PLANE_OPACITY, number> = PLANE_OPACITY,
): number => {
  // if (shape.selected) return PLANE_OPACITY.SELECTED
  // if (shape.dimmed) return PLANE_OPACITY.DIMMED
  if (dimmedShapeIds.includes(shape.shape_id)) return opacities.DIMMED
  if (shape.shape_id === hoveredShapeId) return opacities.HOVER
  return opacities.STANDARD
}

/**
 * Get the color of a plane based on its state.
 *
 * @param shape Plane or Polygon.
 * @param selectedShapeIds Selected shape IDs.
 * @returns Color value
 */
export const getPlaneColor = (shape: Polygon, selectedShapeIds: string[]) => {
  if (selectedShapeIds.includes(shape.shape_id)) return 'yellow'
  return shape.plane_side ? PLANE_SIDE_COLOR[shape.plane_side] : PLANE_SIDE_COLOR.default
}

/**
 * Get the border thickness of a plane based on its state.
 *
 * @param shape Plane or Polygon.
 * @param selectedShapeIds Selected shape IDs.
 */
export const getPlaneBorderTickness = (polygon: Polygon, selectedShapeIds: string[]) => {
  if (selectedShapeIds.includes(polygon.shape_id)) return PLANE_BORDER_THICKNESS.SELECTED
  return PLANE_BORDER_THICKNESS.STANDARD
}

/**
 * Get the styles of a plane based on its state.
 * @param polygon Plane or Polygon.
 * @param selectedShapeIds Selected shape IDs.
 * @param dimmedShapeIds Dimmed shape IDs.
 * @param hoveredShapeId Hovered shape ID.
 * @param guideColor Guide color.
 * @param opacities Opacity values.
 * @returns Plane styles
 */
export const getPlaneStyles = (
  polygon: Polygon,
  selectedShapeIds: string[],
  dimmedShapeIds: string[],
  hoveredShapeId: string,
  guideColor: string,
  opacities: Record<keyof typeof PLANE_OPACITY, number> = PLANE_OPACITY,
) => ({
  guideStyle: selectedShapeIds.includes(polygon.shape_id) ? LineStyle.Solid : LineStyle.Dashed,
  guideColor: selectedShapeIds.includes(polygon.shape_id) || hoveredShapeId === polygon.shape_id ? 'white' : guideColor,
  guideThickness: getPlaneBorderTickness(polygon, selectedShapeIds),
  opacity: getPlaneOpacity(polygon, dimmedShapeIds, hoveredShapeId, opacities),
  labelBgColor: 'yellow',
  labelTextColor: '#333',
})

/**
 * Returns a hash code from a string
 * @param  str The string to hash.
 * @return     A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export const hashCode = (str: string): number => {
  let hash = 0
  for (let i = 0, len = str.length; i < len; i += 1) {
    const chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr // eslint-disable-line no-bitwise
    hash |= 0 // eslint-disable-line no-bitwise
  }
  return hash
}

/**
 * Extracts hash IDs from a string.
 * @param input
 * @returns
 */
export const extractHashIds = (input: string): Record<string, string> => {
  const ids = input.split('#').filter(Boolean)
  const result = {} as Record<string, string>

  ids.forEach((id) => {
    const separatorIndex = id.indexOf('-') // the first hyphen is our separator
    const key = id.substring(0, separatorIndex)
    const value = id.substring(separatorIndex + 1)
    result[key] = value
  })

  return result
}
