import { FC } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Divider, Flex, HStack, Spacer, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import { LogoIcon } from 'assets/icons'

import { HELP_CENTER_URL } from 'config/constants'

const PageFooter: FC = () => {
  const { user } = useAuth0()

  return (
    <Box mt={5}>
      <Divider />
      <HStack my={5}>
        <LogoIcon />
        <Text fontSize="xs" color="gray.500">
          &copy; 2024 株式会社DataLabs
        </Text>
        <Spacer />
        <Flex gap={10}>
          {!!user && (
            <Link to={HELP_CENTER_URL} target="_blank">
              ユーザーマニュアル
            </Link>
          )}
          <Link to="https://share.hsforms.com/11_hEhYjCR0CBEe1gX9adVQddydr" target="_blank">
            お問い合わせ
          </Link>
        </Flex>
      </HStack>
    </Box>
  )
}

export default PageFooter
