import { FC, useContext, useState } from 'react'

import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'
import LayerItem from 'pages/projects/editor/infoPanels/components/LayerItem'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { DIAMETERS, EDITOR_TOOLS } from 'config/constants'
import { INPUT_GROUP_STYLES, LAYER_ITEM_STYLES } from 'config/styles'

import { findDiameterKeyByValue, zeroPad } from 'services/Util'

import {
  deleteWorkingDistanceLabelAtIndex,
  toggleAllWorkingDistanceLabelsStatus,
  toggleWorkingDistanceLabelStatusAtIndex,
  updateSelectedDistanceLabel,
} from '../../tools/RebarDetection/store'

const WorkingCylinders: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const workingCylinders = useSelector((state: RootState) => state.toolRebarDetection.workingDistanceLabels)
  const isLoading = useSelector((state: RootState) => state.toolRebarDetection.isLoading)

  // Context
  const { selectedTool, isLayerModifying } = useContext(EditorContext)

  // States
  const [collapsed, setCollapsed] = useState(false)

  // Flags
  const isAllInvisible = workingCylinders.every((label) => label.invisible)
  const isAllSelected = workingCylinders.every((label) => label.selected)

  if (selectedTool !== EDITOR_TOOLS.CYLINDER) {
    return null
  }

  return (
    <>
      <LayerItem
        collapsible
        collapsed={collapsed}
        updateExpansion={setCollapsed}
        disabled={isLayerModifying}
        invisible={isAllInvisible}
        label={`鉄筋 (${workingCylinders.length}/11)`}
        updateVisibility={(visibility) => dispatch(toggleAllWorkingDistanceLabelsStatus({ invisible: visibility }))}
        onClick={() => {
          dispatch(toggleAllWorkingDistanceLabelsStatus({ selected: !isAllSelected }))
        }}
      />

      {workingCylinders.map((label, index) =>
        label.selected ? (
          <Flex
            key={`info-panel-${label.id}-editing`}
            position="relative"
            className="editor-clickable-item seleected"
            backgroundColor="whiteAlpha.200"
            w="100%"
            alignItems="center"
            {...LAYER_ITEM_STYLES}
            onClick={() => {
              dispatch(toggleWorkingDistanceLabelStatusAtIndex({ index, status: { selected: !label.selected } }))
            }}
            overflow="hidden"
          >
            {/* Main text */}
            <Box pl={3} whiteSpace="nowrap">
              {`鉄筋${zeroPad(index + 1, 2)}`}
            </Box>

            {/* A wrapper container specifically for preventing bubbling up click events */}
            <Box flex={1} onClick={(e) => e.stopPropagation()}>
              <Menu variant="toolbar">
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  textAlign="left"
                  fontWeight="normal"
                  fontSize="xs"
                  paddingX={2}
                  marginLeft={1}
                  height={7}
                  width="100%"
                  variant="toolbar"
                  {...INPUT_GROUP_STYLES}
                >
                  {label.diameter ? `${findDiameterKeyByValue(label.diameter)}（${label.diameter || ''}）` : ''}
                </MenuButton>
                <Portal>
                  <MenuList>
                    {Object.keys(DIAMETERS).map((d) => (
                      <MenuItem
                        key={d}
                        backgroundColor={label.diameter === DIAMETERS[d] ? 'secondary.600' : 'transparent'}
                        onClick={() => {
                          dispatch(updateSelectedDistanceLabel({ diameter: DIAMETERS[d], selected: false }))
                        }}
                        fontSize="xs"
                      >
                        {d}（{DIAMETERS[d]}）
                      </MenuItem>
                    ))}
                  </MenuList>
                </Portal>
              </Menu>
            </Box>
          </Flex>
        ) : (
          <LayerItem
            key={`info-panel-${label.id}`}
            isChild
            disabled={isLoading || isLayerModifying}
            selected={label.selected}
            invisible={label.invisible}
            updateVisibility={(invisible) =>
              dispatch(toggleWorkingDistanceLabelStatusAtIndex({ index, status: { invisible } }))
            }
            label={`鉄筋${zeroPad(index + 1, 2)} (${label.diameter ? findDiameterKeyByValue(label.diameter) : 'D??'})`}
            deleteLayer={() => {
              dispatch(deleteWorkingDistanceLabelAtIndex(index))
            }}
            onClick={() => {
              dispatch(toggleWorkingDistanceLabelStatusAtIndex({ index, status: { selected: !label.selected } }))
            }}
          />
        ),
      )}
    </>
  )
}

export default WorkingCylinders
