import { useCallback, useContext, useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_CUBOID_KEY, EDITOR_CUBOID_QUICK_SIZE, EDITOR_REQUIRED_ANCHORS, EDITOR_TOOLS } from 'config/constants'

import { PointArray } from 'interfaces/attribute'
import { CanvasConfig, CanvasEventsExtra, CanvasProps } from 'interfaces/editor'

import { fixVertexOnNormal, pointArrayToVector3, pointsToVector3s } from 'services/Points'
import { millimeterToMeter } from 'services/Util'

import { setCuboidAnchor } from '../../../shapes/cuboid/store'
import { setMouseAnchor } from '../store'

/**
 * Main canvas hook for PCD Trim tool.
 * Contains the logic for adding a cuboid (and the points required for it).
 *
 * The behavior is as follows:
 * - If the user clicks on the canvas, a new point is added to the cuboid.
 * - Or if the user triple clicks on the canvas, a new cuboid is created with the clicked point as the center.
 * - If the user has added 4 points, the cuboid is created.
 *
 * @param props
 */
export default function useMainCanvas({ pointCloud }: CanvasProps): CanvasConfig {
  // Context
  const {
    selectedTool,
    prevSelectedTool,
    baseDiameter,
    changeIsToolProcessing,
    changeSelectedPoint,
    updateToggledCollapses,
  } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const cuboidAnchor = useSelector((state: RootState) => state.cuboid.anchor)
  const mouseAnchor = useSelector((state: RootState) => state.maskPCD.mouseAnchor)

  // Vars
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID, [selectedTool])

  /**
   * Add a new point for a cuboid.
   *
   * @param addedPoint New point to be added
   */
  const addCuboidAnchor = (addedPoint: Vector3) => {
    if (cuboidAnchor && cuboidAnchor.points.length >= EDITOR_REQUIRED_ANCHORS.cuboid) {
      return
    }

    const points =
      cuboidAnchor?.points.length === EDITOR_REQUIRED_ANCHORS.cuboid - 1
        ? fixVertexOnNormal(pointsToVector3s(cuboidAnchor.points))
        : pointsToVector3s(cuboidAnchor?.points || [])
    const newPoints = [...points, addedPoint]

    // Keep drawing live line if required points are not enough
    changeIsToolProcessing(newPoints.length < EDITOR_REQUIRED_ANCHORS.cuboid)
    dispatch(setMouseAnchor(undefined))
    dispatch(
      setCuboidAnchor({
        diameter: cuboidAnchor?.diameter || baseDiameter || 0,
        points: newPoints.map((point) => point.toArray() as PointArray),
      }),
    )
    changeSelectedPoint({
      anchorIndex: 0,
      pointIndex: newPoints.length - 1,
      shapeKey: EDITOR_CUBOID_KEY,
    })
    updateToggledCollapses([])
  }

  /**
   * On mouse move, set the processing anchor to the current mouse position.
   */
  const onMove = useCallback(
    (points: PointArray | undefined) => {
      if (
        isToolSelected &&
        points &&
        (!cuboidAnchor || (cuboidAnchor && cuboidAnchor?.points.length < EDITOR_REQUIRED_ANCHORS.cuboid))
      ) {
        dispatch(setMouseAnchor(points))
      }
    },
    [isToolSelected, cuboidAnchor, dispatch],
  )

  /**
   * On change to different tool, reset the processing anchor.
   */
  useEffect(() => {
    if (!isToolSelected && prevSelectedTool === EDITOR_TOOLS.PCD_TRIM_CUBOID) {
      dispatch(setMouseAnchor(undefined))
    }
  }, [isToolSelected, prevSelectedTool, dispatch])

  return {
    objects: {
      circleAnchors: useMemo(() => {
        const anchors = []
        if (cuboidAnchor?.points.length && cuboidAnchor.points.length < EDITOR_REQUIRED_ANCHORS.cuboid) {
          anchors.push(
            ...cuboidAnchor.points.map((point) => ({
              id: `cuboid-anchor-${point.join('-')}`,
              point,
              color: '#777',
            })),
          )
        }

        if (mouseAnchor) {
          anchors.push({ id: `maskregion-mouse-anchor`, point: mouseAnchor, color: '#777' })
        }

        return anchors
      }, [cuboidAnchor?.points, mouseAnchor]),
    },
    events: {
      onMouseUp: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        points: PointArray | undefined,
        { isTripleClicked }: CanvasEventsExtra,
      ) => {
        if (!isToolSelected || !points) return

        if (!isTripleClicked || (cuboidAnchor?.points.length && cuboidAnchor.points.length >= 3)) {
          // prevent miss clicking on the same point
          if (
            !cuboidAnchor?.points.length ||
            !pointArrayToVector3(points).equals(
              pointArrayToVector3(cuboidAnchor.points[cuboidAnchor.points.length - 1]),
            )
          ) {
            addCuboidAnchor(pointArrayToVector3(points))
          }
        } else {
          // Quick create a cuboid with triple click
          const size =
            (millimeterToMeter(baseDiameter || 0) || pointCloud?.geometry?.boundingSphere?.radius || 1) *
            EDITOR_CUBOID_QUICK_SIZE
          dispatch(
            setCuboidAnchor({
              diameter: baseDiameter || 0,
              points: [
                [points[0] - size, points[1] - size, points[2] - size],
                [points[0] + size, points[1] - size, points[2] - size],
                [points[0] + size, points[1] - size, points[2] + size],
                [points[0] + size, points[1] + size, points[2] + size],
              ],
            }),
          )
          changeIsToolProcessing(false)
        }
      },
      onMove,
    },
  }
}
