import { useContext, useImperativeHandle, useMemo, useState } from 'react'

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { Project } from 'interfaces/project'

import { postProject, updateProject } from 'services/Projects'

import { fetchProjects } from '../store/dashboard'

const ProjectFormModalFunc: React.ForwardRefRenderFunction<
  {
    openModal: (project?: Project) => void
  },
  {
    onProjectCreated: () => void
  }
> = ({ onProjectCreated }, ref) => {
  //* モーダル制御変数
  const { isOpen, onOpen, onClose } = useDisclosure()

  // Store
  const dispatch = useAppDispatch()
  const projects = useSelector((state: RootState) => state.dashboard.projects)

  //* contexts, hooks呼び出し
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // State
  const [isSaving, setIsSaving] = useState(false)
  const [project, setProject] = useState<Partial<Project> | null>(null)

  //* 親コンポーネントから呼び出される関数定義
  useImperativeHandle(ref, () => ({
    openModal(prj?: Project) {
      setProject(prj || null)
      onOpen()
    },
  }))

  //* 工事作成
  const saveProject = async () => {
    //* 入力値チェック
    if (!project) {
      showErrorModal('工事名が未入力です。')
      setIsSaving(false)
      return false
    }

    //* 工事作成処理開始
    setIsSaving(true)

    //* 署名付きURL取得
    const access_token = await getAccessToken()
    if (!access_token) {
      setIsSaving(false)
      return false
    }

    let result: Project | null
    if (project.project_id) {
      result = await updateProject(access_token, project as Project, showErrorModal)
    } else {
      result = await postProject(access_token, project as Project, showErrorModal)
    }

    if (!result) {
      setIsSaving(false)
      return false
    }

    /// Reload project list
    await dispatch(fetchProjects({ access_token, showErrorModal }))

    //* 入力値、進捗管理変数の初期化
    setProject(null)
    setIsSaving(false)

    //* モーダルを閉じる
    onClose()

    mixpanel.track('Create Project', {
      'Project ID': project.project_id,
    })

    return onProjectCreated()
  }

  const confirmLabel = useMemo(() => {
    if (project?.project_id) return '変更'
    return '作成'
  }, [project])

  const isDuplicateName = !!projects.filter(
    (p) => p.project_name === project?.project_name && p.project_id !== project.project_id,
  ).length

  return (
    <Modal closeOnOverlayClick={!isSaving} isOpen={isOpen} onClose={onClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent data-testid="project-form-modal">
        <ModalHeader>{`工事を${confirmLabel}`}</ModalHeader>
        <ModalCloseButton hidden={isSaving} />
        <ModalBody>
          <FormControl isInvalid={isDuplicateName} isRequired>
            <FormLabel htmlFor="project_name">工事名</FormLabel>
            <Input
              data-testid="project_name"
              id="project_name"
              type="text"
              defaultValue={project?.project_name}
              onChange={(e) =>
                setProject(
                  (prev) =>
                    ({
                      ...prev,
                      project_name: e.target.value,
                    }) as Project,
                )
              }
            />
            <FormErrorMessage fontSize="xs">同じ工事名が既に存在します</FormErrorMessage>
          </FormControl>

          <Spacer my={4} />

          <FormControl>
            <FormLabel htmlFor="project_type">工種名</FormLabel>
            <FormHelperText fontSize="smaller" color="gray">
              検査箇所作成時の初期値として使用されます。検査箇所毎に変更可能です。
            </FormHelperText>
            <Input
              id="project_type"
              type="text"
              defaultValue={project?.default_construction_properties?.construction_type}
              onChange={(e) =>
                setProject(
                  (prev) =>
                    ({
                      ...prev,
                      default_construction_properties: {
                        ...prev?.default_construction_properties,
                        construction_type: e.target.value,
                      },
                    }) as Project,
                )
              }
            />
          </FormControl>

          <Spacer my={4} />

          <FormControl>
            <FormLabel htmlFor="project_detail">種別</FormLabel>
            <FormHelperText fontSize="smaller" color="gray">
              検査箇所作成時の初期値として使用されます。検査箇所毎に変更可能です。
            </FormHelperText>
            <Input
              id="project_detail"
              type="text"
              defaultValue={project?.default_construction_properties?.construction_type_detailed}
              onChange={(e) =>
                setProject(
                  (prev) =>
                    ({
                      ...prev,
                      default_construction_properties: {
                        ...prev?.default_construction_properties,
                        construction_type_detailed: e.target.value,
                      },
                    }) as Project,
                )
              }
            />
          </FormControl>
        </ModalBody>

        <ModalFooter mt={8}>
          <Button isDisabled={isSaving} me={3} py={2} minW="100px" onClick={onClose}>
            キャンセル
          </Button>

          <Button
            data-testid="submit"
            colorScheme="primary"
            isDisabled={!project?.project_name || isSaving || isDuplicateName}
            isLoading={isSaving}
            loadingText={`${confirmLabel}中...`}
            minW="100px"
            onClick={saveProject}
            py={2}
          >
            {confirmLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const ProjectFormModal = forwardRef(ProjectFormModalFunc)
export default ProjectFormModal
