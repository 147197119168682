import { FC, useCallback, useContext, useState } from 'react'

import LayerItem from 'pages/projects/editor/infoPanels/components/LayerItem'
import {
  resetBottomPlane,
  resetTopPlane,
  resetWorkingPoints,
  toggleLowerPlaneVisibility,
  toggleUpperPlaneVisibility,
} from 'pages/projects/editor/tools/VolumeEstimation/Polygon/store'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, PLANE_SIDE_LABELS } from 'config/constants'

import { PlaneSide } from 'interfaces/shape'

const WorkingPolygon: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const planes = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.planes)
  const polygonWorkingPoints = useSelector((root: RootState) => root.toolVolumeEstimationPolygon.workingPoints)
  const planeCreationOrder = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.planeCreationOrder)
  const isLoading = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.isLoading)

  // Context
  const { selectedTool, isLayerModifying } = useContext(EditorContext)

  // States
  const [collapsed, setCollapsed] = useState(false)

  // Flags
  const isAllInvisible = [planes.upper, planes.lower].every((plane) => !plane || (plane && plane.invisible === true))

  /**
   * Update visibility of both planes
   */
  const handleUpdateAllVisibility = useCallback(
    (visibility: boolean) => {
      dispatch(toggleUpperPlaneVisibility(visibility))
      dispatch(toggleLowerPlaneVisibility(visibility))
    },
    [dispatch],
  )

  if (selectedTool !== EDITOR_TOOLS.VOLUME_POLYGON && !planes.upper && !planes.lower) {
    return null
  }

  return (
    <>
      <LayerItem
        collapsible
        collapsed={collapsed}
        updateExpansion={setCollapsed}
        disabled={isLayerModifying}
        invisible={isAllInvisible}
        label="多角形平面"
        updateVisibility={handleUpdateAllVisibility}
      />

      {/* Working plane */}
      {!planes[planeCreationOrder[0]] && polygonWorkingPoints.length && (
        <LayerItem
          isChild
          disabled={polygonWorkingPoints.length <= 1}
          selected={false}
          label={PLANE_SIDE_LABELS[planeCreationOrder[0]]}
          deleteLayer={() => {
            dispatch(resetWorkingPoints())
          }}
        />
      )}

      {/* Completed planes */}
      {planes.upper && (
        <LayerItem
          isChild
          disabled={isLoading || isLayerModifying || (!!planes.lower && planeCreationOrder[0] === PlaneSide.UPPER)}
          selected={false}
          invisible={planes.upper.invisible}
          updateVisibility={(invisible) => dispatch(toggleUpperPlaneVisibility(invisible))}
          label={PLANE_SIDE_LABELS.upper}
          deleteLayer={() => {
            dispatch(resetTopPlane())
          }}
        />
      )}
      {planes.lower && (
        <LayerItem
          isChild
          disabled={isLoading || isLayerModifying || (!!planes.upper && planeCreationOrder[0] === PlaneSide.LOWER)}
          selected={false}
          invisible={planes.lower.invisible}
          updateVisibility={(invisible) => dispatch(toggleLowerPlaneVisibility(invisible))}
          label={PLANE_SIDE_LABELS.lower}
          deleteLayer={() => {
            dispatch(resetBottomPlane())
          }}
        />
      )}
    </>
  )
}

export default WorkingPolygon
