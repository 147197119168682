import { FC } from 'react'

import { Box, Tooltip } from '@chakra-ui/react'
import { Html } from '@react-three/drei'

import { WarningLabelProps } from 'interfaces/canvas'

/**
 * Label for warnings, complete with Tooltip!
 */
const WarningLabel: FC<WarningLabelProps> = ({ point, label, message, opacity, offset = [0, 0] }) => (
  <group>
    <Html
      position={point}
      style={{ transform: `translateX(calc(-50% + ${offset[0]}px)) translateY(calc(-50% + ${offset[1]}px))` }}
      zIndexRange={[1, 9]}
    >
      <Tooltip hasArrow placement="top" label={message} p="8px" fontWeight="normal" className="canvas-tooltip">
        <Box
          backgroundColor="yellow"
          py={0}
          px={4}
          opacity={opacity}
          fontSize="80%"
          fontWeight="bold"
          color="black"
          whiteSpace="nowrap"
        >
          ⚠️ {label}
        </Box>
      </Tooltip>
    </Html>
  </group>
)

export default WarningLabel
