import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
  CursorState,
  addDimmedShapeId,
  removeDimmedShapeId,
  setAnchorPlacementObjects,
  setCursor,
} from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Mesh, Raycaster, Plane as ThreePlane, Vector2, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, GRID_MINIMUM_INTERVAL } from 'config/constants'
import { PLANE_SIDE_COLOR } from 'config/styles'

import { MinimumAreaBoundary } from 'interfaces/diagram'
import { CanvasConfig } from 'interfaces/editor'
import {
  DistanceLabelProps,
  PlaneSide,
  PointArray,
  Polygon,
  PolygonPlaneMeshProps,
  WarningLabelProps,
} from 'interfaces/interfaces'

import { minAreaRectangleOfPolygon } from 'services/MinimumRectangle'
import { findPerpendicularProjection, rotatePoint, transform2Dto3D } from 'services/Points'
import { findNormalTowardsB, getNormals } from 'services/Shape'
import { generateGridPoints, getDistanceLabel, isHoverSelected, millimeterToMeter } from 'services/Util'

import { IntervalsConfig, removeWorkingGridPoint, updateInterval, updateWorkingGridPoints } from '../store'

const raycaster = new Raycaster()

interface MovingGridPoint {
  volumeId: string
  gridPointIndex: number
  anchorIndex: number
  points: PointArray[]
}

const useMainCanvas = (): CanvasConfig => {
  // Context
  const { meshRefs, selectedTool, inspectionItems, prevSelectedTool, shapes, changeIsDragging } =
    useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const grids = useSelector((state: RootState) => state.toolGrid.grids)
  const intervals = useSelector((state: RootState) => state.toolGrid.intervals)
  const workingGridPoints = useSelector((state: RootState) => state.toolGrid.workingGridPoints)
  const highlightedWorkingGrid = useSelector((state: RootState) => state.toolGrid.highlightedWorkingGrid)
  const hoveredShapeId = useSelector((state: RootState) => state.editor.hoveredShapeId)
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // State
  const [existingDistanceLabels, setExistingDistanceLabels] = useState<Record<string, DistanceLabelProps[]>>({})
  const [workingDistanceLabels, setWorkingDistanceLabels] = useState<Record<string, DistanceLabelProps[]>>({})
  const [warningLabels, setWarningLabels] = useState<WarningLabelProps[]>([])
  const [movingGridPoint, setMovingGridPoint] = useState<MovingGridPoint | null>(null)
  const [threePlanes, setThreePlanes] = useState<Record<string, ThreePlane>>({})

  // Flags
  const isToolSelected = selectedTool === EDITOR_TOOLS.GRID

  /**
   * Generate distance labels for grids retrieved from API.
   */
  useEffect(() => {
    const newDistanceLabels: Record<string, DistanceLabelProps[]> = {}
    let newWarningLabels: WarningLabelProps[] = []

    // Generate distance labels
    grids.forEach((item) => {
      newDistanceLabels[item.volume_id!] =
        item.grid?.list_distances
          .filter(() => !intervals[item.volume_id!])
          .map<DistanceLabelProps>((distance) => {
            // Hide if invisible however if highlighted, show with low opacity
            let opacity = distance.invisible ? 0 : 1
            if (distance.highlighted && distance.invisible) {
              opacity = 0.6
            }

            return {
              id: `grid-point-${item.volume_id}-${distance.position_on_grid_point.join('-')}`,
              points: distance.distance
                ? [distance.position_on_grid_point, distance.position_on_projected_point]
                : [distance.position_on_grid_point],
              anchorScale: distance.highlighted ? 1.5 : undefined,
              labelOutlineColor: distance.highlighted ? '#fff' : undefined,
              opacity,
              label: item.shape_ids.polygons.some((id) => isHoverSelected(id, hoveredShapeId, selectedShapeIds))
                ? `${distance.name}: ${getDistanceLabel(distance.distance || 0)}`
                : undefined, // only for clearing TS constraint check
              lineOutline: true,
              topAnchorColor: PLANE_SIDE_COLOR.upper,
              bottomAnchorColor: PLANE_SIDE_COLOR.lower,
              anchorOutlineColor: distance.distance ? undefined : 'yellow',
            }
          })
          .filter((distance) => distance.opacity) || []
    })

    // Generate warning labels
    grids.forEach((item) => {
      newWarningLabels = newWarningLabels.concat(
        item.grid?.list_distances
          .filter(
            (distance) =>
              !distance.distance &&
              !intervals[item.volume_id!] &&
              item.shape_ids.polygons.some((id) => isHoverSelected(id, hoveredShapeId, selectedShapeIds)),
          )
          .map((distance) => {
            // Hide if invisible however if highlighted, show with low opacity
            let opacity = distance.invisible ? 0 : 1
            if (distance.highlighted && distance.invisible) {
              opacity = 0.6
            }

            return {
              point: distance.position_on_grid_point,
              label: distance.name,
              message: '底面に対応する点が見つかりません',
              opacity,
              offset: [0, -18] as [number, number],
            }
          })
          .filter((distance) => distance.opacity) || [],
      )
    })

    setExistingDistanceLabels(newDistanceLabels)
    setWarningLabels(newWarningLabels)
  }, [grids, intervals, hoveredShapeId, selectedShapeIds, inspectionItems])

  /**
   * Generate minimum rectangle boundaries for polygons.
   */
  const minimumRectangleBoundaries = useMemo(
    () =>
      (shapes.polygons || [])
        .filter((poly) => poly.plane_side === PlaneSide.UPPER)
        .map((poly) => ({
          polygon: poly,
          boundary: minAreaRectangleOfPolygon(poly.vertices, poly.positions),
        }))
        .filter((bundle) => bundle?.boundary) as { polygon: Polygon; boundary: MinimumAreaBoundary }[],
    [shapes],
  )

  /**
   * Get shapes of both top and bottom planes for both polygon and rectangle.
   */
  const getTopAndBottomShapes = useCallback(
    (interval: IntervalsConfig) => {
      const topShapePolygon = shapes.polygons?.find((poly) => poly.shape_id === interval.topPlaneId)
      const bottomShapePolygon = shapes.polygons?.find((poly) => poly.shape_id === interval.bottomPlaneId)
      const topShape = topShapePolygon
      const bottomShape = bottomShapePolygon

      // TODO: refactor use top/bottom shape directly.

      return {
        topShapePolygon,
        bottomShapePolygon,
        topShape,
        bottomShape,
      }
    },
    [shapes.polygons],
  )

  /**
   * Generate working grid points, skipping if locked.
   */
  useEffect(() => {
    if (!meshRefs) return

    Object.keys(intervals).forEach((volumeId) => {
      const interval = intervals[volumeId]

      const { topShape, bottomShape, topShapePolygon, bottomShapePolygon } = getTopAndBottomShapes(interval)
      if (!topShape && !bottomShape) return

      // Get mesh references for top and bottom planes, prioritizing minimum boundary if available
      const actualTopMeshRef = meshRefs[interval.topPlaneId]
      const topMeshRef = meshRefs[`minimum-boundary-${interval.topPlaneId}`] || actualTopMeshRef
      const bottomMeshRef = meshRefs[interval.bottomPlaneId]

      if (!topMeshRef.current || !bottomMeshRef.current || !actualTopMeshRef.current) return

      // Determine direction of the bottom plane
      const topNormal = getNormals(topShapePolygon, topMeshRef.current as Mesh)
      const bottomNormal = getNormals(bottomShapePolygon, bottomMeshRef.current as Mesh)
      const direction = findNormalTowardsB(
        topMeshRef.current.getWorldPosition(new Vector3()),
        bottomMeshRef.current.getWorldPosition(new Vector3()),
        topNormal,
      )

      // Construct plane instances for top and bottom planes
      let topPlane: ThreePlane = threePlanes[interval.topPlaneId]
      let bottomPlane: ThreePlane = threePlanes[interval.bottomPlaneId]

      if (!topPlane) {
        topPlane = new ThreePlane().setFromNormalAndCoplanarPoint(topNormal, actualTopMeshRef.current.position)
        setThreePlanes((prev) => ({ ...prev, [interval.topPlaneId]: topPlane }))
      }

      if (!bottomPlane) {
        bottomPlane = new ThreePlane().setFromNormalAndCoplanarPoint(bottomNormal, bottomMeshRef.current.position)
        setThreePlanes((prev) => ({ ...prev, [interval.bottomPlaneId]: bottomPlane }))
      }

      // even if locked, we still need to calculate the planes
      // locked grid only need not to generate grid points
      if (interval.locked) return

      // Generate grid points
      const points = generateGridPoints({
        ...interval,
        longAxis: {
          value: Math.max(interval.longAxis.value, GRID_MINIMUM_INTERVAL),
          max: interval.longAxis.max,
          offset: interval.longAxis.offset,
        },
        shortAxis: {
          value: Math.max(interval.shortAxis.value, GRID_MINIMUM_INTERVAL),
          max: interval.shortAxis.max,
          offset: interval.shortAxis.offset,
        },
      }).map((point) => rotatePoint(new Vector2(point[0], point[1]), interval.angle))

      // Get generated points in Vector3
      let points3d: Vector3[] = []
      if (topShapePolygon) {
        // The points will later be transformed to world position. As for why we do it now as well for polygon,
        // I have no idea but it works so let it be.
        points3d = transform2Dto3D(points, topShapePolygon.transformation).map((point) => new Vector3(...point))
      } else {
        points3d = points.map((point) => new Vector3(point.x, point.y, 0))
      }

      // Finalize grid points by projecting them to the bottom plane and filtering out points that are not on the top plane.
      const gridPoints = points3d
        // filter out points that are not on the top plane
        .reduce<Vector3[]>((arr, point) => {
          // convert to world coordinates
          const point3d = topMeshRef.current.localToWorld(
            new Vector3(millimeterToMeter(point.x), millimeterToMeter(point.y), millimeterToMeter(point.z)),
          )

          // Only for polygons
          if (topShapePolygon) {
            raycaster.set(point3d.clone().add(direction.clone().multiplyScalar(-0.01)), direction)
            const results = raycaster.intersectObject(actualTopMeshRef.current, true)
            if (results.length) {
              arr.push(point3d)
            }
          } else {
            arr.push(point3d)
          }

          return arr
        }, [])
        // Project to bottom plane to get preview of the grid points
        .map((point) => [point.toArray(), findPerpendicularProjection(point, topPlane, bottomPlane).toArray()], [])

      dispatch(updateWorkingGridPoints({ id: volumeId, points: gridPoints }))
    })
  }, [intervals, meshRefs, shapes, minimumRectangleBoundaries, threePlanes, getTopAndBottomShapes, dispatch])

  /**
   * Generate label distance from grid points.
   */
  useEffect(
    () => {
      const labels = Object.keys(workingGridPoints).map((volumeId) =>
        workingGridPoints[volumeId].map<DistanceLabelProps>((points, pointIndex) => ({
          id: `${volumeId}-${points.join('-')}`,
          lineOutline: true,
          bottomAnchorColor: '#f00',
          points,
          anchorScale: volumeId === highlightedWorkingGrid ? 1.5 : undefined,
          labelOutlineColor: volumeId === highlightedWorkingGrid ? '#fff' : undefined,
          onDown: (anchorIndex) => {
            const interval = intervals[volumeId]

            changeIsDragging(true) // toggling this back to false is managed by MainCanvas::onMouseUp
            setMovingGridPoint({ volumeId, gridPointIndex: pointIndex, anchorIndex, points })
            dispatch(setCursor(CursorState.GRABBING)) // MainCanvas::onMouseUp will handle the reset

            // Limit anchor placement on top plane only
            dispatch(setAnchorPlacementObjects([interval.topPlaneId]))

            // Mark the top plane to be a bit dimmer
            const topShapePolygon = shapes.polygons?.find((poly) => poly.shape_id === interval.topPlaneId)
            if (topShapePolygon) {
              dispatch(addDimmedShapeId(topShapePolygon.shape_id))
            }

            // remove this grid point as we have a different instances of it
            dispatch(removeWorkingGridPoint({ id: volumeId, gridPointIndex: pointIndex }))
          },
          onEnter: () => {
            if (!movingGridPoint) dispatch(setCursor(CursorState.GRAB))
          },
          onLeave: () => {
            if (!movingGridPoint) dispatch(setCursor(CursorState.CROSSHAIR))
          },
        })),
      )

      const workingLabels = labels.reduce(
        (acc, current, index) => {
          acc[`${index}`] = current
          return acc
        },
        {} as Record<string, DistanceLabelProps[]>,
      )
      setWorkingDistanceLabels(workingLabels)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workingGridPoints, selectedTool, movingGridPoint, changeIsDragging, dispatch, highlightedWorkingGrid],
  )

  /**
   * Reset anchor placement objects when tool is deselected.
   */
  useEffect(() => {
    if (prevSelectedTool === EDITOR_TOOLS.GRID && !isToolSelected) {
      dispatch(setAnchorPlacementObjects([]))
    }
  }, [isToolSelected, prevSelectedTool, dispatch])

  /**
   * Callback for ending interaction, e.g. mouse up or touch end.
   */
  const onEndInteraction = useCallback(() => {
    if (movingGridPoint) {
      // Reset plane opacity
      const interval = intervals[movingGridPoint.volumeId]
      const topShapePolygon = shapes.polygons?.find((poly) => poly.shape_id === interval.topPlaneId)
      if (topShapePolygon) {
        dispatch(removeDimmedShapeId(topShapePolygon.shape_id))
      }

      // re-add the grid point back to the working grid points
      const gridPoints = [...workingGridPoints[movingGridPoint.volumeId]]
      gridPoints.splice(movingGridPoint.gridPointIndex, 0, movingGridPoint.points)

      dispatch(updateWorkingGridPoints({ id: movingGridPoint.volumeId, points: gridPoints }))
    }

    setMovingGridPoint(null)
    dispatch(setAnchorPlacementObjects([]))
  }, [workingGridPoints, movingGridPoint, intervals, shapes, dispatch])

  return {
    objects: {
      distanceLabels: useMemo(
        () =>
          Object.values(existingDistanceLabels)
            .flat()
            .concat(Object.values(workingDistanceLabels).flat())
            .concat([
              ...(movingGridPoint
                ? [
                    {
                      id: 'moving-grid-point',
                      bottomAnchorColor: '#f00',
                      points: movingGridPoint.points,
                    },
                  ]
                : []),
            ]),
        [existingDistanceLabels, movingGridPoint, workingDistanceLabels],
      ),
      warningLabels,
      polygonPlaneMesh: useMemo(
        () => [
          // Minimum boundary (needed for placing grid points, not needed at all times or displayed to user)
          ...(isToolSelected
            ? (shapes?.polygons
                .filter((polygon) => polygon.plane_side === PlaneSide.UPPER)
                .map((polygon) => {
                  const topBoundary = minimumRectangleBoundaries.find(
                    (bundle) => bundle.polygon.shape_id === polygon?.shape_id,
                  )?.boundary

                  if (!topBoundary) return null

                  return {
                    polygon: {
                      shape_id: `minimum-boundary-${polygon.shape_id}`,
                      positions: transform2Dto3D(
                        topBoundary.vertices.map((vertex) => new Vector2(...vertex)),
                        polygon.transformation,
                      ),
                      vertices: topBoundary.vertices,
                      center: transform2Dto3D(
                        [new Vector2(topBoundary.center[0], topBoundary.center[1])],
                        polygon.transformation,
                      )[0],
                    } as Polygon,
                    color: 'pink',
                    opacity: 0,
                    selectable: false,
                  }
                })
                .filter(Boolean) as PolygonPlaneMeshProps[]) || []
            : []),
        ],
        [shapes, minimumRectangleBoundaries, isToolSelected],
      ),
    },
    events: {
      onTouchEnd: onEndInteraction,
      onMouseUp: onEndInteraction,
      /**
       * Callback for moving grid point
       */
      onMove: useCallback(
        (point: PointArray | undefined) => {
          if (!isToolSelected || !movingGridPoint || !workingGridPoints[movingGridPoint.volumeId] || !point) return

          const interval = intervals[movingGridPoint.volumeId]
          if (!interval) return

          // Lock interval config only if it's not locked yet
          if (!interval.locked)
            dispatch(updateInterval({ id: movingGridPoint.volumeId, interval: { ...interval, locked: true } }))

          // Get plane instances for top and bottom planes
          const topPlane = threePlanes[interval.topPlaneId]
          const bottomPlane = threePlanes[interval.bottomPlaneId]

          setMovingGridPoint((prev) =>
            prev
              ? {
                  ...prev,
                  points: [point, findPerpendicularProjection(new Vector3(...point), topPlane, bottomPlane).toArray()],
                }
              : null,
          )
        },
        [movingGridPoint, workingGridPoints, intervals, threePlanes, isToolSelected, dispatch],
      ),
    },
  }
}

export default useMainCanvas
