import { FC, useEffect, useMemo, useRef } from 'react'

import Konva from 'konva'
import { Circle, Group, Layer, Line, Rect, Stage, Text } from 'react-konva'
import { useAppDispatch } from 'store/app'
import { setPlaneDiagram } from 'store/diagram'
import { Matrix4, Vector2 } from 'three'

import { GRID_MINIMUM_INTERVAL } from 'config/constants'

import {
  CameraProfile,
  InspectionItemGrid,
  PointArray,
  Polygon,
  PolygonDiagramVertice,
  ShapeKeyType,
} from 'interfaces/interfaces'

import { alignMinimumAreaBoundary, minAreaRectangleOfPolygon } from 'services/MinimumRectangle'
import {
  getDiagramScaledVertices,
  getGridForScaledDiagram,
  getOrientation,
  getProjectedVertices,
  orientGridBasedOnProjectedDiagram,
} from 'services/PlaneDiagram'
import { generateGridPoints, meterToMillimeter } from 'services/Util'

import LengthIndicator from './components/LengthIndicator'

const PolygonDiagram: FC<{
  /**
   * ID to be used for the diagram.
   */
  imageId: string

  /**
   * Canvas width.
   */
  width: number

  /**
   * Canvas height.
   */
  height: number

  /**
   * The polygon to be drawn (uppper).
   */
  upperPolygon?: Polygon

  /**
   * The lower polygon of the volume.
   */
  lowerPolygon: Polygon

  /**
   * The grid to be drawn.
   */
  grid?: InspectionItemGrid

  /**
   * Whether to show edge measurements.
   * Will not be shown by default
   */
  showEdgeMeasurement?: boolean

  /**
   * Camera profile to be used for the diagram.
   */
  cameraProfile: CameraProfile | undefined
}> = ({ imageId, width, height, upperPolygon, lowerPolygon, grid, cameraProfile, showEdgeMeasurement = false }) => {
  // Store
  const dispatch = useAppDispatch()

  // Refs
  const stageRef = useRef<Konva.Stage>(null)

  // Calculate the minimum rectangle boundary of the polygon.
  const mainPlane = useMemo(() => upperPolygon || lowerPolygon, [upperPolygon, lowerPolygon])
  const minimumRectangleBoundary = useMemo(() => {
    const boundary = minAreaRectangleOfPolygon(mainPlane.vertices, mainPlane.positions)
    return boundary ? alignMinimumAreaBoundary(boundary, mainPlane, lowerPolygon) : null
  }, [mainPlane, lowerPolygon])

  // Data is in meters, but we want it in millimeters.
  const length1 = minimumRectangleBoundary ? meterToMillimeter(minimumRectangleBoundary.extent[0]) : 0
  const length2 = minimumRectangleBoundary ? meterToMillimeter(minimumRectangleBoundary.extent[1]) : 0

  // The plane is drawn with the longest side as the length and the shortest side as the width.
  const planeWidth = Math.max(length1, length2)
  const planeHeight = Math.min(length1, length2)

  // Scale the plane to fit the canvas.
  const scale = Math.min(width / planeWidth, height / planeHeight) * 0.7 // leave some margin for labels
  const scaledPlaneWidth = planeWidth * scale
  const scaledPlaneHeight = planeHeight * scale
  const canvasWidth = scaledPlaneWidth + 140
  const canvasHeight = scaledPlaneHeight + 120

  // Derive certain properties from the polygon to be used later.
  const matrix4 = useMemo(() => new Matrix4().set(...mainPlane.transformation), [mainPlane.transformation])
  const matrixInverse = useMemo(() => matrix4.clone().invert(), [matrix4])

  /**
   * Projected diagram based on camera profile.
   * Might look unnecessary to have a separate state for this, but it's handy for debugging.
   */
  const projectedPolygon: ReturnType<typeof getProjectedVertices> | null = useMemo(() => {
    if (!minimumRectangleBoundary || !cameraProfile) return null

    // Diagram based on projected diagram through saved camera profile
    const result = getProjectedVertices(
      cameraProfile,
      minimumRectangleBoundary,
      scaledPlaneWidth,
      scaledPlaneHeight,
      mainPlane,
      scale,
    )

    return result
  }, [minimumRectangleBoundary, cameraProfile, mainPlane, scaledPlaneHeight, scaledPlaneWidth, scale])

  /**
   * Generate polygon diagram.
   * If there's a camera profile, it will orient the diagram based on the camera profile.
   * If there's no camera profile, it will orient the diagram based on the minimum rectangle boundary axis alignment.
   */
  const diagram: {
    vertices: PolygonDiagramVertice[]
    orientation?: ReturnType<typeof getOrientation>
    boundary?: Vector2[]
    grid: {
      label: string
      x: number
      y: number
      valid: boolean
    }[]
  } | null = useMemo(() => {
    if (!minimumRectangleBoundary) return null

    // ## Camera profile oriented diagram
    if (projectedPolygon) {
      const vertMM = mainPlane.vertices.map((v) => new Vector2(meterToMillimeter(v[0]), meterToMillimeter(v[1])))

      return {
        vertices: projectedPolygon.vertices.map((point, index) => {
          const nextPoint = projectedPolygon.vertices[(index + 1) % projectedPolygon.vertices.length]
          const center = new Vector2().addVectors(point, nextPoint).multiplyScalar(0.5)

          const pointIn2d = vertMM[index]
          const nextPointIn2d = vertMM[(index + 1) % vertMM.length]

          return {
            label: {
              text: pointIn2d.distanceTo(nextPointIn2d).toFixed(0),
              point: center,
            },
            point,
          }
        }),
        orientation: projectedPolygon.orientation,
        boundary: projectedPolygon.boundary,
        grid: grid?.list_distances
          ? orientGridBasedOnProjectedDiagram(projectedPolygon.operations, mainPlane, grid, scale, matrixInverse)
          : [],
      }
    }

    // ## X-Axis aligned minimum rectangle boundary oriented diagram
    const pivot = new Vector2(
      meterToMillimeter(minimumRectangleBoundary.center[0] * scale),
      meterToMillimeter(minimumRectangleBoundary.center[1] * scale),
    )

    return {
      vertices: getDiagramScaledVertices(
        mainPlane.vertices as unknown as PointArray[],
        minimumRectangleBoundary,
        scale,
        pivot,
      ),
      boundary: minimumRectangleBoundary.boundaryAxisAlignment.vertices.map(
        (v) => new Vector2(v.x * scale, v.y * scale),
      ),
      orientation: 'horizontal',
      grid: grid ? getGridForScaledDiagram(minimumRectangleBoundary, grid, scale, matrixInverse, pivot) : [],
    }
  }, [projectedPolygon, mainPlane, scale, minimumRectangleBoundary, grid, matrixInverse])

  /**
   * Generate grid points based on intervals so we can draw the grid lines.
   */
  const intervalGridPoints = useMemo(() => {
    if (!minimumRectangleBoundary) return []

    const [wd, hg] = minimumRectangleBoundary.extent
    const longAxisInt = Math.max(wd, hg)
    const shortAxisInt = Math.min(wd, hg)

    return generateGridPoints({
      shapeKey: ShapeKeyType.POLYGON,
      longAxis: {
        max: Math.round(meterToMillimeter(longAxisInt / 2)),
        value:
          grid?.intervals?.long_axis || Math.round((meterToMillimeter(planeWidth / 2) + GRID_MINIMUM_INTERVAL) / 2),
        offset: 0,
      },
      shortAxis: {
        max: Math.round(meterToMillimeter(shortAxisInt / 2)),
        value:
          grid?.intervals?.short_axis || Math.round((meterToMillimeter(planeHeight / 2) + GRID_MINIMUM_INTERVAL) / 2),
        offset: 0,
      },
      // 2D boundary is already pre-aligned to x-axis
      angle: 0,
      whichLongAxis: 1,
    }).map((point) => point.map(Math.round))
  }, [grid, planeWidth, planeHeight, minimumRectangleBoundary])

  /**
   * Calculate label positions for grid intervals.
   */
  const gridIntervalLines = useMemo(() => {
    const rows = new Set<number>()
    const cols = new Set<number>()
    const unscaledRows = new Set<number>()
    const unscaledCols = new Set<number>()

    if (diagram?.orientation === 'vertical') {
      intervalGridPoints.forEach((point) => {
        rows.add(Math.floor(point[0]) * scale)
        cols.add(Math.floor(point[1]) * scale)
        unscaledRows.add(Math.floor(point[0]))
        unscaledCols.add(Math.floor(point[1]))
      })
    } else {
      intervalGridPoints.forEach((point) => {
        rows.add(Math.floor(point[1]) * scale)
        cols.add(Math.floor(point[0]) * scale)
        unscaledRows.add(Math.floor(point[1]))
        unscaledCols.add(Math.floor(point[0]))
      })
    }

    return {
      rows: Array.from(rows),
      cols: Array.from(cols),
      unscaledRows: Array.from(unscaledRows),
      unscaledCols: Array.from(unscaledCols),
    }
  }, [intervalGridPoints, scale, diagram?.orientation])

  /**
   * Calculate label positions for grid intervals.
   */
  const gridIntervalLabels = useMemo(() => {
    const rows = []
    const cols = []
    const isVert = diagram?.orientation === 'vertical'
    for (let i = 0; i < gridIntervalLines.unscaledRows.length - 1; i += 1) {
      const diff = gridIntervalLines.rows[i + 1] - gridIntervalLines.rows[i]
      const unscaledDiff = gridIntervalLines.unscaledRows[i + 1] - gridIntervalLines.unscaledRows[i]

      rows.push({
        x: isVert ? scaledPlaneHeight : scaledPlaneWidth,
        y: gridIntervalLines.rows[i],
        length: diff,
        label: `${grid?.intervals?.short_axis || unscaledDiff}`,
      })
    }

    for (let i = 0; i < gridIntervalLines.unscaledCols.length - 1; i += 1) {
      const diff = gridIntervalLines.cols[i + 1] - gridIntervalLines.cols[i]
      const unscaledDiff = gridIntervalLines.unscaledCols[i + 1] - gridIntervalLines.unscaledCols[i]

      cols.push({
        x: gridIntervalLines.cols[i],
        y: isVert ? scaledPlaneWidth : scaledPlaneHeight,
        length: diff,
        label: `${grid?.intervals?.long_axis || unscaledDiff}`,
      })
    }

    return {
      rows,
      cols,
    }
  }, [gridIntervalLines, scaledPlaneHeight, scaledPlaneWidth, grid, diagram?.orientation])

  /**
   * Export the diagram into an image to be used in file exports.
   */
  useEffect(() => {
    if (stageRef.current === null) return

    dispatch(
      setPlaneDiagram({
        imageId,
        diagram: {
          image: stageRef.current?.toDataURL() || '',
          width: diagram?.orientation === 'horizontal' ? canvasWidth : canvasHeight,
          height: diagram?.orientation === 'horizontal' ? canvasHeight : canvasWidth,
        },
      }),
    )
  }, [imageId, canvasWidth, canvasHeight, diagram?.orientation, dispatch])

  return (
    <Stage
      width={diagram?.orientation === 'horizontal' ? canvasWidth : canvasHeight}
      height={diagram?.orientation === 'horizontal' ? canvasHeight : canvasWidth}
      ref={stageRef}
    >
      {/* Background */}
      <Layer>
        <Rect x={0} y={0} width={canvasWidth} height={!projectedPolygon ? canvasHeight : canvasWidth} fill="#fff" />
      </Layer>

      {/* Objects */}
      <Layer x={70} y={70}>
        {/* Boundary aligned to polygon (debug only) */}
        {/* {minimumRectangleBoundary?.boundaryAxisAlignment.vertices && (
          <Group>
            {!cameraProfile && (
              <Group>
                {minimumRectangleBoundary.mirrored.intersectionUp2d?.intersectionPoint && (
                  <Arrow
                    stroke="purple"
                    points={[
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionUp2d.centroid.x) * scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionUp2d.centroid.y) * scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionUp2d.intersectionPoint.x) * scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionUp2d.intersectionPoint.y) * scale,
                    ]}
                  />
                )}

                {minimumRectangleBoundary.mirrored.intersectionRight2d?.intersectionPoint && (
                  <Arrow
                    stroke="skyblue"
                    points={[
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionRight2d.centroid.x) * scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionRight2d.centroid.y) * scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionRight2d.intersectionPoint.x) *
                        scale,
                      meterToMillimeter(minimumRectangleBoundary.mirrored.intersectionRight2d.intersectionPoint.y) *
                        scale,
                    ]}
                  />
                )}
              </Group>
            )}
          </Group>
        )} */}

        {/* Axes line */}
        {/* <Group>
          <Line points={[0, -370, 0, 370]} stroke="purple" strokeWidth={1} dash={[5, 3]} />
          <Line points={[-370, 0, 570, 0]} stroke="purple" strokeWidth={1} dash={[5, 3]} />
        </Group> */}

        {/* Width/Height indicators */}
        {/* <Group>
          <LengthIndicator
            x={0}
            y={-30}
            length={scaledPlaneWidth}
            rotation={270}
            label={`${planeWidth.toFixed(0)}`}
            invertLabelPosition
          />
          <LengthIndicator
            x={-30}
            y={0}
            length={scaledPlaneHeight}
            label={`${planeHeight.toFixed(0)}`}
            invertLabelPosition
          />
        </Group> */}

        {/* Ref polygon */}
        {/* {projectedPolygon && (
          <Group>
            {projectedPolygon.result2d.up && projectedPolygon.result2d.right && (
              <Group>
                <Arrow
                  points={[
                    projectedPolygon.result2d.up.edge[0].x,
                    projectedPolygon.result2d.up.edge[0].y,
                    projectedPolygon.result2d.up.edge[1].x,
                    projectedPolygon.result2d.up.edge[1].y,
                  ]}
                  fill="lightgreen"
                  stroke="lightgreen"
                />
                {projectedPolygon.result2d.up.intersectionPoint && (
                  <Arrow
                    points={[
                      projectedPolygon.result2d.up.centroid.x,
                      projectedPolygon.result2d.up.centroid.y,
                      projectedPolygon.result2d.up.intersectionPoint.x,
                      projectedPolygon.result2d.up.intersectionPoint.y,
                    ]}
                    fill="pink"
                    stroke="pink"
                  />
                )}
                {projectedPolygon.result2d.right.intersectionPoint && (
                  <Arrow
                    points={[
                      projectedPolygon.result2d.right.centroid.x,
                      projectedPolygon.result2d.right.centroid.y,
                      projectedPolygon.result2d.right.intersectionPoint.x,
                      projectedPolygon.result2d.right.intersectionPoint.y,
                    ]}
                    fill="orange"
                    stroke="orange"
                  />
                )}
              </Group>
            )}
            {projectedPolygon.vertices.map((vertex, index) => (
              <Group>
                <Line
                  stroke="#000"
                  strokeWidth={1}
                  points={[
                    vertex.x,
                    vertex.y,
                    projectedPolygon.vertices[(index + 1) % projectedPolygon.vertices.length].x,
                    projectedPolygon.vertices[(index + 1) % projectedPolygon.vertices.length].y,
                  ]}
                />
              </Group>
            ))}
          </Group>
        )} */}

        {/* diagram boundary */}
        {/* {diagram?.boundary && (
          <Group>
            <Arrow
              points={[diagram.boundary[0].x, diagram.boundary[0].y, diagram.boundary[1].x, diagram.boundary[1].y]}
              stroke="red"
              fill="red"
              strokeWidth={1}
            />
            <Arrow
              points={[diagram.boundary[1].x, diagram.boundary[1].y, diagram.boundary[2].x, diagram.boundary[2].y]}
              stroke="red"
              fill="red"
              strokeWidth={1}
            />
            <Arrow
              points={[diagram.boundary[2].x, diagram.boundary[2].y, diagram.boundary[3].x, diagram.boundary[3].y]}
              stroke="red"
              fill="red"
              strokeWidth={1}
            />
            <Arrow
              points={[diagram.boundary[3].x, diagram.boundary[3].y, diagram.boundary[0].x, diagram.boundary[0].y]}
              stroke="red"
              fill="red"
              strokeWidth={1}
            />
          </Group>
        )} */}

        {diagram && (
          <Group>
            {showEdgeMeasurement && (
              <Group>
                {diagram.orientation === 'vertical' ? (
                  <Group>
                    <LengthIndicator
                      x={0}
                      y={-30}
                      length={scaledPlaneHeight}
                      label={(scaledPlaneHeight * (1 / scale)).toFixed(0)}
                      rotation={270}
                      invertLabelPosition
                    />
                    <LengthIndicator
                      x={-30}
                      y={0}
                      length={scaledPlaneWidth}
                      label={(scaledPlaneWidth * (1 / scale)).toFixed(0)}
                      invertLabelPosition
                    />
                  </Group>
                ) : (
                  <Group>
                    <LengthIndicator
                      x={0}
                      y={-30}
                      length={scaledPlaneWidth}
                      label={(scaledPlaneWidth * (1 / scale)).toFixed(0)}
                      rotation={270}
                      invertLabelPosition
                    />
                    <LengthIndicator
                      x={-30}
                      y={0}
                      length={scaledPlaneHeight}
                      label={(scaledPlaneHeight * (1 / scale)).toFixed(0)}
                      invertLabelPosition
                    />
                  </Group>
                )}
              </Group>
            )}

            {diagram.vertices.map((vertex, index) => (
              <Group>
                <Line
                  stroke="green"
                  strokeWidth={1}
                  points={[
                    vertex.point.x,
                    vertex.point.y,
                    diagram.vertices[(index + 1) % diagram.vertices.length].point.x,
                    diagram.vertices[(index + 1) % diagram.vertices.length].point.y,
                  ]}
                />
                {showEdgeMeasurement && (
                  <Text
                    text={vertex.label.text}
                    x={vertex.label.point.x - 25}
                    y={vertex.label.point.y - 13}
                    width={50}
                    height={26}
                    align="center"
                    verticalAlign="middle"
                    stroke="white"
                    strokeWidth={5}
                    fontSize={15}
                    fontStyle="bold"
                    fillAfterStrokeEnabled
                  />
                )}
              </Group>
            ))}
          </Group>
        )}

        {/* Grid lines */}
        <Group>
          {gridIntervalLines.rows.map((row) => (
            <Line
              points={[
                -10,
                row,
                (diagram?.orientation === 'vertical' ? scaledPlaneHeight : scaledPlaneWidth) + 20,
                row,
              ]}
              stroke="black"
              strokeWidth={1}
              dash={[2, 2]}
            />
          ))}
          {gridIntervalLines.cols.map((col) => (
            <Line
              points={[
                col,
                -10,
                col,
                (diagram?.orientation === 'vertical' ? scaledPlaneWidth : scaledPlaneHeight) + 20,
              ]}
              stroke="black"
              strokeWidth={1}
              dash={[2, 2]}
            />
          ))}
          {gridIntervalLabels.rows.map((label) => (
            <LengthIndicator
              x={label.x + 20}
              y={label.y}
              length={label.length}
              label={label.label}
              anchorWidth={0}
              lineDash={[2, 2]}
            />
          ))}
          {gridIntervalLabels.cols.map((label) => (
            <LengthIndicator
              x={label.x}
              y={label.y + 20}
              length={label.length}
              label={label.label}
              anchorWidth={0}
              lineDash={[2, 2]}
              rotation={270}
            />
          ))}
        </Group>

        {/* Grid points */}
        {diagram?.grid && (
          <Group>
            {diagram.grid.map((point) => (
              <Group>
                {point.valid ? (
                  <Circle x={point.x} y={point.y} width={10} height={10} fill="black" />
                ) : (
                  <Circle
                    x={point.x}
                    y={point.y}
                    width={10}
                    height={10}
                    fill="white"
                    stroke="black"
                    strokeWidth={1}
                    dash={[2, 2]}
                  />
                )}
                <Text
                  text={point.label}
                  rotation={270}
                  y={point.y - 5}
                  x={point.x - 15}
                  align="center"
                  verticalAlign="middle"
                  stroke="white"
                  strokeWidth={5}
                  fontSize={13}
                  fontStyle="bold"
                  fillAfterStrokeEnabled
                />
              </Group>
            ))}
          </Group>
        )}
      </Layer>
    </Stage>
  )
}

export default PolygonDiagram
