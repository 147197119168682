import './styles.css'

import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Button, Flex, Spacer } from '@chakra-ui/react'
import { patchEditingInspectionAreas, setGrabbedAreaId, setMouseStartPos } from 'pages/projects/inspection-sheet/store'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { InputEditorEditIcon, OrderIcon } from 'assets/icons'

import { InspectionArea, InspectionSheet } from 'interfaces/inspection'

import { parseInspectionItemPartitionKey, setInspectionAreasOrder } from 'services/InspectionArea'

import AreaItem from './components/AreaItem'

enum EditType {
  Edit = 'edit',
  Order = 'order',
}

/**
 * Overlap percentage of dragged area over another area to trigger swap.
 */
const ORDERING_OVERLAP_SWAP_THRESHOLD = 0.5

const SettingsDrawerAreaList: FC = () => {
  // Stores
  const dispatch = useAppDispatch()
  const inspectionSheets = useSelector((state: RootState) => state.inspectionSheet.inspectionSheets)
  const mouseStartPos = useSelector((state: RootState) => state.inspectionSheet.mouseStartPos)
  const grabbedAreaId = useSelector((state: RootState) => state.inspectionSheet.grabbedAreaId)
  const initialPos = useSelector((state: RootState) => state.inspectionSheet.initialPos)

  // Get areas and set ordering. Edited inspection areas has higher priority.
  const editedInspectionAreas = useSelector((state: RootState) => state.inspectionSheet.editedInspectionAreas)
  const allAreas = useSelector((state: RootState) => state.page.inspectionAreas)
  const inspectionAreas = useMemo(
    () => setInspectionAreasOrder(allAreas, editedInspectionAreas),
    [allAreas, editedInspectionAreas],
  )

  // States
  const [editType, setEditType] = useState<EditType>(EditType.Edit)

  // Derived
  const groupedAreas = useMemo(
    () =>
      inspectionSheets
        .reduce<{ inspectionArea: InspectionArea; inspectionSheet: InspectionSheet }[]>((prev, inspectionSheet) => {
          if (!inspectionSheet.partition_key) return prev

          const ids = parseInspectionItemPartitionKey(inspectionSheet.partition_key)
          const inspectionArea = inspectionAreas.find((a) => a.inspection_area_id === ids.inspectionAreaId)
          if (!inspectionArea) return prev

          prev.push({
            inspectionArea,
            inspectionSheet,
          })

          return prev
        }, [])
        // order by inspectionArea.order
        .sort((a, b) => a.inspectionArea.order! - b.inspectionArea.order!),
    [inspectionAreas, inspectionSheets],
  )

  const onGrabbedEnd = useCallback(() => {
    const ele = document.getElementById(`grabbed-area-${grabbedAreaId}`)
    if (ele && initialPos) {
      ele.style.top = `${initialPos.y}px`
      ele.style.left = `${initialPos.x}px`
    }

    dispatch(setMouseStartPos(null))
    dispatch(setGrabbedAreaId(''))

    // derive from the DOM the new order
    const areas = document.querySelectorAll('#edit-inspection-area-container .item')
    const newOrdering = Array.from(areas).map((row, index) => {
      const id = row.id.replace('edit-inspection-area-', '')
      const area = inspectionAreas.find((a) => a.inspection_area_id === id)
      const editedArea = editedInspectionAreas.find((a) => a.inspection_area_id === id)
      return area ? { ...area, ...editedArea, order: index } : null
    }) as InspectionArea[]
    dispatch(patchEditingInspectionAreas(newOrdering))
  }, [grabbedAreaId, initialPos, editedInspectionAreas, inspectionAreas, dispatch])

  /**
   * On mouse move, if user is dragging an area, move the area.
   * Manipulate the DOM directly for better performance.
   */
  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      const ele = document.getElementById(`grabbed-area-${grabbedAreaId}`)

      if (!mouseStartPos || !initialPos || !grabbedAreaId || !ele) return

      const eleRect = ele.getBoundingClientRect()
      const draggedLeft = event.clientX - mouseStartPos.x + initialPos.x
      const draggedTop = event.clientY - mouseStartPos.y + initialPos.y
      const draggedBottom = draggedTop + eleRect.height
      const every5 = !(Math.abs(draggedTop - initialPos.y) % 5)
      const isDraggingDown = draggedTop > eleRect.top

      ele.style.top = `${draggedTop}px`
      ele.style.left = `${draggedLeft}px`

      if (!every5) return

      const areas = document.querySelectorAll('#edit-inspection-area-container .item')
      const areaPositions: { top: number; bottom: number; height: number; id: string }[] = []
      areas.forEach((area) => {
        const id = area.id.replace('edit-inspection-area-', '')
        const rect = area.getBoundingClientRect()
        areaPositions.push({
          top: rect.top,
          bottom: rect.bottom,
          height: rect.height,
          id,
        })
      })

      // we only update store after user releases the mouse, so we need to
      // keep track of the current order by checking the DOM
      const currentSortedAreas = areaPositions.map((a, index) => {
        const area = inspectionAreas.find((ia) => ia.inspection_area_id === a.id)
        return area ? { ...area, order: index } : null
      }) as InspectionArea[]

      // figure out the new position
      const overlaps = areaPositions
        .map(({ top: overlappedTop, bottom: overlappedBottom, height, id }, index) => {
          const yOverlap = Math.max(0, Math.min(draggedBottom, overlappedBottom) - Math.max(draggedTop, overlappedTop))
          return { id, yOverlap: yOverlap / height, order: index }
        })
        .filter(({ yOverlap }) => yOverlap > ORDERING_OVERLAP_SWAP_THRESHOLD)

      if (!overlaps.length) return

      // once overlap goes over threshold, we will pick based on direction of drag
      // dragging down - higher order position
      // dragging up - lower order position
      const max = overlaps.reduce((prev, curr) => {
        if (isDraggingDown && curr.order > prev.order) {
          return curr
        }

        if (!isDraggingDown && curr.order < prev.order) {
          return curr
        }

        return prev
      }, overlaps[0])
      const { order: newOrder } = max

      // udpate inspection areas with new order
      const grabbedArea = currentSortedAreas.find((area) => area.inspection_area_id === grabbedAreaId)
      if (grabbedArea && grabbedArea.order !== newOrder) {
        const newOrdering = currentSortedAreas
          .reduce<InspectionArea[]>((collection, area) => {
            if (area.inspection_area_id === grabbedArea.inspection_area_id) {
              collection.push({ ...area, order: newOrder })
            } else if (isDraggingDown && area.order! <= newOrder) {
              collection.push({ ...area, order: area.order! - 1 })
            } else if (!isDraggingDown && area.order! >= newOrder) {
              collection.push({ ...area, order: area.order! + 1 })
            } else {
              collection.push({ ...area })
            }

            return collection
          }, [])
          .sort((a, b) => a.order! - b.order!)

        // sort the DOM directly for better performance
        const container = document.getElementById('edit-inspection-area-container')
        if (container) {
          const areaEles = container.querySelectorAll('.item')

          while (container.firstChild) {
            container.removeChild(container.lastChild!)
          }
          newOrdering.forEach((area) => {
            const areaEle = Array.from(areaEles).find((a) => a.id === `edit-inspection-area-${area.inspection_area_id}`)
            if (areaEle) container.appendChild(areaEle)
          })
        }
      }
    },
    [mouseStartPos, grabbedAreaId, initialPos, inspectionAreas],
  )

  /**
   * Track mouse movement for drag and drop.
   * Will also initialize the initial position of other areas.
   */
  useEffect(() => {
    if (mouseStartPos) {
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onGrabbedEnd)

      return () => {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onGrabbedEnd)
      }
    }

    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onGrabbedEnd)
    return () => null
  }, [dispatch, onGrabbedEnd, onMouseMove, mouseStartPos])

  return (
    <>
      <Box borderLeft="4px solid var(--chakra-colors-blue-400)" pl={2}>
        帳票詳細で検査箇所の名前や情報を編集したり、並べ替えモードに切り替えて順番を変更したりできます。
      </Box>

      <Spacer my={6} />

      <Flex id="area-edit-type">
        <Box>
          <Button
            leftIcon={<InputEditorEditIcon />}
            className={[editType === EditType.Edit ? 'active' : ''].filter(Boolean).join(' ')}
            onClick={() => setEditType(EditType.Edit)}
          >
            編集
          </Button>
          <Button
            leftIcon={<OrderIcon />}
            className={[editType === EditType.Order ? 'active' : ''].filter(Boolean).join(' ')}
            onClick={() => setEditType(EditType.Order)}
          >
            並べ替え
          </Button>
        </Box>
      </Flex>

      <Spacer my={6} />

      <Flex id="edit-inspection-area-container" className={`edit-type-${editType}`}>
        {groupedAreas.map((row) => (
          <AreaItem key={row.inspectionArea.inspection_area_id} {...row} />
        ))}
      </Flex>
    </>
  )
}

export default SettingsDrawerAreaList
