import axios, { AxiosResponse } from 'axios'

import { ErrorResponse } from 'interfaces/interfaces'

export const ERROR_PROCESS_MESSAGE: Record<string, { key: string; name: string; messages: Record<number, string> }> = {
  CREATE_COMMENT: {
    key: 'CREATE_COMMENT',
    name: 'コメント作成',
    messages: {
      40007: '工事内の画像の枚数が上限に到達しました。\n画像を削除して再度試してください。',
    },
  },
  CREATE_COMMENT_REPLY: {
    key: 'CREATE_COMMENT_REPLY',
    name: 'コメント返信作成',
    messages: {
      40007: '工事内の画像の枚数が上限に到達しました。\n画像を削除して再度試してください。',
    },
  },
  CREATE_PROJECT: {
    key: 'CREATE_PROJECT',
    name: '工事作成',
    messages: {
      40002: '点群ファイルが壊れています。',
      40004:
        '点群の物理的な大きさが限界値を超えています。\nトリミングなどを行い、点群の大きさを下げて再度試してください。',
      40006: '点群の座標値が大きすぎます。\ncentering処理を行い、再度試してください。',
      40010: 'すでに同じ名前の工事が存在します。\n別の名前を入力してください。',
      40081: '点の数が少なすぎます。',
      42201: 'ファイル名が不正です。\nスペースや@などの特殊文字を取り除いて、再度試してください。',
    },
  },
  CREATE_INSPECTION_AREA: {
    key: 'CREATE_INSPECTION_AREA',
    name: '検査箇所作成',
    messages: {
      40002: '点群ファイルが壊れています。',
      40004:
        '点群の物理的な大きさが限界値を超えています。\nトリミングなどを行い、点群の大きさを下げて再度試してください。',
      40006: '点群の座標値が大きすぎます。\ncentering処理を行い、再度試してください。',
      40011: 'すでに同じ名前の検査箇所が存在します。\n別の名前を入力してください。',
      40081: '点の数が少なすぎます。',
      40801: '点群処理がタイムアウトしました。データが大きすぎる可能性があります。',
      42201: 'ファイル名が不正です。\nスペースや@などの特殊文字を取り除いて、再度試してください。',
      50001: '予期せぬエラーが発生しました。データが大きすぎる可能性があります。',
    },
  },
  CREATE_USER_INFO: { key: 'CREATE_USER_INFO', name: 'ユーザー情報作成', messages: {} },
  GET_USER: {
    key: 'GET_USER',
    name: 'ユーザー取得',
    messages: {
      40401: '40401', // Because the message style is quite complex, manually handle it in the component
    },
  },
  DELETE_COMMENT: { key: 'DELETE_COMMENT', name: 'コメント削除', messages: {} },
  DELETE_COMMENT_REPLY: { key: 'DELETE_COMMENT_REPLY', name: 'コメント返信削除', messages: {} },
  DELETE_PROJECT: { key: 'DELETE_PROJECT', name: '工事削除', messages: {} },
  DELETE_INVITED_USER_FROM_PROJECT: {
    key: 'DELETE_INVITED_USER_FROM_PROJECT',
    name: '閲覧ユーザー招待の削除',
    messages: {},
  },
  DELETE_SHAPES: { key: 'DELETE_SHAPES', name: '鉄筋削除', messages: {} },
  DETECT_SHAPES: {
    key: 'DETECT_SHAPES',
    name: '鉄筋検出',
    messages: {
      40081: '点の数が少なすぎます。',
    },
  },
  DOWNLOAD_CAD_FILE: {
    key: 'DOWNLOAD_CAD_FILE',
    name: 'CADファイル生成',
    messages: {
      42201: 'モデルの数が上限を超えています。不要なものを削除してやり直してください。',
    },
  },
  EVALUATE_COVER_DISTANCE: {
    key: 'EVALUATE_COVER_DISTANCE',
    name: '最小かぶり評価',
    messages: {
      40081: '鉄筋が型枠と干渉しています。',
    },
  },
  EVALUATE_MEAN_DISTANCE: {
    key: 'EVALUATE_MEAN_DISTANCE',
    name: '平均間隔評価',
    messages: {
      40081: '鉄筋の位置・向きが揃っていません。鉄筋の選択が適切であることを確認してください。',
    },
  },
  ESTIMATE_VOLUME: {
    key: 'ESTIMATE_VOLUME',
    name: '体積測定',
    messages: {
      40081: '平面同士が平行に近くなるように選択してください。',
      40082: '平面同士が干渉しています。平面の選択が適切であることを確認してください。',
    },
  },
  ESTIMATE_DEPTH: {
    key: 'ESTIMATE_DEPTH',
    name: 'かぶり厚測定',
    messages: {
      40081:
        '平面と鉄筋が干渉しています。該当のモデルに⚠マークが付きます。距離の算出が必要な場合は干渉しないようにモデルを再作成してください。',
    },
  },
  ESTIMATE_GRID: {
    key: 'ESTIMATE_DEPTH',
    name: 'グリッド測定',
    messages: {
      40081: '平面同士が平行に近くなるように選択してください。',
      40082: '平面同士が干渉しています。平面の選択が適切であることを確認してください。',
      40083: 'グリッドの間隔が大きすぎ、または小さすぎます。\n値を変更して再度試してください。',
      40084: '底面に対応する点が見つかりません。\n体積推定で平面モデルを作り直してください。',
    },
  },
  GET_ACCESS_TOKEN: { key: 'GET_ACCESS_TOKEN', name: 'ユーザー情報の確認', messages: {} },
  GET_COMMENT_REPLIES: { key: 'GET_COMMENT_REPLIES', name: 'コメント返信取得', messages: {} },
  GET_COMMENTS: { key: 'GET_COMMENTS', name: 'コメント取得', messages: {} },
  GET_DOWN_SAMPLED_FILE: { key: 'GET_DOWN_SAMPLED_FILE', name: '点群データ取得', messages: {} },
  GET_INSPECTION_SHEET: { key: 'GET_INSPECTION_SHEET', name: '帳票取得', messages: {} },
  GET_INSPECTION_ITEMS: { key: 'GET_INSPECTION_ITEMS', name: '帳票項目取得', messages: {} },
  GET_PROJECTS: { key: 'GET_PROJECTS', name: '工事取得', messages: {} },
  GET_SERVICE_STATUS: { key: 'GET_SERVICE_STATUS', name: 'サービス状況取得', messages: {} },
  GET_TUTORIAL_VIDEO_CONFIG: { key: 'GET_TUTORIAL_VIDEO_CONFIG', name: 'サービス状況取得', messages: {} },
  GET_INSPECTION_AREA: { key: 'GET_INSPECTION_AREA', name: '検査箇所取得', messages: {} },
  GET_SHAPES: { key: 'GET_SHAPES', name: '鉄筋取得', messages: {} },
  INVITE_USER: {
    key: 'INVITE_USER',
    name: '閲覧ユーザー招待',
    messages: {
      40004: '招待したユーザーは既にHatsulyのアカウントを持っています。\n工事への招待を行うことができます。',
    },
  },
  INVITE_USER_TO_PROJECT: {
    key: 'INVITE_USER_TO_PROJECT',
    name: '閲覧ユーザー招待',
    messages: {
      40002: '自分自身を招待する事はできません。',
      40402: '招待された閲覧ユーザーのアカウントが作成されていません。\nまずアカウント作成の招待を行ってください。',
    },
  },
  MODIFY_INSPECTION_SHEET: { key: 'MODIFY_INSPECTION_SHEET', name: '帳票処理', messages: {} },
  REGISTER_USER: { key: 'REGISTER_USER', name: '新規登録', messages: {} },
  SAVE_SHAPES: { key: 'SAVE_SHAPES', name: '鉄筋保存', messages: {} },
  UPDATE_COMMENT: {
    key: 'UPDATE_COMMENT',
    name: 'コメント変更',
    messages: {
      40007: '工事内の画像の枚数が上限に到達しました。\n画像を削除して再度試してください。',
    },
  },
  UPDATE_COMMENT_REPLY: {
    key: 'UPDATE_COMMENT_REPLY',
    name: 'コメント返信変更',
    messages: {
      40007: '工事内の画像の枚数が上限に到達しました。\n画像を削除して再度試してください。',
    },
  },
  UPDATE_PROJECT: {
    key: 'UPDATE_PROJECT',
    name: '工事変更',
    messages: {
      40010: 'すでに同じ名前の工事が存在します。\n別の名前を入力してください。',
    },
  },
  UPDATE_INSPECTION_AREA: {
    key: 'UPDATE_INSPECTION_AREA',
    name: '検査箇所変更',
    messages: {
      40011: 'すでに同じ名前の検査箇所が存在します。\n別の名前を入力してください。',
    },
  },

  // Blueprints
  GET_BLUEPRINTS: { key: 'GET_BLUEPRINTS', name: '設計図面取得', messages: {} },
  ADD_BLUEPRINT: { key: 'ADD_BLUEPRINT', name: '設計図面追加', messages: {} },
  DELETE_BLUEPRINT: { key: 'DELETE_BLUEPRINT', name: '設計図面削除', messages: {} },
} as const

export const ERROR_PROCESS: Record<keyof typeof ERROR_PROCESS_MESSAGE, keyof typeof ERROR_PROCESS_MESSAGE> =
  Object.keys(ERROR_PROCESS_MESSAGE).reduce((processes, key) => ({ ...processes, [key]: key }), {})

export const COMMON_MESSAGE = 'エラーが発生しました。\n時間をおいて、再度実行してください。'

export const getCommonErrorMessage = (processName: keyof typeof ERROR_PROCESS): string =>
  `${ERROR_PROCESS_MESSAGE[processName].name}でエラーが発生しました。\n時間をおいて、再度実行してください。`

export const getErrorForToast = (processName: keyof typeof ERROR_PROCESS, statusId: number) => ({
  title: ERROR_PROCESS_MESSAGE[processName]?.name || '',
  description: ERROR_PROCESS_MESSAGE[processName]?.messages[statusId] || '',
})

/**
 * Get error message from response
 *
 * @param processName Error process name
 * @param response Axios response
 */
export const getErrorMessage = (processName: string, response: AxiosResponse) => {
  let alertMessage = getCommonErrorMessage(processName)

  const statusCode = response.status
  if (statusCode === 400) alertMessage = '不正なリクエストです。'
  if (statusCode === 403) alertMessage = '不正なリクエストです。'
  if (statusCode === 404) alertMessage = 'データが存在しません。'

  const responseData = response.data as ErrorResponse
  let statusId: number | undefined = responseData?.status_id
  if (responseData?.status_message && responseData.status_message.includes('status_id')) {
    try {
      const parsed = JSON.parse(responseData.status_message) as { status_id: number }
      statusId = parsed.status_id
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  // Detailed message
  const messages = statusId ? ERROR_PROCESS_MESSAGE[processName].messages[statusId] || '' : ''
  if (messages) {
    alertMessage = messages
  }

  return alertMessage
}

//*
/**
 * axiosやs3などのtry-catchされたエラーのハンドリングを実施する
 * @param {Error} err Errorオブジェクト
 * @param {string} processName 処理名
 */
export const processErrorHandler = (
  err: unknown,
  processName: keyof typeof ERROR_PROCESS,
  showErrorModal: (message: string) => void,
) => {
  //* エラーの種類によって、エラーメッセージを設定
  let alertMessage = `不明な${COMMON_MESSAGE}`
  if (axios.isAxiosError(err) && err.response) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    alertMessage = getErrorMessage(processName, err.response)
  }

  //* エラーを表示
  showErrorModal(alertMessage)
}
