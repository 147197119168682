import { useCallback, useContext, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { InspectionAreaDownSampleStatus } from 'project-dashboard-library/dist/interfaces/inspectionArea'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'
import {
  fetchInspectionArea,
  reset,
  setInspectionArea,
  setInspectionAreas,
  setIsInvited,
  setIsLoading,
  setIsOwner,
  setProject,
} from 'store/page'

import { GlobalModalContext } from 'contexts/GlobalModal'

import { JOBS_WATCHING_INTERVAL } from 'config/constants'

import { InspectionArea } from 'interfaces/inspection'
import { Project } from 'interfaces/project'

import { getInspectionAreas, setInspectionAreasOrder } from 'services/InspectionArea'
import { getProject } from 'services/Projects'
import { isInvited, isOwner } from 'services/Validation'

interface PageHookProps {
  onLoaded?: ({
    project,
    inspectionArea,
    inspectionAreas,
  }: {
    project: Project
    inspectionArea: InspectionArea | null
    inspectionAreas: InspectionArea[] | null
  }) => Promise<void>
}

export const usePage = ({ onLoaded }: PageHookProps = {}) => {
  const { project_id } = useParams<{ project_id: string }>()
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // Route matching to check current page
  const isInspectionAreaBlueprintPage = !!inspection_area_id

  // Context
  const { getAccessTokenSilently } = useAuth0()
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const inspectionAreas = useSelector((state: RootState) => state.page.inspectionAreas)
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)
  const userProfile = useSelector((state: RootState) => state.user.userProfile)
  const isLoading = useSelector((state: RootState) => state.page.isLoading)

  /**
   * Check the status of the inspection area
   */
  const checkInspectionAreaStatus = useCallback(async () => {
    if (!project_id || !inspection_area_id) return

    const token = await getAccessTokenSilently()
    if (!token) {
      return
    }

    await dispatch(
      fetchInspectionArea({
        access_token: token,
        project_id,
        inspection_area_id,
      }),
    )
  }, [project_id, inspection_area_id, getAccessTokenSilently, dispatch])

  useEffect(() => {
    if (inspectionArea && inspectionArea.downsample_status?.status === InspectionAreaDownSampleStatus.RUNNING) {
      setTimeout(() => {
        void checkInspectionAreaStatus()
      }, JOBS_WATCHING_INTERVAL)
    }
  }, [inspectionArea, checkInspectionAreaStatus, dispatch])

  /**
   * Fetch project and inspection area on page load.
   */
  useEffect(() => {
    if (!userLoaded || !project_id || (isInspectionAreaBlueprintPage && !inspection_area_id)) {
      return () => null
    }

    if (inspection_area_id) {
      // prevent infinite loop
      if (inspectionArea?.inspection_area_id === inspection_area_id) {
        return () => null
      }

      // if user changes inspection area, set it as active
      const area = inspection_area_id
        ? inspectionAreas?.find((ia) => ia.inspection_area_id === inspection_area_id)
        : null
      if (area) {
        dispatch(setInspectionArea(area))
        return () => null
      }
    }

    if (!inspection_area_id && inspectionArea) {
      // if user navigates back to inspection area list, reset it
      dispatch(setInspectionArea(undefined))
      return () => null
    }

    // if loading has finished, and we're not in inspection area, end it here to prevent infinite loop
    if (!inspection_area_id && !isLoading) {
      return () => null
    }

    // if project is already loaded, end it here
    if (project && project.project_id === project_id) {
      return () => null
    }

    dispatch(setIsLoading(true))

    const controller = new AbortController()

    void (async () => {
      const token = await getAccessTokenSilently()
      if (!token) {
        dispatch(setIsLoading(false))
        return
      }

      // fetch project and all inspection areas of that project
      const result = await Promise.all([
        getProject(token, project_id, undefined, controller.signal),
        getInspectionAreas(token, project_id, undefined, controller.signal),
      ])

      // false refers to aborted requests, ignore them
      if (result.some((r) => r === false)) {
        return
      }

      if (result.some((r) => r === null)) {
        dispatch(setIsLoading(false))
        return
      }

      // Derive inspection area from areas list, if inspection_area_id is provided
      const data = {
        project: result[0] as Project,
        inspectionAreas: result[1] as InspectionArea[],
      }
      const area = inspection_area_id
        ? data.inspectionAreas?.find((ia) => ia.inspection_area_id === inspection_area_id)
        : null

      if (inspection_area_id && !area) {
        dispatch(setIsLoading(false))
        showErrorModal('検査箇所が見つかりませんでした。')
        return
      }

      // Set inspection area manually to store
      if (area) {
        dispatch(setInspectionArea(area))
      }

      dispatch(setProject(data.project))
      dispatch(
        setInspectionAreas(setInspectionAreasOrder(data.inspectionAreas, []).sort((a, b) => a.order! - b.order!)),
      )

      if (onLoaded)
        await onLoaded({
          project: data.project,
          inspectionArea: area || null,
          inspectionAreas: data.inspectionAreas,
        })

      dispatch(setIsLoading(false))
    })()

    return () => {
      controller.abort()
    }
  }, [
    isLoading,
    userLoaded,
    project,
    project_id,
    inspection_area_id,
    isInspectionAreaBlueprintPage,
    inspectionArea,
    inspectionAreas,
    getAccessTokenSilently,
    dispatch,
    showErrorModal,
    onLoaded,
  ])

  /**
   * Set owner and invited permission.
   */
  useEffect(() => {
    if (!project || !userProfile) return

    dispatch(setIsOwner(isOwner(project, userProfile)))
    dispatch(setIsInvited(isInvited(project, userProfile)))
  }, [project, userProfile, dispatch])

  /**
   * Cleanup on unmount.
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  return {
    project,
    inspectionArea,
  }
}
