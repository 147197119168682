/**
 * The CommentPopup component consists of 4 parts; Container, Body, Form, Image Input Row.
 *  1. Container (this file)
 *     - This is the main component that handles the popup's position and visibility.
 *     - Will also contain the Body and Form.
 *     - It also handles new creation of comment and reply.
 *     - The component embedded in this file is for creation of new comment/reply.
 *  2. Body (CommentBody)
 *     - This is the component that handles the display of comment and reply body.
 *     - It also handles the editing and deletion of comment and reply.
 *     - Contains the menu item for comment / reply actions (edit, delete, etc.)
 *     - The CommentInput embedded here is for editing comment/reply.
 *  3. Form (CommentInput)
 *     - Handles both comment and reply, create and edit as well as file management.
 *  4. Iamge Input Row (CommentInputImage)
 *    - Handles the display of image selected to be uploaded and their caption.
 *    - The component is only for a single image looped in CommentInput.
 */
import { FC } from 'react'

import {
  Box,
  Button,
  HStack,
  Heading,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { ExternalLink } from 'assets/icons'

import { CommentBody } from './components/CommentBody'
import { CommentInput } from './components/CommentInput'
import { CommentPopupProps, useCommentPopup } from './hooks/commentPopup'

// drei cannot load styles of chakra-ui
const BUTTON_STYLE = {
  width: 'auto',
  lineHeight: 1.2,
  height: '32px',
  fontWeight: 'bold',
  borderRadius: 'var(--chakra-radii-md)',
  paddingX: '12px',
}
const PRIMARY_BUTTON_STYLE = {
  ...BUTTON_STYLE,
  backgroundColor: 'var(--chakra-colors-primary-500)',
  color: 'white',
  _hover: { backgroundColor: 'var(--chakra-colors-primary-600)' },
  _disabled: { backgroundColor: 'var(--chakra-colors-primary-300)' },
}
const SECONDARY_BUTTON_STYLE = {
  ...BUTTON_STYLE,
  borderColor: 'var(--chakra-colors-gray-200)',
  borderWidth: 1,
  _hover: { backgroundColor: 'var(--chakra-colors-gray-100)' },
  _disabled: { backgroundColor: 'white', color: 'var(--chakra-colors-gray-400)' },
}

const CommentPopup: FC<CommentPopupProps> = (props) => {
  const { comment, onMove } = props
  const {
    isDisabled,
    isLoading,
    isNewComment,
    isInputShown,
    title,
    titleLinkPath,
    setIsLoading,
    onCancelInput,
    onSaved,
    handleClose,
    showReplyForm,
    fetchReplies,
  } = useCommentPopup(props)

  if (!comment) return null

  return (
    <Popover placement="top-start" isOpen>
      {/* Just for positioning the popover, trigger event will be handled by toolbar */}
      <PopoverTrigger>
        <Box style={{ left: '-8px', right: 0, top: 0, position: 'absolute', width: 0, height: 0 }} />
      </PopoverTrigger>
      <PopoverContent
        backgroundColor="white"
        borderRadius="6px"
        maxWidth="320px"
        onMouseEnter={(e) => e.stopPropagation()}
        onMouseLeave={(e) => e.stopPropagation()}
      >
        <PopoverArrow />
        <PopoverBody wordBreak="break-word" padding={0}>
          <VStack>
            <VStack overflow="auto" maxHeight="360px" p="8px" w="100%">
              {title && titleLinkPath && (
                <Box width="100%" borderBottomWidth={1} paddingBottom="8px">
                  <Heading size="sm" width="100%" marginTop="-3px">
                    <Tooltip hasArrow label="検査箇所の3D画面に移動" placement="top" variant="light">
                      <Link to={titleLinkPath} as={RouterLink} color="primary" fontWeight="bold" fontSize="md">
                        {title} <ExternalLink style={{ display: 'inline' }} size=".8rem" />
                      </Link>
                    </Tooltip>
                  </Heading>
                </Box>
              )}

              {/* ## MAIN THREAD */}
              {!isNewComment && <CommentBody comment={comment} onLoading={setIsLoading} onMove={onMove} />}

              {/* ## SHOW COMMENTS BUTTON */}
              {!comment.replies?.length && comment?.reply_amount && (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={fetchReplies}
                  alignSelf="flex-start"
                  isDisabled={isLoading}
                  {...SECONDARY_BUTTON_STYLE}
                  py="6px"
                >
                  {comment.reply_amount}件の返信を表示
                </Button>
              )}

              {/* ## REPLIES */}
              {!!comment.replies?.length &&
                comment.replies.map((reply) => (
                  <CommentBody key={reply.reply_id} comment={comment} reply={reply} onLoading={setIsLoading} />
                ))}

              {/* ## COMMENT FORM */}
              {/* Only to be used when adding new comment or reply */}
              {isInputShown && (
                <CommentInput
                  parentComment={comment}
                  isDisabled={isDisabled}
                  onCancel={onCancelInput}
                  onSaved={onSaved}
                />
              )}
            </VStack>

            {/* ## Buttons for showing add reply form and close modal ## */}
            {!isInputShown && (
              <HStack alignSelf="stretch" p="8px" pt={0} w="100%">
                <Button
                  colorScheme="primary"
                  size="sm"
                  onClick={() => {
                    if (!isDisabled) {
                      showReplyForm()
                    }
                  }}
                  isDisabled={isDisabled || isLoading}
                  {...PRIMARY_BUTTON_STYLE}
                >
                  返信
                </Button>
                <Spacer />
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    if (!isDisabled) handleClose()
                  }}
                  isDisabled={isDisabled || isLoading}
                  {...SECONDARY_BUTTON_STYLE}
                >
                  閉じる
                </Button>
              </HStack>
            )}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default CommentPopup
