import './styles.css'

import { FC } from 'react'

import { Button, HStack, Menu, MenuButton, MenuItem, MenuList, Spinner, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { RootState } from 'store/app'

import { ChevronDownIcon } from 'assets/icons'

import { BUTTON_FONT_SIZE } from 'config/constants'

const AreaSwitcher: FC = () => {
  // Router
  const location = useLocation()
  const useMatches = useLocation()
  const isInspectionSheet = useMatches.pathname.includes('inspection-sheet')

  // Store
  const inspectionAreas = useSelector((state: RootState) => state.page.inspectionAreas)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const isLoading = useSelector((state: RootState) => state.page.isLoading)

  // Derived
  const childPage = location.pathname.split('/').pop()

  const areas = [...inspectionAreas]
  // Re-sort inspection areas with editedInspectionAreas if we're on inspection sheet
  const editedInspectionAreas = useSelector((state: RootState) => state.inspectionSheet.editedInspectionAreas)
  if (isInspectionSheet) {
    areas.sort((a, b) => {
      const editedA = editedInspectionAreas.find((edited) => edited.inspection_area_id === a.inspection_area_id)
      const editedB = editedInspectionAreas.find((edited) => edited.inspection_area_id === b.inspection_area_id)
      if (editedA && editedB) {
        return editedA.order! - editedB.order!
      }
      if (editedA) {
        return -1
      }
      if (editedB) {
        return 1
      }
      return a.order! - b.order!
    })
  }

  return (
    <HStack id="area-switcher" gap={4}>
      <Text className="label" fontSize={BUTTON_FONT_SIZE}>
        検査箇所
      </Text>
      <Menu placement="bottom-end" isLazy>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          {isLoading ? <Spinner size="sm" position="relative" top="2px" /> : inspectionArea?.inspection_area_name}
        </MenuButton>
        <MenuList className="menu" maxHeight={{ base: '90svh', xl: '50svh' }}>
          {areas.map((area) => (
            <MenuItem
              key={area.inspection_area_id}
              as={Link}
              to={`/projects/${area.project_id}/${childPage}?area=${area.inspection_area_id}`}
            >
              {area.inspection_area_name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  )
}

export default AreaSwitcher
