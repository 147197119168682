import { FC } from 'react'

import { HStack, StackDivider } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'

import { ToolbarRotateIcon, ToolbarScaleIcon, ToolbarTranslateIcon } from 'assets/icons'

import { EDITOR_SUB_TOOLS } from 'config/constants'

import SubToolbarButton from './components/SubToolbarButton'

const SubToolbar: FC = () => {
  const buttonSize = isTablet ? 12 : 8

  return (
    <HStack
      left={isTablet ? '600px' : '50%'}
      transform="translateX(-50%)"
      overflow="hidden"
      position="absolute"
      spacing={4}
      top={1}
      h={isTablet ? 12 : 8}
    >
      <HStack
        backgroundColor="gray.800"
        borderRadius="md"
        overflow="hidden"
        divider={<StackDivider borderColor="whiteAlpha.200" />}
        spacing={0}
      >
        <SubToolbarButton
          icon={<ToolbarTranslateIcon width="50%" height="50%" />}
          label="移動"
          toolType={EDITOR_SUB_TOOLS.CUBOID.TRANSLATE}
          buttonSize={buttonSize}
        />
        <SubToolbarButton
          icon={<ToolbarScaleIcon width="50%" height="50%" />}
          label="サイズ"
          toolType={EDITOR_SUB_TOOLS.CUBOID.SCALE}
          buttonSize={buttonSize}
        />
        <SubToolbarButton
          icon={<ToolbarRotateIcon width="50%" height="50%" />}
          label="回転"
          toolType={EDITOR_SUB_TOOLS.CUBOID.ROTATE}
          buttonSize={buttonSize}
        />
      </HStack>
    </HStack>
  )
}

export default SubToolbar
