import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { InspectionSheetPlaneDiagram } from 'interfaces/inspection'

export interface DiagramState {
  /**
   * Plane diagrams for the inspection sheet.
   */
  planeDiagrams: Record<string, InspectionSheetPlaneDiagram>
}

export const initialState: DiagramState = {
  planeDiagrams: {},
}

export const diagramSlice = createSlice({
  name: 'diagram',
  initialState,
  reducers: {
    setPlaneDiagram: (state, action: PayloadAction<{ imageId: string; diagram: InspectionSheetPlaneDiagram }>) => {
      state.planeDiagrams[action.payload.imageId] = action.payload.diagram
    },
    reset: () => initialState,
  },
})

export const { setPlaneDiagram, reset } = diagramSlice.actions

export default diagramSlice.reducer
