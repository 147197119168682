import { FC, useCallback, useContext, useEffect } from 'react'

import { WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  StackDivider,
  useDisclosure,
} from '@chakra-ui/react'
import { cloneDeep } from 'lodash'
import ToolbarButton from 'pages/projects/editor/toolbar/components/ToolbarButton'
import { BiCamera } from 'react-icons/bi'
import { BsGearFill } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { Matrix4, Quaternion, Vector3 } from 'three'

import {
  CubeBackIcon,
  CubeDownIcon,
  CubeFrontIcon,
  CubeLeftIcon,
  CubeRightIcon,
  CubeUpIcon,
  ToolbarFocusButton,
} from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, EDITOR_TOOL_BUTTON_SIZE } from 'config/constants'

import { CameraViewOrientation } from 'interfaces/inspection'

import CameraViewButton from './CameraViewButton'

/**
 * Timeout for the camera profile sub-toolbar to close.
 * Value is in milliseconds.
 */
const CAMERA_PROFILE_SUB_TOOLBAR_TIMEOUT = 3 * 1000

/**
 * Camera sub-toolbar timeout.
 */
let subToolbarTimeout: NodeJS.Timeout

export const CameraToolbar: FC = () => {
  // Popover toolbar
  const { isOpen, onOpen, onClose } = useDisclosure()

  // Context
  const { selectedTool, changeTool, cameraRef, arcballControlsRef } = useContext(EditorContext)

  // Store
  const cameraProfile = useSelector((state: RootState) => state.toolCameraProfile.cameraProfile)
  const userType = useSelector((state: RootState) => state.user.userType)
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  /**
   * Re-orient camera to saved profile.
   * @param orientation Camera view orientation.
   * @param noAutoClose Flag to not auto close the camera profile sub-toolbar.
   */
  const orientCameraToProfile = useCallback(
    (orientation: CameraViewOrientation, noAutoClose?: boolean) => {
      if (cameraProfile && arcballControlsRef.current && cameraRef.current) {
        const state = cloneDeep(cameraProfile.state)

        if (orientation !== CameraViewOrientation.FRONT) {
          const position = new Vector3()
          const quaternion = new Quaternion()
          const scale = new Vector3()
          new Matrix4().fromArray(state.arcballState.cameraMatrix.elements).decompose(position, quaternion, scale)

          if (orientation === CameraViewOrientation.TOP) {
            const up = new Vector3(...cameraProfile.positions.back)
              .sub(new Vector3(...cameraProfile.target))
              .normalize()
            state.arcballState.cameraUp = { x: up.x, y: up.y, z: up.z }
          } else if (orientation === CameraViewOrientation.BOTTOM) {
            const up = new Vector3(...cameraProfile.positions.front)
              .sub(new Vector3(...cameraProfile.target))
              .normalize()
            state.arcballState.cameraUp = { x: up.x, y: up.y, z: up.z }
          }

          const updatedMatrix = new Matrix4().compose(
            new Vector3(...cameraProfile.positions[orientation]),
            quaternion,
            scale,
          )
          state.arcballState.cameraMatrix.elements = updatedMatrix.toArray()
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
        ;(arcballControlsRef.current as any).setStateFromJSON(JSON.stringify(state))
      }
      if (!noAutoClose) onClose()
    },
    [cameraProfile, cameraRef, arcballControlsRef, onClose],
  )

  /**
   * Clicking the main view button will orient the camera to the front view
   * as well as open the camera profile sub-toolbar.
   */
  const onClickMainView = useCallback(() => {
    onOpen()
    orientCameraToProfile(CameraViewOrientation.FRONT, true)

    clearTimeout(subToolbarTimeout)
    subToolbarTimeout = setTimeout(() => {
      onClose()
    }, CAMERA_PROFILE_SUB_TOOLBAR_TIMEOUT)
  }, [onOpen, onClose, orientCameraToProfile])

  /**
   * Close sub-toolbar when the selected tool is camera profile.
   */
  useEffect(() => {
    if (selectedTool === EDITOR_TOOLS.CAMERA_PROFILE) {
      onClose()
    }
  }, [selectedTool, onClose])

  return (
    <HStack
      bottom={2}
      left={2}
      h={EDITOR_TOOL_BUTTON_SIZE}
      position="absolute"
      backgroundColor="gray.800"
      borderRadius="md"
      divider={<StackDivider borderColor="whiteAlpha.200" />}
      spacing={0}
      data-testid="camera-assistant-bar"
    >
      <ToolbarButton
        icon={<ToolbarFocusButton size="50%" />}
        label={EDITOR_TOOLS_LABELS.FOCUS}
        toolType={EDITOR_TOOLS.FOCUS}
        buttonSize={EDITOR_TOOL_BUTTON_SIZE}
        selectedTool={selectedTool}
        changeTool={changeTool}
        toolTipPlacement="top"
      />
      {isAllowedToModify && (!cameraProfile || selectedTool === EDITOR_TOOLS.CAMERA_PROFILE) && (
        <ToolbarButton
          icon={
            <Box position="relative">
              <BiCamera />
              <>
                <Icon
                  as={BsGearFill}
                  position="absolute"
                  top="-2px"
                  right="-2px"
                  width="75%"
                  height="75%"
                  color="black"
                />
                <Icon
                  as={BsGearFill}
                  position="absolute"
                  top="-2px"
                  right="-2px"
                  width="65%"
                  height="65%"
                  color="white"
                />
                {!cameraProfile && (
                  <Icon
                    as={WarningIcon}
                    position="absolute"
                    top="-60%"
                    right="-60%"
                    width="16px"
                    height="16px"
                    color="orange"
                  />
                )}
              </>
            </Box>
          }
          label="正面を設定"
          toolType={EDITOR_TOOLS.CAMERA_PROFILE}
          buttonSize={EDITOR_TOOL_BUTTON_SIZE}
          selectedTool={selectedTool}
          changeTool={changeTool}
          toolTipPlacement="top"
        />
      )}
      {cameraProfile && selectedTool !== EDITOR_TOOLS.CAMERA_PROFILE && (
        <Box w={EDITOR_TOOL_BUTTON_SIZE} h={EDITOR_TOOL_BUTTON_SIZE} position="relative">
          <CameraViewButton
            label="カメラを正面(-y)からの視点に移動"
            icon={<CubeFrontIcon width="50%" height="50%" data-testid="cameraFromFront" />}
            onClick={onClickMainView}
            onLongPress={onOpen}
            hasSubtoolbar
          />
          <Box position="relative" bottom="calc(100% + 4px)">
            <Popover
              defaultIsOpen
              returnFocusOnClose={false}
              isOpen={isOpen}
              onClose={onClose}
              placement="top"
              closeOnBlur
              closeOnEsc
            >
              <PopoverContent
                h={EDITOR_TOOL_BUTTON_SIZE}
                w="auto"
                borderWidth={0}
                backgroundColor="transparent"
                bottom={EDITOR_TOOL_BUTTON_SIZE}
                marginBottom={1}
              >
                <PopoverArrow
                  w="8px"
                  h="8px"
                  top={EDITOR_TOOL_BUTTON_SIZE}
                  left="12px"
                  transform="rotate(45deg) translate(0, -5px)"
                  backgroundColor="gray.800"
                  boxShadow="1px 1px 1px 0 var(--chakra-colors-whiteAlpha-500)"
                  position="absolute"
                />
                <PopoverBody
                  p={0}
                  backgroundColor="secondary.800"
                  borderColor="secondary.700"
                  borderWidth={1}
                  borderRadius="md"
                >
                  <HStack
                    h={EDITOR_TOOL_BUTTON_SIZE}
                    backgroundColor="gray.800"
                    borderRadius="md"
                    divider={<StackDivider borderColor="whiteAlpha.200" />}
                    spacing={0}
                    data-testid="camera-profile-view-orientation-bar"
                  >
                    <CameraViewButton
                      label="カメラを左(-x)からの視点に移動"
                      icon={<CubeLeftIcon width="50%" height="50%" data-testid="cameraFromLeft" />}
                      onClick={() => orientCameraToProfile(CameraViewOrientation.LEFT)}
                    />
                    <CameraViewButton
                      label="カメラを右(+x)からの視点に移動"
                      icon={<CubeRightIcon width="50%" height="50%" data-testid="cameraFromRight" />}
                      onClick={() => orientCameraToProfile(CameraViewOrientation.RIGHT)}
                    />
                    <CameraViewButton
                      label="カメラを正面(-y)からの視点に移動"
                      icon={<CubeFrontIcon width="50%" height="50%" data-testid="cameraFromFront" />}
                      onClick={() => orientCameraToProfile(CameraViewOrientation.FRONT)}
                    />
                    <CameraViewButton
                      label="カメラを裏(+y)からの視点に移動"
                      icon={<CubeBackIcon width="50%" height="50%" data-testid="cameraFromBack" />}
                      onClick={() => orientCameraToProfile(CameraViewOrientation.BACK)}
                    />
                    <CameraViewButton
                      label="カメラを下(-z)からの視点に移動"
                      icon={<CubeDownIcon width="50%" height="50%" data-testid="cameraFromBottom" />}
                      onClick={() => orientCameraToProfile(CameraViewOrientation.BOTTOM)}
                    />
                    <CameraViewButton
                      label="カメラを上(+z)からの視点に移動"
                      icon={<CubeUpIcon width="50%" height="50%" data-testid="cameraFromTop" />}
                      onClick={() => orientCameraToProfile(CameraViewOrientation.TOP)}
                    />
                    {isAllowedToModify && (
                      <ToolbarButton
                        icon={
                          <Box position="relative">
                            <BiCamera />
                            <>
                              <Icon
                                as={BsGearFill}
                                position="absolute"
                                top="-2px"
                                right="-2px"
                                width="75%"
                                height="75%"
                                color="black"
                              />
                              <Icon
                                as={BsGearFill}
                                position="absolute"
                                top="-2px"
                                right="-2px"
                                width="65%"
                                height="65%"
                                color="white"
                              />
                            </>
                          </Box>
                        }
                        label="正面を設定"
                        toolType={EDITOR_TOOLS.CAMERA_PROFILE}
                        buttonSize={EDITOR_TOOL_BUTTON_SIZE}
                        selectedTool={selectedTool}
                        changeTool={changeTool}
                        toolTipPlacement="top"
                      />
                    )}
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </Box>
      )}
    </HStack>
  )
}
