import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { ToastId, useToast } from '@chakra-ui/react'
import { CursorState, setCursor } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, MAXIMUM_NUMBER_OF_SHAPE_DETECTION } from 'config/constants'

import { PointArray } from 'interfaces/attribute'
import { CanvasConfig } from 'interfaces/editor'

import { meterToMillimeter } from 'services/Util'

import {
  DistanceLabelWorkingCylinderProps,
  addWorkingDistanceLabel,
  finishDraggingDistanceLabel,
  setDraggingDistanceLabel,
  setFirstPointPlaced,
  setTempDistanceLabel,
  updateDraggingDistanceLabelAnchor,
} from '../store'

enum ANCHOR_COLORS {
  Selected = 'orange',
  Normal = '#777',
}

export default function useMainCanvas(): CanvasConfig {
  // Toast
  const toast = useToast()
  const toastIdRef = useRef<ToastId>()

  // Context
  const { selectedTool, changeIsDragging } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const workingDistanceLabels = useSelector((state: RootState) => state.toolRebarDetection.workingDistanceLabels)
  const tempDistanceLabel = useSelector((state: RootState) => state.toolRebarDetection.tempDistanceLabel)
  const baseDiameter = useSelector((state: RootState) => state.toolRebarDetection.baseDiameter)
  const draggingDistanceLabel = useSelector((state: RootState) => state.toolRebarDetection.draggingDistanceLabel)
  const isLoading = useSelector((state: RootState) => state.toolRebarDetection.isLoading)
  const firstPointPlaced = useSelector((state: RootState) => state.toolRebarDetection.firstPointPlaced)

  // State
  const [onDownData, setOnDownData] = useState<{ position: number[]; timestamp: number } | undefined>(undefined) // position here is screen position, not canvas coordinates.

  // Vars
  const isSelectedTool = useMemo(() => selectedTool === EDITOR_TOOLS.CYLINDER, [selectedTool])

  /**
   * Handle mouse down event.
   */
  const onMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setOnDownData({ position: [e.clientX, e.clientY], timestamp: Date.now() })
  }, [])

  /**
   * Mouse click will either add a new distance label (cylinder marker) or finish the current working distance label.
   */
  const onMouseUp = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, point: PointArray | undefined) => {
      if ((!isSelectedTool && selectedTool !== EDITOR_TOOLS.MOVE) || !point) return

      // check for dragging distance label first
      if (draggingDistanceLabel) {
        dispatch(finishDraggingDistanceLabel())
        return
      }

      // If the user has been dragging the canvas, don't add a new distance label.
      const mouseStart = onDownData ? { ...onDownData } : undefined
      setOnDownData(undefined)

      const distanceFromDown = mouseStart
        ? Math.hypot(e.clientX - mouseStart.position[0], e.clientY - mouseStart.position[1])
        : 0
      if (distanceFromDown > 10) return // user has been dragging the canvas

      // Add a temp distance label if there's none yet.
      if (!isSelectedTool) return

      if (!tempDistanceLabel) {
        dispatch(
          setTempDistanceLabel({
            id: 'rebar-detection-temp-distance-label',
            points: [point],
            anchorColor: 'red',
            lineColor: 'red',
            labelBgColor: 'yellow',
          }),
        )
      } else if (firstPointPlaced) {
        dispatch(
          addWorkingDistanceLabel({
            ...tempDistanceLabel,
            id: `rebar-detection-distance-label-${workingDistanceLabels.length}`,
            diameter: baseDiameter,
            points: [tempDistanceLabel.points[0], point],
            anchorColor: ANCHOR_COLORS.Normal,
          }),
        )
        dispatch(setTempDistanceLabel(undefined))
        dispatch(setFirstPointPlaced(false))
      } else if (workingDistanceLabels.length >= MAXIMUM_NUMBER_OF_SHAPE_DETECTION) {
        if (!toastIdRef.current) {
          toastIdRef.current = toast({
            title: '同時に検出できる要素は11個までです。',
            description: '右側の「検出中の要素」パネルから削除して11個以下にしてください。',
            status: 'warning',
            duration: null,
          })
        }
      } else {
        dispatch(
          setTempDistanceLabel({
            ...tempDistanceLabel,
            points: [point],
          }),
        )
        dispatch(setFirstPointPlaced(true))
      }
    },
    [
      tempDistanceLabel,
      selectedTool,
      isSelectedTool,
      baseDiameter,
      onDownData,
      draggingDistanceLabel,
      toast,
      workingDistanceLabels.length,
      firstPointPlaced,
      dispatch,
    ],
  )

  /**
   * On mouse move, update the temp distance label, if there is one.
   */
  const onMouseMove = useCallback(
    (point: PointArray | undefined) => {
      if (!point || (!isSelectedTool && selectedTool !== EDITOR_TOOLS.MOVE)) {
        return
      }

      if (draggingDistanceLabel) {
        dispatch(updateDraggingDistanceLabelAnchor(point))
      } else if (isSelectedTool) {
        if (!tempDistanceLabel) {
          dispatch(
            setTempDistanceLabel({
              id: 'rebar-detection-temp-distance-label',
              points: [point],
              anchorColor: 'red',
              lineColor: 'red',
              labelBgColor: 'yellow',
            }),
          )
        } else if (tempDistanceLabel) {
          dispatch(
            setTempDistanceLabel({
              ...tempDistanceLabel,
              points: !firstPointPlaced ? [point] : [tempDistanceLabel.points[0], point],
              label: !firstPointPlaced
                ? undefined
                : `${meterToMillimeter(
                    new Vector3(...tempDistanceLabel.points[0]).distanceTo(new Vector3(...point)),
                  ).toFixed(0)}mm`,
            }),
          )
        }
      }
    },
    [isSelectedTool, selectedTool, tempDistanceLabel, draggingDistanceLabel, firstPointPlaced, dispatch],
  )

  /**
   * When a distance label is mouse down, change the cursor to grabbing.
   * @param labelIndex Index of the distance label
   * @param anchorIndex Which anchor was interacted with.
   */
  const onDistanceLabelDown = useCallback(
    (labelIndex: number, anchorIndex: number) => {
      if (isSelectedTool || selectedTool === EDITOR_TOOLS.MOVE) {
        dispatch(setDraggingDistanceLabel({ labelIndex, anchorIndex }))
        dispatch(setCursor(CursorState.GRABBING))
        changeIsDragging(true)
      }
    },
    [isSelectedTool, selectedTool, changeIsDragging, dispatch],
  )

  /**
   * When user removes a working distance label, remove the toast if available.
   */
  useEffect(() => {
    if ((workingDistanceLabels.length < MAXIMUM_NUMBER_OF_SHAPE_DETECTION || isLoading) && toastIdRef.current) {
      toast.close(toastIdRef.current)
      toastIdRef.current = undefined
    }
  }, [workingDistanceLabels.length, isLoading, toast])

  return {
    objects: {
      distanceLabels: workingDistanceLabels
        .filter(
          (label, index) =>
            !label.invisible &&
            // If there's a dragging distance label, skip it, we'll use a dummy instead.
            (!draggingDistanceLabel || (draggingDistanceLabel && draggingDistanceLabel.labelIndex !== index)),
        )
        .map<DistanceLabelWorkingCylinderProps>((label, labelIndex) => ({
          ...label,
          anchorScale: label.selected ? 1.5 : 1,
          anchorColor: label.selected ? ANCHOR_COLORS.Selected : ANCHOR_COLORS.Normal,
          lineColor: label.selected ? 'yellow' : label.lineColor,
          onDown: (anchorIndex: number) => (isLoading ? undefined : onDistanceLabelDown(labelIndex, anchorIndex)),
          onEnter: () => {
            if (!draggingDistanceLabel && !isLoading) dispatch(setCursor(CursorState.GRAB))
          },
          onLeave: () => {
            if (!draggingDistanceLabel && !isLoading) dispatch(setCursor(CursorState.CROSSHAIR))
          },
        }))
        // Add the currently drawing label if there is one, and not dragging anything when the current drawing already has 2 points.
        .concat(
          (!draggingDistanceLabel || (draggingDistanceLabel && (tempDistanceLabel?.points.length || 0) > 1)
            ? tempDistanceLabel
            : []) || [],
        )
        // Add the temporary dragging distance label if there is one.
        .concat(draggingDistanceLabel ? [draggingDistanceLabel.label] : []),
    },
    events: {
      // TODO: handle touch events
      onMove: onMouseMove,
      onMouseUp,
      onMouseDown,
    },
  }
}
